const ReducerStateContant ={
    fulfilled:'fulfilled',
    pending:'pending',
    rejected:'rejected'
}
 const ValidationExpression = {
    Email: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/i,
    Password:/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])[A-Za-z\d@$!%*#?&]{10,}$/
};
const Dimensions = {
    H200W200:"200x200",
    H400W400:"400x400",
    H600W600:"600x600",
    H800W800:"800x800"
};
export {ReducerStateContant,ValidationExpression,Dimensions};