import { createSlice } from "@reduxjs/toolkit";
import { getAllPosPaymentSettingsAction, getPOSPaymentSettingsAction ,insertUpdatePosPaymentSettingsAction, posPaymentSettingsStatusACTION, posPaymentSettingsDeleteACTION} from "../../actions/settings/PosPaymentSettingsAction";
import { toast } from 'react-toastify';

const INITIAL_STATE = {

    PosPaymentSettings: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    selectedPosPaymentSetting: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    insertUpdatePosPaymentSettingsDetails: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },

    activeInactivePosPaymentSettingDetails: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },

    deletePosPaymentSettingDetails: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    }

}

const PosPaymentSettingsReducer = createSlice({
    name: "PosPaymentSettings",
    initialState: INITIAL_STATE,
    reducers: {


        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetPosPaymentSettingsDetailsState(state, action) {
            state.selectedPosPaymentSetting = INITIAL_STATE.selectedPosPaymentSetting;
            state.insertUpdatePosPaymentSettingsDetails = INITIAL_STATE.insertUpdatePosPaymentSettingsDetails;
        },

    },

    extraReducers: (builder) => {
        builder.addCase(getAllPosPaymentSettingsAction.fulfilled, (state, action) => {
            // console.log("getAllPosPaymentSettingsAction success", action);
            state.PosPaymentSettings.data = action.payload.data;
            // console.log(state.PosPaymentSettings.data , 'given data');
            state.PosPaymentSettings.apiMsg.status = action.meta.requestStatus;
            state.PosPaymentSettings.apiMsg.message = "success";
            // console.log(state.PosPaymentSettings.apiMsg.message,'apimsg');
        });
        builder.addCase(getAllPosPaymentSettingsAction.pending, (state, action) => {
            // console.log("getAllPosPaymentSettingsAction pending", action);
            state.PosPaymentSettings.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllPosPaymentSettingsAction.rejected, (state, action) => {
            // console.log("getAllPosPaymentSettingsAction rejected", action);
            state.PosPaymentSettings.apiMsg.message = action.error.message;
            state.PosPaymentSettings.apiMsg.status = action.meta.requestStatus;
        });

//get api while click on edit button

        builder.addCase(getPOSPaymentSettingsAction.fulfilled, (state, action) => {
            state.selectedPosPaymentSetting.data = action.payload.data;
            // console.log(state.selectedPosPaymentSetting.data , 'get data api call');
            state.selectedPosPaymentSetting.apiMsg.status = action.meta.requestStatus;
            state.selectedPosPaymentSetting.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getPOSPaymentSettingsAction.pending, (state, action) => {
            state.selectedPosPaymentSetting.data = null;
            state.selectedPosPaymentSetting.apiMsg.message = action.meta.requestStatus;;
            state.selectedPosPaymentSetting.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getPOSPaymentSettingsAction.rejected, (state, action) => {
            state.selectedPosPaymentSetting.apiMsg.message = action.error.message;
            state.selectedPosPaymentSetting.apiMsg.status = action.meta.requestStatus;
        });

//insert update case

        builder.addCase(
            insertUpdatePosPaymentSettingsAction.fulfilled,
            (state, action) => {

                state.insertUpdatePosPaymentSettingsDetails.data = action.payload.data.data;
                state.insertUpdatePosPaymentSettingsDetails.apiMsg.status = action.meta.requestStatus;
                state.insertUpdatePosPaymentSettingsDetails.apiMsg.message = action.payload.message.message;

                if (!action.payload.newRecord) {
                    let updatedRecord = state.PosPaymentSettings.data.records.map(x => {
                        if (x.id == action.payload.data.data.id) {
                            console.log(action.payload.data,'updated Data');
                            return action.payload.data.data;
                            
                        }
                        else {
                            return x;
                        }
                    })

                    state.PosPaymentSettings.data.records = updatedRecord;
                }
                else {
                    if (state.PosPaymentSettings.data.records.length) {
                        state.PosPaymentSettings.data.records.unshift(action.payload.data.data);
                    }
                    else {
                        state.PosPaymentSettings.data.records.push(action.payload.data.data);
                    }
                }
                toast.success(action.payload.message.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });

            }
        );
        builder.addCase(
            insertUpdatePosPaymentSettingsAction.pending,
            (state, action) => {
                state.insertUpdatePosPaymentSettingsDetails.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdatePosPaymentSettingsAction.rejected,
            (state, action) => {

                console.log(action.error.message);
                state.insertUpdatePosPaymentSettingsDetails.apiMsg.message = action.error.message;
                state.insertUpdatePosPaymentSettingsDetails.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });
            }
        );

// active inactive status case


builder.addCase(posPaymentSettingsStatusACTION.fulfilled, (state, action) => {
    state.PosPaymentSettings.data.records = state.PosPaymentSettings.data.records.map((item) => {

        return {
            ...item,
            active: action.payload.id === item.id ? !item.active : item.active
        }
    })
    state.activeInactivePosPaymentSettingDetails.apiMsg.status = action.meta.requestStatus;
    state.activeInactivePosPaymentSettingDetails.apiMsg.message = action.payload.message.message;
});
builder.addCase(posPaymentSettingsStatusACTION.pending, (state, action) => {
    state.activeInactivePosPaymentSettingDetails.apiMsg.status = action.meta.requestStatus;
});
builder.addCase(posPaymentSettingsStatusACTION.rejected, (state, action) => {
    state.activeInactivePosPaymentSettingDetails.apiMsg.message = action.error.message;
    state.activeInactivePosPaymentSettingDetails.apiMsg.status = action.meta.requestStatus;
});



// delete changestatus  change

builder.addCase(posPaymentSettingsDeleteACTION.fulfilled, (state, action) => {

    state.PosPaymentSettings.data.records = state.PosPaymentSettings.data.records.filter((item, index) => item.id !== action.payload.data.id)

    state.deletePosPaymentSettingDetails.apiMsg.status = action.meta.requestStatus;
    state.deletePosPaymentSettingDetails.apiMsg.message = action.payload.message.message;
});
builder.addCase(posPaymentSettingsDeleteACTION.pending, (state, action) => {
    state.deletePosPaymentSettingDetails.apiMsg.status = action.meta.requestStatus;
});
builder.addCase(posPaymentSettingsDeleteACTION.rejected, (state, action) => {
    state.deletePosPaymentSettingDetails.apiMsg.message = action.error.message;
    state.deletePosPaymentSettingDetails.apiMsg.status = action.meta.requestStatus;
});


    }

})

export const { RESET, ResetPosPaymentSettingsDetailsState } = PosPaymentSettingsReducer.actions;
export default PosPaymentSettingsReducer.reducer;