import { createAction, createReducer, createSlice } from "@reduxjs/toolkit";
import { 
  kitchenCommentAddUpdateACTION,
  kitchenCommentGetAllACTION,
  kitchenCommentChangeStatusACTION,
  kitchenCommentDeleteACTION,
  getKitchenCommentACTION 
} from "../../actions/kitchenCommentActions";

const INITIAL_STATE = {
  kitchenComments: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  kitchenCommentDetails: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  selectedKitchenCommentDetails: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  insertUpdateKitchenCommentDetails: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  }
};

const kichenCommentReducer = createSlice({
  name: "KitchenComment",
  initialState: INITIAL_STATE,
  reducers: {
    RESET(state, action) {
      state = INITIAL_STATE;
    },
    ResetKitchenCommentDetailsState(state, action) {
      state.kitchenCommentDetails = INITIAL_STATE.kitchenCommentDetails;
      state.insertUpdateKitchenCommentDetails = INITIAL_STATE.insertUpdateKitchenCommentDetails;
      state.selectedKitchenCommentDetails=INITIAL_STATE.selectedKitchenCommentDetails;
    },
  },
  extraReducers: (builder) => {
    
      //------------------------------- kitchenCommentAddUpdateACTION start -------------------------
     // eslint-disable-next-line
    builder.addCase(kitchenCommentAddUpdateACTION.fulfilled, (state, action) => {
      // console.log("kitchenCommentAddUpdateACTION", action);

        //state.kitchenComments.data.records.push(action.payload.data);
        if(!action.payload.newRecord){
          let updatedRecord = state.kitchenComments.data.records.map(x => {
            if(x.id==action.meta.arg.id){
              return action.payload.data.data;
            }
            else{
              return x;
            }
          })

          state.kitchenComments.data.records=updatedRecord;
        }
        else{
         
          if(state.kitchenComments.data.records.length){
            state.kitchenComments.data.records.unshift(action.payload.data.data);
          }
          else{
            state.kitchenComments.data.records.push(action.payload.data.data);
          }
        }

        state.insertUpdateKitchenCommentDetails.apiMsg.status = action.meta.requestStatus;
        state.insertUpdateKitchenCommentDetails.apiMsg.message = action.payload.message.message;
    }),
      builder.addCase(kitchenCommentAddUpdateACTION.pending, (state, action) => {
        state.insertUpdateKitchenCommentDetails.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(kitchenCommentAddUpdateACTION.rejected, (state, action) => {
        state.insertUpdateKitchenCommentDetails.apiMsg.message = action.error.message;
        state.insertUpdateKitchenCommentDetails.apiMsg.status = action.meta.requestStatus;
      }),
      //------------------------------- kitchenCommentAddUpdateACTION end -------------------------

      //------------------------------- kitchenCommentGetAllACTION start -------------------------

      builder.addCase(kitchenCommentGetAllACTION.fulfilled, (state, action) => {
        //console.log("kitchenCommentGetAllACTION", action);

        state.kitchenComments.data = action.payload.data;
        state.kitchenComments.apiMsg.status = action.meta.requestStatus;
        state.kitchenComments.apiMsg.message = action.payload.message.message;
      }),
      builder.addCase(kitchenCommentGetAllACTION.pending, (state, action) => {
        //console.log("kitchenCommentGetAllACTION pending", action);
        state.kitchenComments.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(kitchenCommentGetAllACTION.rejected, (state, action) => {
       // console.log("kitchenCommentGetAllACTION rejected", action);
       state.kitchenComments.apiMsg.message = action.error.message;
       state.kitchenComments.apiMsg.status = action.meta.requestStatus;
      }),
    //------------------------------- kitchenCommentGetAllACTION end -------------------------

    //------------------------------- kitchenCommentChangeStatusACTION start -------------------------

    builder.addCase(kitchenCommentChangeStatusACTION.fulfilled, (state, action) => {
        //console.log("kitchenCommentChangeStatusACTION", action);

        state.kitchenComments.data.records = state.kitchenComments.data.records.map((item) => {

          return {
            ...item,
            active: action.payload.id === item.id ? !item.active : item.active
          }
        })
        state.kitchenCommentDetails.apiMsg.status = action.meta.requestStatus;
        state.kitchenCommentDetails.apiMsg.message = action.payload.message.message;
      }),
      builder.addCase(kitchenCommentChangeStatusACTION.pending, (state, action) => {
       // console.log("kitchenCommentChangeStatusACTION pending", action);
       state.kitchenCommentDetails.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(kitchenCommentChangeStatusACTION.rejected, (state, action) => {
        //console.log("kitchenCommentChangeStatusACTION rejected", action);
        state.kitchenCommentDetails.apiMsg.message = action.error.message;
        state.kitchenCommentDetails.apiMsg.status = action.meta.requestStatus;
      }),
    //------------------------------- kitchenCommentChangeStatusACTION end -------------------------

     //------------------------------- kitchenCommentDeleteACTION start -------------------------
    
     builder.addCase(kitchenCommentDeleteACTION.fulfilled, (state, action) => {
      //console.log("kitchenCommentDeleteACTION", action);

      state.kitchenComments.data.records = state.kitchenComments.data.records.filter((item, index) => item.id !== action.payload.data.id)

      state.kitchenCommentDetails.apiMsg.status = action.meta.requestStatus;
      state.kitchenCommentDetails.apiMsg.message = action.payload.message.message;
    }),
      builder.addCase(kitchenCommentDeleteACTION.pending, (state, action) => {
        //console.log("kitchenCommentDeleteACTION pending", action);
        state.kitchenCommentDetails.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(kitchenCommentDeleteACTION.rejected, (state, action) => {
        //console.log("kitchenCommentDeleteACTION rejected", action);
        state.kitchenCommentDetails.apiMsg.message = action.error.message;
        state.kitchenCommentDetails.apiMsg.status = action.meta.requestStatus;
      }),
      //------------------------------- kitchenCommentDeleteACTION end -------------------------
       //------------------------------- getKitchenCommentACTION start -------------------------

  builder.addCase(getKitchenCommentACTION.fulfilled, (state, action) => {
    //console.log("getKitchenCommentACTION", action);

    state.selectedKitchenCommentDetails.data = action.payload.data;
    state.selectedKitchenCommentDetails.apiMsg.status = action.meta.requestStatus;
    state.selectedKitchenCommentDetails.apiMsg.message = action.payload.message.message;
  }),
  builder.addCase(getKitchenCommentACTION.pending, (state, action) => {
   // console.log("getKitchenCommentACTION pending", action);
   state.selectedKitchenCommentDetails.apiMsg.status = action.meta.requestStatus;
  }),
  builder.addCase(getKitchenCommentACTION.rejected, (state, action) => {
    //console.log("getKitchenCommentACTION rejected", action);
    state.selectedKitchenCommentDetails.apiMsg.message = action.error.message;
    state.selectedKitchenCommentDetails.apiMsg.status = action.meta.requestStatus;
  })
  //------------------------------- getKitchenCommentACTION end -------------------------
    
  },
});

export const { RESET,ResetKitchenCommentDetailsState } = kichenCommentReducer.actions;
export default kichenCommentReducer.reducer;
