import React from "react";
import { Routes, Route } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { BrowserView, MobileView, isMobile } from "react-device-detect";
import WithLoading from "../WithLoading";
import { ReducerStateContant } from "../../helper/constants/general-const";
import RouteLoader from "../RouteLoader";
import { useSelector } from "react-redux";
const MainLayout = WithLoading(
  React.lazy(() => import("../layout/mainLayout/MainLayout"))
);
const LoginPage = WithLoading(
  React.lazy(() => import("../../pages/auth/Login/Login"))
);

// pages with loading

const DashboardIndexPage = WithLoading(React.lazy(() => import("../../pages/dashboard/Index")));

const LandingPage = WithLoading(React.lazy(() => import("../../pages/HeadOfficeSettings/LandingPage/landingPage")));
const UserStaff = WithLoading(React.lazy(() => import("../../pages/HeadOfficeSettings/UserStaff/userstaff")));
const StaffPage = WithLoading(React.lazy(() => import("../../pages/Setting/Staff/staff")));

const ChangePassword = WithLoading(React.lazy(() => import("../../pages/HeadOfficeSettings/ChangePassword/ChangePassword")));


const CustomersIndexPage = WithLoading(React.lazy(() => import("../../pages/Customers/Customers")));

const MenuTypePage = WithLoading(React.lazy(() => import("../../pages/Setting/MenuType/MenuType")));
const KitchenCommentsPage = WithLoading(React.lazy(() => import("../../pages/ProductItems/KitchenComments/kitchencomments")));
const KitchenCommentCategoriesPage = WithLoading(React.lazy(() => import("../../pages/ProductItems/KitchenCommentCategory/kitchenCommentCategories")));
const CategoriesPage = WithLoading(React.lazy(() => import("../../pages/ProductItems/Categories/categories")));
const ReportDepartmentsPage = WithLoading(React.lazy(() => import("../../pages/ProductItems/ReportDepartment/reportdepartments")));
const AddOnsPage = WithLoading(React.lazy(() => import("../../pages/ProductItems/AddOns/addOns")));
const OptionSetsPage = WithLoading(React.lazy(() => import("../../pages/ProductItems/OptionSet/optionSets")));
const VariantCategoriesPage = WithLoading(React.lazy(() => import("../../pages/ProductItems/VariantCategory/variantCategories")));
const VariantsPage = WithLoading(React.lazy(() => import("../../pages/ProductItems/Variants/variants")));
const MealCoursePage = WithLoading(React.lazy(() => import("../../pages/ProductItems/MealCourse/mealCourses")));
const MenuItemsPage = WithLoading(React.lazy(() => import("../../pages/ProductItems/MenuItems")));
const ManualpriceitemsPage = WithLoading(React.lazy(() => import("../../pages/ProductItems/Manualpriceitems")));
const PayInOutPage = WithLoading(React.lazy(() => import("../../pages/ProductItems/PayInOut/PayInOut")));
const TagMasterPage = WithLoading(React.lazy(() => import("../../pages/ProductItems/TagMaster/TagMaster")));
const AllergenMasterPage = WithLoading(React.lazy(() => import("../../pages/ProductItems/AllergenMaster/AllergenMaster")));

const StoreSettingsPage = WithLoading(React.lazy(() => import("../../pages/Setting/StoreSettings")));
const StoreAddressPage = WithLoading(React.lazy(() => import("../../pages/Setting/StoreAddress")));
const ServiceTypePage = WithLoading(React.lazy(() => import("../../pages/Setting/ServiceType/ServiceTypes")));
const PaymentSettings = WithLoading(React.lazy(() => import("../../pages/Setting/WebOrderingSettings/PaymentSettings/index")));

const WebSettingsPage = WithLoading(React.lazy(() => import("../../pages/Setting/WebOrderingSettings/websettings")));
const AdvertisementPage = WithLoading(React.lazy(() => import("../../pages/Setting/WebOrderingSettings/Advertisement/Advertisement")));
const WebContentPage = WithLoading(React.lazy(() => import("../../pages/Setting/WebOrderingSettings/WebContent/webContent")));

const TaxSettingsPage = WithLoading(React.lazy(() => import("../../pages/Setting/TaxSettings/taxsettings")));
const ServiceChargeSettingsPage = WithLoading(React.lazy(() => import("../../pages/Setting/ServiceChargeSettings/serviceChargeSettings")));
const StoreOpeningHoursPage = WithLoading(React.lazy(() => import("../../pages/Setting/StoreOpeningHours/storeOpeningHours")));
const PrinterSettingsPage = WithLoading(React.lazy(() => import("../../pages/Setting/PrinterSettings/printersettings")));
const KitchedDisplayPage = WithLoading(React.lazy(() => import("../../pages/Setting/KitchenDisplay/kitchendisplays")));

const PosUserPage = WithLoading(React.lazy(() => import("../../pages/Setting/POSUsers/posUser")))

const PosUserGroupPage = WithLoading(React.lazy(() => import("../../pages/Setting/POSUserGroups/posUserGroup")))

const PosPaymentSettings = WithLoading(React.lazy(() => import("../../pages/Setting/POSPaymentSettings/posPaymentSettings")))
// const GeneralPaymentSettings = WithLoading(React.lazy(() => import("../../pages/Setting/POSGeneralSettings/index")))


const StaffDiscountPage = WithLoading(React.lazy(() => import("../../pages/Setting/StaffDiscounts/staffDiscount")))


const OrdersPage = WithLoading(React.lazy(() => import("../../pages/Orders/Orders")));
const TodayOrdersPage = WithLoading(React.lazy(() => import("../../pages/Orders/TodayOrders")));
const PromotionPage = WithLoading(React.lazy(() => import("../../pages/DiscountPromotions/Promotions/Promotions")));
const DiscountPage = WithLoading(React.lazy(() => import("../../pages/DiscountPromotions/Discounts/discount")));

const PromotionQRCodePage = WithLoading(React.lazy(() => import("../../pages/DiscountPromotions/Discounts/qrCode")));

const ConditionalVoucherPage = WithLoading(React.lazy(() => import("../../pages/DiscountPromotions/ConditionalVouchers/conditionalVoucher")));

const RolePage = WithLoading(React.lazy(() => import("../../pages/HeadOfficeSettings/Roles/role")));

const RestaurantSettings = WithLoading(React.lazy(() => import("../../pages/Setting/RestaurantSettings/index")));
const TemplatesSettings = WithLoading(React.lazy(() => import("../../pages/Setting/Templates/Templates")));
const ReportsPage = WithLoading(React.lazy(() => import("../../pages/Reports/Reports")));

const VoidLineReportPage = WithLoading(React.lazy(() => import("../../pages/Reports/VoidLineReport/VoidLineReport")));
const NoSaleReportPage = WithLoading(React.lazy(() => import("../../pages/Reports/NoSaleReport/NoSaleReport")));
const PayinReportPage = WithLoading(React.lazy(() => import("../../pages/Reports/PayInReport/PayinReport")));
const PayoutReportPage = WithLoading(React.lazy(() => import("../../pages/Reports/PayoutReport/PayoutReport")));
const CashLiftReportPage = WithLoading(React.lazy(() => import("../../pages/Reports/CashLiftReport/CashLiftReport")));
const StaffClockInOutReportPage = WithLoading(React.lazy(() => import("../../pages/Reports/StaffClockInOutReport/StaffClockInOutReport")));
const VoidSaleReportPage = WithLoading(React.lazy(() => import("../../pages/Reports/VoidSaleReport/VoidSaleReport")));
const CashInReportPage = WithLoading(React.lazy(() => import("../../pages/Reports/CashInReport/CashInReport")));
const DayLogReportPage = WithLoading(React.lazy(() => import("../../pages/Reports/DayLogReport/DayLogReport")));

const PageNotFound = WithLoading(React.lazy(() => import("../../pages/PageNotFound")));
const AccessDeniedPage = WithLoading(React.lazy(() => import("../../pages/AccessDenied")))

const LandingPageWeb = WithLoading(React.lazy(() => import("../../pages/landingPage/landingpage")))

const RestaurantTablePage = WithLoading(React.lazy(() => import("../../pages/Setting/TableSettings/restaurantTable")))

const GeneralSettingPage = WithLoading(React.lazy(() => import("../../pages/Setting/GeneralSettings/generalSetting")))

const LinkedDevicesPage = WithLoading(React.lazy(() => import("../../pages/Setting/RestaurantDevices/LinkedDevices")))

const AreaPage = WithLoading(React.lazy(() => import("../../pages/Setting/Area/area")))

const DevicePage = WithLoading(React.lazy(() => import("../../pages/Setting/Devices/device")))

const RequireAuth = (props) => {
  return (
    <>
      {" "}
      {props.active ? (
        props.children
      ) : (
        <Navigate to="/admin/login" replace />
      )}{" "}
    </>
  );
};

const AppRouters = (props) => {

  // const restaurantID = restaurantsReducer.restaurant.data.records.filter(
  //   (item) => item.selected === true
  // )[0].id;
  const protectedLayout = (
    <>
      {
        <>
          {

            props.authorizedAccess == ReducerStateContant.pending ?
              <RouteLoader />
              :
              <RequireAuth active={props.active}>
                {isMobile ? <MobileView className="device-mob"> <MainLayout /> </MobileView> : <BrowserView className="device-desk"> <MainLayout /> </BrowserView>}
              </RequireAuth>
          }
        </>

      }
    </>
  );



  return (
    <Routes>
      <Route path="/">
        <Route index element={<LandingPageWeb />} />
        <Route path="/admin/login">
          <Route index element={<LoginPage />} />
        </Route>
        <Route path="admin" element={protectedLayout}>
          <Route index element={<DashboardIndexPage />} />
          <Route path="dashboard">
            <Route index element={<DashboardIndexPage />} />
          </Route>
          <Route path="orders">
            <Route path="all-orders" element={<OrdersPage />} />
            <Route path="today-orders" element={<TodayOrdersPage />} />
          </Route>
          <Route path="discount-promotions" >
            <Route path="promotions" element={<PromotionPage />} />
            <Route path="discount" element={<DiscountPage />} />
            <Route path="conditional-vouchers" element={<ConditionalVoucherPage />} />


          </Route>
          <Route path="discount-promotions/discount">
            <Route path="qrcode" element={<PromotionQRCodePage />} />
          </Route>
          <Route path="product-items">

            <Route path="pos-items">
              <Route path="kitchen-comment">
                <Route path="comments" element={<KitchenCommentsPage />} />
                <Route path="category" element={<KitchenCommentCategoriesPage />} />
              </Route>
              <Route path="manual-price-items" element={<ManualpriceitemsPage />} />
              <Route path="payin-payout" element={<PayInOutPage />} />
            </Route>
            <Route path="manage-category">
              <Route path="category" element={<CategoriesPage />} />
              <Route path="sub-category" element={<CategoriesPage />} />
              <Route path="report-departments" element={<ReportDepartmentsPage />} />
              <Route path="meal-course" element={<MealCoursePage />} />
            </Route>
            <Route path="manage-addons">
              <Route path="option-sets" element={<OptionSetsPage />} />
              <Route path="addons" element={<AddOnsPage />} />
            </Route>
            <Route path="manage-variant">
              <Route path="variants" element={<VariantsPage />} />
              <Route path="category" element={<VariantCategoriesPage />} />
            </Route>
            <Route path="menu_items" element={<MenuItemsPage />} />
            <Route path="tag_masters" element={<TagMasterPage />} />
            <Route path="allergen-masters" element={<AllergenMasterPage />} />

          </Route>
          <Route path="settings">
            <Route path="store-settings">
              <Route path="general" element={<StoreSettingsPage />} />
              <Route path="store-address" element={<StoreAddressPage />} />
              <Route path="order-type-service-type" element={<ServiceTypePage />} />
              <Route path="menu-type" element={<MenuTypePage />} />


              {/* THIS PAGE IS UNDER CONSTRUCTION   */}
              <Route path="store-opening-hours" element={<StoreOpeningHoursPage />} />
              <Route path="service-charge-settings" element={<ServiceChargeSettingsPage />} />
              <Route path="tax-settings" element={<TaxSettingsPage />} />
              <Route path="feedback-settings" element={<PageNotFound />} />
            </Route>
            <Route path="pos-settings">

              <Route path="manage-kitchen-printers" element={<PrinterSettingsPage />} />
              <Route path="manage-kitchen-display" element={<KitchedDisplayPage />} />
              <Route path="users" element={<PosUserPage />} />
              <Route path="users-group" element={<PosUserGroupPage />} />
              <Route path="staff" element={<StaffPage />} />
              <Route path="StaffDiscounts" element={<StaffDiscountPage />} />
              <Route path="PosPaymentSettings" element={<PosPaymentSettings />} />
              {/* <Route path="GeneralSettings" element={<GeneralPaymentSettings />} /> */}
              <Route path="restaurant-settings" element={<RestaurantSettings />} />
              <Route path="templates" element={<TemplatesSettings />} />
              <Route path="*" element={<PageNotFound />} />
            </Route>
            <Route path="web-ordering-settings">
              <Route path="web-settings" element={<WebSettingsPage />} />
              <Route path="payment-settings" element={<PaymentSettings />} />
              <Route path="advertisement" element={<AdvertisementPage />} />
              <Route path="webcontent" element={<WebContentPage />} />
            </Route>

            <Route path="table-settings">
              <Route path="table" element={<RestaurantTablePage />} />
              <Route path="general-setting" element={<GeneralSettingPage />} />
              <Route path="area" element={<AreaPage />} />

            </Route>
            <Route path="restaurant-devices">
              <Route path="linked-devices" element={<LinkedDevicesPage />} />
              <Route path="devices" element={<DevicePage />} />

            </Route>


          </Route>
          <Route path="customers">
            <Route index element={<CustomersIndexPage />} />
          </Route>
          <Route path="reports">

            <Route path="sales-reports">
              <Route index path="salessummary" element={<ReportsPage />} />
              <Route index path="salesquantity" element={<ReportsPage />} />
              {/* <Route index path="cashliftreport" element={<CashLiftReportPage />} />
              <Route index path="cashinreport" element={<CashInReportPage />} /> */}
            </Route>

            <Route path="users-report">
              <Route index path="voidlinereport" element={<VoidLineReportPage />} />
              <Route index path="nosalereport" element={<NoSaleReportPage />} />
              <Route index path="payinreport" element={<PayinReportPage />} />
              <Route index path="payoutreport" element={<PayoutReportPage />} />
              <Route index path="staffclockinoutreport" element={<StaffClockInOutReportPage />} />
              <Route index path="voidsalereport" element={<VoidSaleReportPage />} />
              <Route index path="cashliftreport" element={<CashLiftReportPage />} />
              <Route index path="cashinreport" element={<CashInReportPage />} />
              <Route index path="cashinreport" element={<CashInReportPage />} />
              <Route index path="daylogdetails" element={<DayLogReportPage />} />
            </Route>

            <Route index path="productsummaryrefund" element={<ReportsPage />} />
            <Route index path="inactivedishes" element={<ReportsPage />} />

          </Route>
          <Route path="headofficesettings">
            <Route path="landingpage">
              <Route path="general" element={<LandingPage />} />
              <Route path="navbar" element={<LandingPage />} />
              <Route path="header" element={<LandingPage />} />
              <Route path="about" element={<LandingPage />} />
              <Route path="contact" element={<LandingPage />} />
              <Route path="gallery" element={<LandingPage />} />
              <Route path="footer" element={<LandingPage />} />
            </Route>
            <Route path="roles" element={<RolePage />} />
            <Route path="userstaff" element={<UserStaff />} />

            <Route path="changepassword" element={<ChangePassword />} />

          </Route>
          <Route path="accessdenied">
            <Route index element={<AccessDeniedPage />} />
          </Route>
          <Route path="*">
            <Route path="*" element={<PageNotFound />} />
          </Route>
        </Route>
      </Route>


    </Routes>
  );
};

export default AppRouters;
