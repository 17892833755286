import { createAction, createReducer, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import {
    advertisementAddUpdateACTION,
    advertisementChangeStatusACTION,
    advertisementDeleteACTION,
    advertisementGetAllACTION,
    getAdvertisementACTION
} from '../actions/advertisement.Actions'

const INITIAL_STATE = {
    advertisements: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    adDetails: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    adStatusUpdate: {
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    insertUpdateAdvertisementDetails: {
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        data: null
    }

};

const advertisementReducre = createSlice({
    name: "Advertisement",
    initialState: INITIAL_STATE,
    reducers: {
        RESET_AD_DETAILS(state, action) {
            state.adDetails = INITIAL_STATE.adDetails;
            state.insertUpdateAdvertisementDetails = INITIAL_STATE.insertUpdateAdvertisementDetails;
        }
    },
    extraReducers: (builder) => {
        //------------------------------- advertisementAddUpdateACTION start -------------------------
        // eslint-disable-next-line
        builder.addCase(advertisementAddUpdateACTION.fulfilled, (state, action) => {
            // console.log("advertisementAddUpdateACTION", action);

            if (!action.payload.newRecord) {
                let updatedRecord = state.advertisements.data.records.map(x => {
                    if (x.id === action.meta.arg.id) {
                        return action.payload.data.data;
                    }
                    else {
                        return x;
                    }
                })

                state.advertisements.data.records = updatedRecord;
            }
            else {
                //action.meta.arg.id = action.payload.data.data;
                if (state.advertisements.data.records.length) {
                    state.advertisements.data.records.unshift(action.payload.data.data);
                }
                else {
                    state.advertisements.data.records.push(action.payload.data.data);
                }
            }

            state.insertUpdateAdvertisementDetails.apiMsg.status = action.meta.requestStatus;
            state.insertUpdateAdvertisementDetails.apiMsg.message = action.payload.message.message;
            state.insertUpdateAdvertisementDetails.data = action.payload.data.data;
        }),
            builder.addCase(advertisementAddUpdateACTION.pending, (state, action) => {
                state.insertUpdateAdvertisementDetails.apiMsg.status = action.meta.requestStatus;
            }),
            builder.addCase(advertisementAddUpdateACTION.rejected, (state, action) => {
                state.insertUpdateAdvertisementDetails.apiMsg.message = action.error.message;
                state.insertUpdateAdvertisementDetails.apiMsg.status = action.meta.requestStatus;
            }),

            //------------------------------- advertisementAddUpdateACTION start -------------------------

            //------------------------------- advertisementGetAllACTION start -------------------------

            builder.addCase(advertisementGetAllACTION.fulfilled, (state, action) => {

                state.advertisements.data = action.payload.data;
                state.advertisements.apiMsg.status = action.meta.requestStatus;
                state.advertisements.apiMsg.message = action.payload.message.message;
            }),
            builder.addCase(advertisementGetAllACTION.pending, (state, action) => {
                //console.log("advertisementGetAllACTION pending", action);
                state.advertisements.apiMsg.status = action.meta.requestStatus;
            }),
            builder.addCase(advertisementGetAllACTION.rejected, (state, action) => {
                //console.log("advertisementGetAllACTION rejected", action);
                state.advertisements.apiMsg.message = action.error.message;
                state.advertisements.apiMsg.status = action.meta.requestStatus;
            }),
            //------------------------------- advertisementGetAllACTION end -------------------------

            //------------------------------- advertisementChangeStatusACTION start -------------------------

            builder.addCase(advertisementChangeStatusACTION.fulfilled, (state, action) => {
                //console.log("advertisementChangeStatusACTION", action);

                state.advertisements.data.records = state.advertisements.data.records.map((item) => {

                    return {
                        ...item,
                        active: action.payload.id === item.id ? !item.active : item.active
                    }
                })
                state.adStatusUpdate.apiMsg.status = action.meta.requestStatus;
                state.adStatusUpdate.apiMsg.message = action.payload.message.message;
            }),
            builder.addCase(advertisementChangeStatusACTION.pending, (state, action) => {
                //console.log("advertisementChangeStatusACTION pending", action);
                state.adStatusUpdate.apiMsg.status = action.meta.requestStatus;
            }),
            builder.addCase(advertisementChangeStatusACTION.rejected, (state, action) => {
                //console.log("advertisementChangeStatusACTION rejected", action);
                state.adStatusUpdate.apiMsg.message = action.error.message;
                state.adStatusUpdate.apiMsg.status = action.meta.requestStatus;
            }),
            //------------------------------- advertisementChangeStatusACTION end -------------------------

            //------------------------------- advertisementDeleteACTION start -------------------------

            builder.addCase(advertisementDeleteACTION.fulfilled, (state, action) => {
                //console.log("advertisementDeleteACTION", action);

                state.advertisements.data.records = state.advertisements.data.records.filter((item, index) => item.id !== action.payload.data.id)

                state.adStatusUpdate.apiMsg.status = action.meta.requestStatus;
                state.adStatusUpdate.apiMsg.message = action.payload.message.message;
            }),
            builder.addCase(advertisementDeleteACTION.pending, (state, action) => {
                //console.log("advertisementDeleteACTION pending", action);
                state.adStatusUpdate.apiMsg.status = action.meta.requestStatus;
            }),
            builder.addCase(advertisementDeleteACTION.rejected, (state, action) => {
                //console.log("advertisementDeleteACTION rejected", action);
                state.adStatusUpdate.apiMsg.message = action.error.message;
                state.adStatusUpdate.apiMsg.status = action.meta.requestStatus;
            }),
            //------------------------------- advertisementDeleteACTION end -------------------------

            //------------------------------- getAdvertisementACTION start -------------------------

            builder.addCase(getAdvertisementACTION.fulfilled, (state, action) => {
                //console.log("getAdvertisementACTION", action);
                state.adDetails.data = action.payload.data;
                state.adDetails.apiMsg.status = action.meta.requestStatus;
                state.adDetails.apiMsg.message = action.payload.message.message;
            }),
            builder.addCase(getAdvertisementACTION.pending, (state, action) => {
                //console.log("getAdvertisementACTION pending", action);
                state.adDetails.apiMsg.status = action.meta.requestStatus;
            }),
            builder.addCase(getAdvertisementACTION.rejected, (state, action) => {
                //console.log("getAdvertisementACTION rejected", action);
                state.adDetails.apiMsg.message = action.error.message;
                state.adDetails.apiMsg.status = action.meta.requestStatus;
            })

        //------------------------------- getAdvertisementACTION end -------------------------


    }
});

export const { RESET_AD_DETAILS } = advertisementReducre.actions;
export default advertisementReducre.reducer;
