import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiCall, logoutUser } from '../../API';
import * as Toast from "../../component/Toast";
import _ from 'lodash'

export const getallOrdersACTION = createAsyncThunk(
    'Orders/getallOrders',
    async (data) => {
        let d = {
            device: 1,
            pageNo: data.pageNo,
            pageSize: data.pageSize,
            sortBy: 0,
            sortOrder: 0,
            filter: {
                ...data.filter
            },
        };
        return apiCall('/order/getall', 'POST', d, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {

                        let groupByOrdersStatusNames = _.groupBy(response.data.data.records, 'orderStatusName')
                        let groupByOrdersStatus = _.groupBy(response.data.data.records, 'orderStatus')
                        return {
                            ...response.data,
                            data: {
                                ...response.data.data,
                                groupByOrdersStatusNames,
                                groupByOrdersStatus
                            }
                        };
                    } else {
                        Toast.error(response.data.message.message);
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                Toast.error(error);
                return Promise.reject({
                    ...error,
                });
            });

    }
)

export const getOrderdetailsACTION = createAsyncThunk(
    "Orders/getOrderdetails",
    async (data) => {
        return apiCall("/order/getorderdetails", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        return {
                            ...response.data,
                        };
                    } else {
                        Toast.error(response.data.message.message);
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                Toast.error(error);
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const changeStatusACTION = createAsyncThunk(
    "Orders/changeStatus",
    async (data) => {
        let d = {
            restaurantID: data.restaurantID,
            orderID: data.orderID,
            updatedStatus: data.updatedStatus
        }
        return apiCall("/order/updatestatus", "POST", d, false)
            .then((response) => {
                console.log('data ', data)
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        Toast.success(response.data.message.message);
                        return {
                            ...response.data,
                            orderData: data.orderData
                        };
                    } else {
                        Toast.error(response.data.message.message);
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                Toast.error(error);
                return Promise.reject({
                    ...error,
                });
            });
    }
);