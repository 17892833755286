import { createAction, createReducer, createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import {
  getRestaurantRecordsACTION,
  getRestaurantdetailsACTION,
  updaterestaurantdetailsACTION,
  getRestaurantAddressDetailsACTION,
  updateRestaurantAddressDetailsACTION
} from "../../actions/restaurantsActions";
const INITIAL_STATE = {
  restaurant: {
    data: {
      records: [],
      totalRecords: 0,
    },
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  restaurantDetails: {
    data: {},

    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  restaurantDetailsInsertUpdateDetails: {
    data: {},

    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  restaurantAdderssDetails: {
    data: {},

    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  restaurantAdderssInsertUpdateDetails: {
    data: {},

    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
};

const restaurantsReducer = createSlice({
  name: "Restaurants",
  initialState: INITIAL_STATE,
  reducers: {
    SELECTRESTAURANTaction(state, action) {
      //console.log("SELECTRESTAURANTaction ", action);
      state.restaurant.data.records = action.payload;
    },

    RESET(state, action) {
      state = INITIAL_STATE;
    },
  },
  extraReducers: (builder) => {
    //------------------------------- getRestaurantRecordsACTION start -------------------------
    // eslint-disable-next-line
    builder.addCase(getRestaurantRecordsACTION.fulfilled, (state, action) => {
      // console.log("getRestaurantRecordsACTION", action);

      state.restaurant.data = action.payload.data;
      state.restaurant.apiMsg.status = action.meta.requestStatus;
      state.restaurant.apiMsg.message = action.payload.message.message;

      state.restaurantDetails = INITIAL_STATE.restaurantDetails;
      state.restaurantAdderssDetails = INITIAL_STATE.restaurantAdderssDetails;
    }),
      builder.addCase(getRestaurantRecordsACTION.pending, (state, action) => {
        //console.log("getRestaurantRecordsACTION pending", action);
        state.restaurant.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(getRestaurantRecordsACTION.rejected, (state, action) => {
        //console.log("getRestaurantRecordsACTION rejected", action);
        state.restaurant.apiMsg.message = action.error.message;
        state.restaurant.apiMsg.status = action.meta.requestStatus;
      }),
      //------------------------------- getRestaurantRecordsACTION end -------------------------
      //------------------------------- getRestaurantdetailsACTION start -------------------------

      builder.addCase(getRestaurantdetailsACTION.fulfilled, (state, action) => {
        //console.log("getRestaurantdetailsACTION", action);

        state.restaurantDetails.data = action.payload.data;
        state.restaurantDetails.apiMsg.status = action.meta.requestStatus;
        state.restaurantDetails.apiMsg.message = action.payload.message.message;
      }),
      builder.addCase(getRestaurantdetailsACTION.pending, (state, action) => {
        //console.log("getRestaurantdetailsACTION pending", action);
        state.restaurantDetails.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(getRestaurantdetailsACTION.rejected, (state, action) => {
        //console.log("getRestaurantdetailsACTION rejected", action);
        state.restaurantDetails.apiMsg.message = action.error.message;
        state.restaurantDetails.apiMsg.status = action.meta.requestStatus;
      }),
      //------------------------------- getRestaurantdetailsACTION end -------------------------
      //------------------------------- updaterestaurantdetailsACTION start -------------------------

      builder.addCase(
        updaterestaurantdetailsACTION.fulfilled,
        (state, action) => {
          //console.log("updaterestaurantdetailsACTION", action);
          if (state.restaurant.data && state.restaurant.data.records && state.restaurant.data.records.length) {

            state.restaurant.data.records.map(function (v, i) {
              if (v.id == action.payload.data.id) {
                state.restaurant.data.records[i].offline = action.payload.data.offline;
              }
            })
          }
          state.restaurantDetailsInsertUpdateDetails.data = action.payload.data;
          state.restaurantDetailsInsertUpdateDetails.apiMsg.status = action.meta.requestStatus;
          state.restaurantDetailsInsertUpdateDetails.apiMsg.message =
            action.payload.message.message;
          toast.success(action.payload.message.message, {
            position: toast.POSITION.TOP_RIGHT,
            toastId: "restaurantDetailsInsertUpdateDetails-sucess"
          });


        }


      ),
      builder.addCase(
        updaterestaurantdetailsACTION.pending,
        (state, action) => {
          //console.log("updaterestaurantdetailsACTION pending", action);
          state.restaurantDetailsInsertUpdateDetails.apiMsg.status = action.meta.requestStatus;
        }
      ),
      builder.addCase(
        updaterestaurantdetailsACTION.rejected,
        (state, action) => {
          //console.log("updaterestaurantdetailsACTION rejected", action);
          state.restaurantDetailsInsertUpdateDetails.apiMsg.message = action.error.message;
          state.restaurantDetailsInsertUpdateDetails.apiMsg.status = action.meta.requestStatus;
          toast.error(action.error.message, {
            position: toast.POSITION.TOP_RIGHT,
            toastId: "restaurantDetailsInsertUpdateDetails-error"
          });
        }
      ),

      //------------------------------- updaterestaurantdetailsACTION end -------------------------

      //------------------------------- getRestaurantAddressDetailsACTION start -------------------------

      builder.addCase(getRestaurantAddressDetailsACTION.fulfilled, (state, action) => {
        //console.log("getRestaurantAddressDetailsACTION", action);

        state.restaurantAdderssDetails.data = action.payload.data;
        state.restaurantAdderssDetails.apiMsg.status = action.meta.requestStatus;
        state.restaurantAdderssDetails.apiMsg.message = action.payload.message.message;
      }),
      builder.addCase(getRestaurantAddressDetailsACTION.pending, (state, action) => {
        //console.log("getRestaurantAddressDetailsACTION pending", action);
        state.restaurantAdderssDetails.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(getRestaurantAddressDetailsACTION.rejected, (state, action) => {
        //console.log("getRestaurantAddressDetailsACTION rejected", action);
        state.restaurantAdderssDetails.apiMsg.message = action.error.message;
        state.restaurantAdderssDetails.apiMsg.status = action.meta.requestStatus;
      }),
      //------------------------------- getRestaurantAddressDetailsACTION end -------------------------
      //------------------------------- updateRestaurantAddressDetailsACTION start -------------------------

      builder.addCase(
        updateRestaurantAddressDetailsACTION.fulfilled,
        (state, action) => {
          //console.log("updateRestaurantAddressDetailsACTION", action);

          state.restaurantAdderssInsertUpdateDetails.data = action.payload.data;
          state.restaurantAdderssInsertUpdateDetails.apiMsg.status = action.meta.requestStatus;
          state.restaurantAdderssInsertUpdateDetails.apiMsg.message =
            action.payload.message.message;
          toast.success(action.payload.message.message, {
            position: toast.POSITION.TOP_RIGHT,
            toastId: "restaurantAdderssInsertUpdateDetails-sucess"
          });
        }
      ),
      builder.addCase(
        updateRestaurantAddressDetailsACTION.pending,
        (state, action) => {
          //console.log("updateRestaurantAddressDetailsACTION pending", action);
          state.restaurantAdderssInsertUpdateDetails.apiMsg.status = action.meta.requestStatus;
        }
      ),
      builder.addCase(
        updateRestaurantAddressDetailsACTION.rejected,
        (state, action) => {
          //console.log("updateRestaurantAddressDetailsACTION rejected", action);
          state.restaurantAdderssInsertUpdateDetails.apiMsg.message = action.error.message;
          state.restaurantAdderssInsertUpdateDetails.apiMsg.status = action.meta.requestStatus;
          toast.error(action.error.message, {
            position: toast.POSITION.TOP_RIGHT,
            toastId: "restaurantAdderssDetails-error"
          });
        }
      );

    //------------------------------- updateRestaurantAddressDetailsACTION end -------------------------
  },
});

export const { SELECTRESTAURANTaction, RESET } = restaurantsReducer.actions;
export default restaurantsReducer.reducer;
