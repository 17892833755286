import { createAction, createReducer, createSlice } from "@reduxjs/toolkit";
import {
  categoriesMasterGetallACTION,
  addUpdateACTION,
  getCategoriesACTION,
  deleteACTION,
  changeStatusACTION,
  parentcategoriesMasterGetallACTION
} from '../../actions/categoriesActions'
import {Enums} from '../../../helper/constants/emuns-const';
const INITIAL_STATE = {
  stepper: {
    id: 1
  },
  categories: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  parentCategories: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  childCategories: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  categoriesDetails: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  selectedCategoriesDetails: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  insertUpdateCategoriesDetails: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  }
};

const categoriesReducer = createSlice({
  name: "Categories",
  initialState: INITIAL_STATE,
  reducers: {
    NEXTPREIOUS(state, action){
      state.stepper.id = action.payload
    },
    RESET(state, action) {
      state = INITIAL_STATE
    },
    ResetCategoriesDetailsState(state, action) {
      state.categoriesDetails = INITIAL_STATE.categoriesDetails;
      state.insertUpdateCategoriesDetails = INITIAL_STATE.insertUpdateCategoriesDetails;
      state.selectedCategoriesDetails=INITIAL_STATE.selectedCategoriesDetails;
    },
    ResetAllState(state, action) {
      state.categoriesDetails = INITIAL_STATE.categoriesDetails;
      state.insertUpdateCategoriesDetails = INITIAL_STATE.insertUpdateCategoriesDetails;
      state.selectedCategoriesDetails=INITIAL_STATE.selectedCategoriesDetails;
    },
  },
  extraReducers: (builder) => {
    //------------------------------- addUpdateACTION start -------------------------
    // eslint-disable-next-line
    builder.addCase(addUpdateACTION.fulfilled, (state, action) => {
      //console.log("addUpdateACTION", action);

      //state.categories.data.records.push(action.payload.data);
      if (!action.payload.newRecord) {
        let updatedRecord = state.categories.data.records.map(x => {
          if (x.id == action.meta.arg.id) {
            return action.payload.data.data;
          }
          else {
            return x;
          }
        })

        state.categories.data.records = updatedRecord;
      }
      else {
        
        if (state.categories.data.records.length) {
          state.categories.data.records.unshift(action.payload.data.data);
        }
        else {
          state.categories.data.records.push(action.payload.data.data);
        }
      }
      action.meta.arg.id = action.payload.data.data.id;
      state.selectedCategoriesDetails.data = action.meta.arg;
      state.selectedCategoriesDetails.apiMsg.status = action.meta.requestStatus;
      state.selectedCategoriesDetails.apiMsg.message = action.payload.message.message;

      state.insertUpdateCategoriesDetails.apiMsg.status = action.meta.requestStatus;
      state.insertUpdateCategoriesDetails.apiMsg.message =
        action.payload.message.message;
    }),
      builder.addCase(addUpdateACTION.pending, (state, action) => {
        //console.log("addUpdateACTION pending", action);
        state.insertUpdateCategoriesDetails.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(addUpdateACTION.rejected, (state, action) => {
        //console.log("addUpdateACTION rejected", action);
        state.insertUpdateCategoriesDetails.apiMsg.message = action.error.message;
        state.insertUpdateCategoriesDetails.apiMsg.status = action.meta.requestStatus;
      }),
      //------------------------------- addUpdateACTION end -------------------------
      //------------------------------- deleteACTION start -------------------------

      builder.addCase(deleteACTION.fulfilled, (state, action) => {
        //console.log("deleteACTION", action);

        state.categories.data.records = state.categories.data.records.filter((item, index) => item.id !== action.payload.data.id)

        state.categoriesDetails.apiMsg.status = action.meta.requestStatus;
        state.categoriesDetails.apiMsg.message =
          action.payload.message.message;
      }),
      builder.addCase(deleteACTION.pending, (state, action) => {
        //console.log("deleteACTION pending", action);
        state.categoriesDetails.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(deleteACTION.rejected, (state, action) => {
        //console.log("deleteACTION rejected", action);
        state.categoriesDetails.apiMsg.message = action.error.message;
        state.categoriesDetails.apiMsg.status = action.meta.requestStatus;
      }),
      //------------------------------- deleteACTION end -------------------------
      //------------------------------- categoriesMasterGetallACTION start -------------------------

      builder.addCase(categoriesMasterGetallACTION.fulfilled, (state, action) => {
        // console.log("categoriesMasterGetallACTION", action);
        if (action.meta.arg.filter.parentCategoryID !== undefined && action.meta.arg.filter.parentCategoryID !== null) {
          state.childCategories.data = action.payload.data;
          state.childCategories.apiMsg.status = action.meta.requestStatus;
          state.childCategories.apiMsg.message = action.payload.message.message;
        } else {

          state.categories.data = action.payload.data;
          state.categories.apiMsg.status = action.meta.requestStatus;
          state.categories.apiMsg.message = action.payload.message.message;
        }
      }),
      builder.addCase(categoriesMasterGetallACTION.pending, (state, action) => {
        // console.log("categoriesMasterGetallACTION pending", action);
        if (action.meta.arg.filter.parentCategoryID !== undefined && action.meta.arg.filter.parentCategoryID !== null) {

          state.childCategories.apiMsg.status = action.meta.requestStatus;

        } else {
          state.categories.apiMsg.status = action.meta.requestStatus;
        }
      }),
      builder.addCase(categoriesMasterGetallACTION.rejected, (state, action) => {
        //console.log("categoriesMasterGetallACTION rejected", action);
        if (action.meta.arg.filter.parentCategoryID !== undefined && action.meta.arg.filter.parentCategoryID !== null) {

          state.childCategories.apiMsg.status = action.meta.requestStatus;
          state.childCategories.apiMsg.message = action.error.message;
        } else {

          state.categories.apiMsg.message = action.error.message;
          state.categories.apiMsg.status = action.meta.requestStatus;
        }
      }),
      //------------------------------- categoriesMasterGetallACTION end -------------------------
      //------------------------------- changeStatusACTION start -------------------------

      builder.addCase(changeStatusACTION.fulfilled, (state, action) => {
        //console.log("changeStatusACTION", action);
        var sortOrderChanged = action.meta.arg.statusType===Enums.ChangeStatus.SortOrder;
        state.categories.data.records = state.categories.data.records.map((item) => {

          return {
            ...item,
            active: action.payload.id === item.id && !sortOrderChanged ? !item.active : item.active,
            sortOrder: action.payload.id === item.id && sortOrderChanged ? action.meta.arg.value : item.sortOrder
          }
        })
        state.categoriesDetails.apiMsg.status = action.meta.requestStatus;
        state.categoriesDetails.apiMsg.message = action.payload.message.message;
      }),
      builder.addCase(changeStatusACTION.pending, (state, action) => {
        //console.log("changeStatusACTION pending", action);
        state.categoriesDetails.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(changeStatusACTION.rejected, (state, action) => {
        //console.log("changeStatusACTION rejected", action);
        state.categoriesDetails.apiMsg.message = action.error.message;
        state.categoriesDetails.apiMsg.status = action.meta.requestStatus;
      }),
      //------------------------------- changeStatusACTION end -------------------------

      //------------------------------- getCategoriesACTION start -------------------------

      builder.addCase(getCategoriesACTION.fulfilled, (state, action) => {
        //console.log("getCategoriesACTION", action);

        state.selectedCategoriesDetails.data = action.payload.data;
        state.selectedCategoriesDetails.apiMsg.status = action.meta.requestStatus;
        state.selectedCategoriesDetails.apiMsg.message = action.payload.message.message;
      }),
      builder.addCase(getCategoriesACTION.pending, (state, action) => {
        //console.log("getCategoriesACTION pending", action);
        state.selectedCategoriesDetails.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(getCategoriesACTION.rejected, (state, action) => {
        //console.log("getCategoriesACTION rejected", action);
        state.selectedCategoriesDetails.apiMsg.message = action.error.message;
        state.selectedCategoriesDetails.apiMsg.status = action.meta.requestStatus;
      }),
      // ------------------------------- getCategoriesACTION end -------------------------
      //------------------------------- parentcategoriesMasterGetallACTION start -------------------------

      builder.addCase(parentcategoriesMasterGetallACTION.fulfilled, (state, action) => {
        //console.log("parentcategoriesMasterGetallACTION", action);

        state.parentCategories.data = action.payload.data;
        state.parentCategories.apiMsg.status = action.meta.requestStatus;
        state.parentCategories.apiMsg.message = action.payload.message.message;
      }),
      builder.addCase(parentcategoriesMasterGetallACTION.pending, (state, action) => {
        //console.log("parentcategoriesMasterGetallACTION pending", action);
        state.parentCategories.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(parentcategoriesMasterGetallACTION.rejected, (state, action) => {
        //console.log("parentcategoriesMasterGetallACTION rejected", action);
        state.parentCategories.apiMsg.message = action.error.message;
        state.parentCategories.apiMsg.status = action.meta.requestStatus;
      })
    // ------------------------------- parentcategoriesMasterGetallACTION end -------------------------

  },
});

export const {NEXTPREIOUS,RESET, ResetCategoriesDetailsState,ResetAllState } = categoriesReducer.actions;
export default categoriesReducer.reducer;
