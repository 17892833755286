import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiCall } from '../../../API';
import * as Toast from "../../../component/Toast";


export const getAllDayLogReportActions = createAsyncThunk(
    'DayLogReport/getAllDayLogReport',
    async (data) => {
        let d = {
            device: 1,
            pageNo: -1,
            pageSize: 0,
            sortBy: 0,
            sortOrder: 0,
            filter: {
                fromDate: data.fromDate,
                toDate: data.toDate,
            },
            restaurantID: data.restaurantID,
        };
        return apiCall('/report/getalldaylog', 'POST', d, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        return {
                            ...response.data,
                            data: {
                                ...response.data.data
                            }
                        };
                    } else {
                        Toast.error(response.data.message.message);
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                Toast.error(error);
                return Promise.reject({
                    ...error,
                });
            });
    }
)

export const getDayLogReportAction = createAsyncThunk(
    "DayLogReport/getDayLogReport",
    async (data) => {
        return apiCall("/report/getdayenddetails", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

