import { createAction, createReducer, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
// import { getAllCashLiftReportACTION, getCashLiftReportAction, updateCashLiftAction, CashLiftDeleteACTION } from "../../actions/cashInReportActions/cashLiftReport.Actions";
import { getAllCashLiftReportACTION, getCashLiftReportAction, updateCashLiftAction, CashLiftDeleteACTION } from "../../actions/cashLiftReportActions/cashLiftReport.Actions";
import { toast } from 'react-toastify';

const INITIAL_STATE = {
    cashLiftReport: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    selectedCashLift: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    updateCashLift: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },

    deleteCashLift: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
};

const cashLiftReportReducer = createSlice({
    name: "Reports",
    initialState: INITIAL_STATE,
    reducers: {
        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetCashLiftDetailsState(state, action) {
            state.selectedCashLift = INITIAL_STATE.selectedCashLift;
            state.updateCashLift = INITIAL_STATE.updateCashLift;
            state.deleteCashLift = INITIAL_STATE.deleteCashLift;
        },
    },
    extraReducers: (builder) => {
        //------------------------------- getAllCashLiftReportACTION start -------------------------
        // eslint-disable-next-line
        builder.addCase(getAllCashLiftReportACTION.fulfilled, (state, action) => {

            state.cashLiftReport.data = action.payload.data;
            state.cashLiftReport.apiMsg.status = action.meta.requestStatus;
            state.cashLiftReport.apiMsg.message = action.payload.message.message;
        }),
            builder.addCase(getAllCashLiftReportACTION.pending, (state, action) => {
                //console.log("getCashLiftReportACTION pending", action);
                state.cashLiftReport.apiMsg.status = action.meta.requestStatus;
            }),
            builder.addCase(getAllCashLiftReportACTION.rejected, (state, action) => {
                //console.log("getAllCashLiftReportACTION rejected", action);
                state.cashLiftReport.apiMsg.message = action.error.message;
                state.cashLiftReport.apiMsg.status = action.meta.requestStatus;
            })
        //------------------------------- getAllCashLiftReportACTION end -------------------------


        builder.addCase(getCashLiftReportAction.fulfilled, (state, action) => {
            state.selectedCashLift.data = action.payload.data;
            state.selectedCashLift.apiMsg.status = action.meta.requestStatus;
            state.selectedCashLift.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(getCashLiftReportAction.pending, (state, action) => {
            state.selectedCashLift.data = null;
            state.selectedCashLift.apiMsg.message = action.meta.requestStatus;;
            state.selectedCashLift.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getCashLiftReportAction.rejected, (state, action) => {
            state.selectedCashLift.apiMsg.message = action.error.message;
            state.selectedCashLift.apiMsg.status = action.meta.requestStatus;
        });



        builder.addCase(updateCashLiftAction.fulfilled,
            (state, action) => {

                state.updateCashLift.data = action.payload.data.data;
                state.updateCashLift.apiMsg.status = action.meta.requestStatus;
                state.updateCashLift.apiMsg.message = action.payload.message.message;

                // if (!action.payload.newRecord) {
                let updatedRecord = state.cashLiftReport.data.records.map(x => {
                    if (x.id == action.payload.data.data.id) {
                        return action.payload.data.data;
                    }
                    else {
                        return x;
                    }
                })

                state.cashLiftReport.data.records = updatedRecord;
                // }
                // else {
                //     if (state.device.data.records.length) {
                //         state.device.data.records.unshift(action.payload.data.data);
                //     }
                //     else {
                //         state.device.data.records.push(action.payload.data.data);
                //     }
                // }
                toast.success(action.payload.message.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });

            }
        );
        builder.addCase(
            updateCashLiftAction.pending,
            (state, action) => {
                state.updateCashLift.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            updateCashLiftAction.rejected,
            (state, action) => {

                state.updateCashLift.apiMsg.message = action.error.message;
                state.updateCashLift.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });
            }
        );



        builder.addCase(CashLiftDeleteACTION.fulfilled, (state, action) => {
            state.cashLiftReport.data.records = state.cashLiftReport.data.records.filter((item, index) => item.id !== action.payload.data.id)
            // console.log(action.meta.arg , "argssssssssss");
            state.deleteCashLift.apiMsg.status = action.meta.requestStatus;
            state.deleteCashLift.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(CashLiftDeleteACTION.pending, (state, action) => {
            state.deleteCashLift.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(CashLiftDeleteACTION.rejected, (state, action) => {
            state.deleteCashLift.apiMsg.message = action.error.message;
            state.deleteCashLift.apiMsg.status = action.meta.requestStatus;
        });


    }
});


export const { RESET, ResetCashLiftDetailsState } = cashLiftReportReducer.actions;
export default cashLiftReportReducer.reducer;
