import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiCall } from "../../../API";
import * as Toast from "../../../component/Toast";
import { Enums } from '../../../helper/constants/emuns-const';

export const getAllRestaurantTableAction = createAsyncThunk(
    "Restaurant/GetAllRestaurant",
    async (data) => {
        let d = {
            device: 1,
            pageNo: -1,
            pageSize: 0,
            sortBy: 0,
            sortOrder: 0,
            filter: {
                ...data.filter
            },
            restaurantID: data.id,
        };

        return apiCall("/restauranttable/getall", "POST", d, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        return {
                            ...response.data,
                            data: {
                                ...response.data.data
                            },
                        };
                    } else {
                        Toast.error(response.data.message.message);
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                Toast.error(error);
                return Promise.reject({
                    ...error,
                });
            });
    }
);
export const insertUpdateRestaurantTableAction = createAsyncThunk(
    "Restaurant/insertupdaterestaurant",
    async (data) => {

        return apiCall("/restauranttable/insertupdate", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        let message = response.data.message
                        // console.log("message",message);
                        return {
                            data: {
                                ...response.data,
                            },
                            message: {
                                code: response.data.message.code,
                                type: response.data.message.type,
                                message: response.data.message.message
                            },
                            newRecord: data.id ? false : true
                        }
                    } else {
                        if (!response.data.message && response.data.errors && response.data.errors.length) {
                            response.data.message = response.data.errors[0];
                        }
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
                else {
                    return Promise.reject({
                        ...response.data,
                    });
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);
export const getRestaurantTableAction = createAsyncThunk(
    "Restaurant/GetRestaurant",
    async (data) => {
        return apiCall("/restauranttable/get", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        //console.log('response.data ',response.data)
                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);
export const restautantTableChangeStatusACTION = createAsyncThunk(
    "Restaurant/changeStatus",
    async (data) => {
        return apiCall("/restauranttable/changestatus", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        if (data.statusType !== Enums.ChangeStatus.SortOrder) {
                            Toast.success(response.data.message.message)
                        }
                        return {
                            ...response.data,
                            id: data.id,
                        };
                    } else {
                        Toast.error(response.data.message.message);
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                Toast.error(error);
                return Promise.reject({
                    ...error,
                });
            });
    }
);
export const restautantTableDeleteACTION = createAsyncThunk(
    "Restaurant/delete",
    async (data) => {
        return apiCall("/restauranttable/changestatus", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        Toast.success(response.data.message.message);
                        return {
                            ...response.data,
                            data: {
                                ...data,
                            },
                        };
                    } else {
                        Toast.error(response.data.message.message);
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                Toast.error(error);
                return Promise.reject({
                    ...error,
                });
            });
    }
);
export const restaurantServiceGetallACTION = createAsyncThunk(
    "Restaurant/restaurantServiceGetall",
    async (data) => {
        let d = {
            ...data,
            device: 1,
            pageNo: -1,
            pageSize: 0,
            sortBy: 0,
            sortOrder: 0,
            filter: {
                ...data.filter
            },
            restaurantID: data.restaurantID,
        };

        return apiCall("/restaurantservice/getall", "POST", d, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        ////console.log("response.data ", response.data);
                        return {
                            ...response.data,
                            data: {
                                ...response.data.data,
                                parentServicesSelect: response.data.data.records.map(
                                    (item, index) => {
                                        return {
                                            id: item.id,
                                            name: item.name,
                                            serviceType: item.serviceType,
                                        };
                                    }
                                ),
                            },
                        };
                    } else {
                        //console.log("response error", response.data.message);
                        Toast.error(response.data.message.message);
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                Toast.error(error);
                return Promise.reject({
                    ...error,
                });
            });
    }
);