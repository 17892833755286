import { createAction, createReducer, createSlice } from "@reduxjs/toolkit";
import {
  addOnGetallACTION,
  addUpdateACTION,
  changeStatusACTION,
  deleteACTION,
  getAddOnACTION
} from '../../actions/addOnActions';
import { Enums } from '../../../helper/constants/emuns-const';

const INITIAL_STATE = {
  addOn: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  addOnDetails: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  insertUpdateaddOnDetails: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  }
};

const addOnReducer = createSlice({
  name: "AddOn",
  initialState: INITIAL_STATE,
  reducers: {

    RESET(state, action) {
      state = INITIAL_STATE
    },
    ResetaddOnDetailsState(state, action) {
      state.addOnDetails = INITIAL_STATE.addOnDetails;
      state.insertUpdateaddOnDetails = INITIAL_STATE.insertUpdateaddOnDetails;
    },
  },
  extraReducers: (builder) => {
    //------------------------------- addUpdateACTION start -------------------------
    // eslint-disable-next-line
    builder.addCase(addUpdateACTION.fulfilled, (state, action) => {
      //console.log("addUpdateACTION", action);

      if (!action.payload.newRecord) {
        let updatedRecord = state.addOn.data.records.map(x => {
          if (x.id == action.meta.arg.id) {
            return action.payload.data.data;
          }
          else {
            return x;
          }
        })

        state.addOn.data.records = updatedRecord;
      }
      else {
        if (state.addOn.data.records.length) {
          state.addOn.data.records.unshift(action.payload.data.data);
        }
        else {
          state.addOn.data.records.push(action.payload.data.data);
        }
      }

      state.insertUpdateaddOnDetails.apiMsg.status = action.meta.requestStatus;
      state.insertUpdateaddOnDetails.apiMsg.message =
        action.payload.message.message;
    }),
      builder.addCase(addUpdateACTION.pending, (state, action) => {
        //console.log("addUpdateACTION pending", action);
        state.insertUpdateaddOnDetails.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(addUpdateACTION.rejected, (state, action) => {
        //console.log("addUpdateACTION rejected", action);
        state.insertUpdateaddOnDetails.apiMsg.message = action.error.message;
        state.insertUpdateaddOnDetails.apiMsg.status = action.meta.requestStatus;
      }),
      //------------------------------- addUpdateACTION end -------------------------
      //------------------------------- deleteACTION start -------------------------

      builder.addCase(deleteACTION.fulfilled, (state, action) => {
        //console.log("deleteACTION", action);

        state.addOn.data.records = state.addOn.data.records.filter((item, index) => item.id !== action.payload.data.id)

        state.addOnDetails.apiMsg.status = action.meta.requestStatus;
        state.addOnDetails.apiMsg.message =
          action.payload.message.message;
      }),
      builder.addCase(deleteACTION.pending, (state, action) => {
        //console.log("deleteACTION pending", action);
        state.addOnDetails.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(deleteACTION.rejected, (state, action) => {
        //console.log("deleteACTION rejected", action);
        state.addOnDetails.apiMsg.message = action.error.message;
        state.addOnDetails.apiMsg.status = action.meta.requestStatus;
      }),
      //------------------------------- deleteACTION end -------------------------
      //------------------------------- addOnGetallACTION start -------------------------

      builder.addCase(addOnGetallACTION.fulfilled, (state, action) => {
        //console.log("addOnGetallACTION", action);

        state.addOn.data = action.payload.data;
        state.addOn.apiMsg.status = action.meta.requestStatus;
        state.addOn.apiMsg.message = action.payload.message.message;
      }),
      builder.addCase(addOnGetallACTION.pending, (state, action) => {
        //console.log("addOnGetallACTION pending", action);
        state.addOn.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(addOnGetallACTION.rejected, (state, action) => {
        //console.log("addOnGetallACTION rejected", action);
        state.addOn.apiMsg.message = action.error.message;
        state.addOn.apiMsg.status = action.meta.requestStatus;
      }),
      //------------------------------- addOnGetallACTION end -------------------------
      //------------------------------- changeStatusACTION start -------------------------

      builder.addCase(changeStatusACTION.fulfilled, (state, action) => {

        var sortOrderChanged = action.meta.arg.statusType === Enums.ChangeStatus.SortOrder;
        state.addOn.data.records = state.addOn.data.records.map((item) => {

          return {
            ...item,
            active: action.payload.id === item.id && !sortOrderChanged ? !item.active : item.active,
            sortOrder: action.payload.id === item.id && sortOrderChanged ? action.meta.arg.value : item.sortOrder
          }
        })
        state.addOnDetails.apiMsg.status = action.meta.requestStatus;
        state.addOnDetails.apiMsg.message = action.payload.message.message;
      }),
      builder.addCase(changeStatusACTION.pending, (state, action) => {
        //console.log("changeStatusACTION pending", action);
        state.addOnDetails.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(changeStatusACTION.rejected, (state, action) => {
        //console.log("changeStatusACTION rejected", action);
        state.addOnDetails.apiMsg.message = action.error.message;
        state.addOnDetails.apiMsg.status = action.meta.requestStatus;
      }),
      //------------------------------- changeStatusACTION end -------------------------

      //------------------------------- getAddOnACTION start -------------------------

      builder.addCase(getAddOnACTION.fulfilled, (state, action) => {
        //console.log("getAddOnACTION", action);

        state.addOnDetails.data = action.payload.data;
        state.addOnDetails.apiMsg.status = action.meta.requestStatus;
        state.addOnDetails.apiMsg.message = action.payload.message.message;
      }),
      builder.addCase(getAddOnACTION.pending, (state, action) => {
        //console.log("getAddOnACTION pending", action);
        state.addOnDetails.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(getAddOnACTION.rejected, (state, action) => {
        //console.log("getAddOnACTION rejected", action);
        state.addOnDetails.apiMsg.message = action.error.message;
        state.addOnDetails.apiMsg.status = action.meta.requestStatus;
      })
    // ------------------------------- getAddOnACTION end -------------------------

  },
});

export const { RESET, ResetaddOnDetailsState } = addOnReducer.actions;
export default addOnReducer.reducer;
