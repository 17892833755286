import { createAction, createReducer, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import {
    getallOrdersACTION,
    getOrderdetailsACTION,
    changeStatusACTION
} from '../actions/orders.Actions'
import { EnumskeyValue } from '../../helper/constants/emuns-const'

const INITIAL_STATE = {
    orders: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    orderDetails: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    orderStatusUpdate: {
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },


};

const ordersReducre = createSlice({
    name: "Orders",
    initialState: INITIAL_STATE,
    reducers: {
        RESET_ORDERS(state, action) {
            state.orders = INITIAL_STATE.orders;
        },
        RESET_ORDER_UPDATE(state, action) {
            state.orderStatusUpdate = INITIAL_STATE.orderStatusUpdate;
        },
        RESET_ORDER_DETAILS(state, action) {
            state.orderDetails = INITIAL_STATE.orderDetails;
        }
    },
    extraReducers: (builder) => {
        //------------------------------- getallOrdersACTION start -------------------------
        // eslint-disable-next-line
        builder.addCase(getallOrdersACTION.fulfilled, (state, action) => {

            state.orders.data = action.payload.data;
            state.orders.apiMsg.status = action.meta.requestStatus;
            state.orders.apiMsg.message = action.payload.message.message;
        }),
            builder.addCase(getallOrdersACTION.pending, (state, action) => {
                //console.log("getallOrdersACTION pending", action);
                state.orders.apiMsg.status = action.meta.requestStatus;
            }),
            builder.addCase(getallOrdersACTION.rejected, (state, action) => {
                //console.log("getallOrdersACTION rejected", action);
                state.orders.apiMsg.message = action.error.message;
                state.orders.apiMsg.status = action.meta.requestStatus;
            }),
            //------------------------------- getallOrdersACTION end -------------------------

            //------------------------------- changeStatusACTION start -------------------------

            builder.addCase(changeStatusACTION.fulfilled, (state, action) => {
                // console.log("changeStatusACTION", action);
                var oldStatus = EnumskeyValue.orderStatusOne.filter(x => x.value === action.payload.orderData.orderStatus)
                if (oldStatus.length <= 0) {
                    oldStatus = EnumskeyValue.orderStatusTwo.filter(x => x.value === action.payload.orderData.orderStatus)
                }
                var upatedstatus = EnumskeyValue.orderStatusOne.filter(x => x.value === action.meta.arg.updatedStatus)
                console.log('upatedstatus 1', upatedstatus)
                if (upatedstatus.length <= 0) {
                    upatedstatus = EnumskeyValue.orderStatusTwo.filter(x => x.value === action.meta.arg.updatedStatus)
                    console.log('upatedstatus 2', upatedstatus)

                }
                // Object.assign(state.orders.data.groupByOrdersStatus, source)
                if (!state.orders.data.groupByOrdersStatus[`${upatedstatus[0]?.value}`]) {

                    state.orders.data.groupByOrdersStatus[`${upatedstatus[0]?.value}`] = [{ ...action.payload.orderData, orderStatusName: upatedstatus[0]?.key, orderStatus: upatedstatus[0]?.value }]
                    state.orders.data.groupByOrdersStatus[`${oldStatus[0]?.value}`] = state.orders.data.groupByOrdersStatus[`${oldStatus[0]?.value}`].filter(x => x.orderID !== action.meta.arg.orderID)
                } else {
                    console.log('else 1')
                    state.orders.data.groupByOrdersStatus[`${upatedstatus[0]?.value}`].push({ ...action.payload.orderData, orderStatusName: upatedstatus[0]?.key, orderStatus: upatedstatus[0]?.value })
                    state.orders.data.groupByOrdersStatus[`${oldStatus[0]?.value}`] = state.orders.data.groupByOrdersStatus[`${oldStatus[0]?.value}`].filter(x => x.orderID !== action.meta.arg.orderID)
                }

                if (!state.orders.data.groupByOrdersStatusNames[upatedstatus[0]?.key]) {
                    state.orders.data.groupByOrdersStatusNames[upatedstatus[0]?.key] = [{ ...action.payload.orderData, orderStatusName: upatedstatus[0]?.key, orderStatus: upatedstatus[0]?.value }]
                    state.orders.data.groupByOrdersStatusNames[oldStatus[0]?.key] = state.orders.data.groupByOrdersStatusNames[oldStatus[0]?.key].filter(x => x.orderID !== action.meta.arg.orderID)
                } else {
                    console.log('else 2')
                    state.orders.data.groupByOrdersStatusNames[upatedstatus[0]?.key].push({ ...action.payload.orderData, orderStatusName: upatedstatus[0]?.key, orderStatus: upatedstatus[0]?.value })
                    state.orders.data.groupByOrdersStatusNames[oldStatus[0]?.key] = state.orders.data.groupByOrdersStatusNames[oldStatus[0]?.key].filter(x => x.orderID !== action.meta.arg.orderID)
                }
                state.orderStatusUpdate.apiMsg.status = action.meta.requestStatus;
                state.orderStatusUpdate.apiMsg.message = action.payload.message.message;
            }),
            builder.addCase(changeStatusACTION.pending, (state, action) => {
                //console.log("changeStatusACTION pending", action);
                state.orderStatusUpdate.apiMsg.status = action.meta.requestStatus;
            }),
            builder.addCase(changeStatusACTION.rejected, (state, action) => {
                //console.log("changeStatusACTION rejected", action);
                state.orderStatusUpdate.apiMsg.message = action.error.message;
                state.orderStatusUpdate.apiMsg.status = action.meta.requestStatus;
            }),
            //------------------------------- changeStatusACTION end -------------------------

            //------------------------------- getOrderdetailsACTION start -------------------------

            builder.addCase(getOrderdetailsACTION.fulfilled, (state, action) => {
                //console.log("getOrderdetailsACTION", action);
                state.orderDetails.data = action.payload.data;
                state.orderDetails.apiMsg.status = action.meta.requestStatus;
                state.orderDetails.apiMsg.message = action.payload.message.message;
            }),
            builder.addCase(getOrderdetailsACTION.pending, (state, action) => {
                //console.log("getOrderdetailsACTION pending", action);
                state.orderDetails.apiMsg.status = action.meta.requestStatus;
            }),
            builder.addCase(getOrderdetailsACTION.rejected, (state, action) => {
                //console.log("getOrderdetailsACTION rejected", action);
                state.orderDetails.apiMsg.message = action.error.message;
                state.orderDetails.apiMsg.status = action.meta.requestStatus;
            })

        //------------------------------- getOrderdetailsACTION end -------------------------


    }
});

export const { RESET_ORDERS, RESET_ORDER_UPDATE, RESET_ORDER_DETAILS } = ordersReducre.actions;
export default ordersReducre.reducer;
