import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiCall } from "../../../API";
import * as Toast from "../../../component/Toast";

export const getPrintLayoutAction = createAsyncThunk(
    "PrintLayout/GetPrintLayout",
    async (data) => {
        return apiCall("/restaurant/printLayout/get", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        //console.log('response.data ',response.data)
                        return {
                            ...response.data,
                        }
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);
export const insertUpdatePrintLayoutAction = createAsyncThunk(
    "PrintLayout/insertupdateprintlayout",
    async (data) => {

        return apiCall("/restaurant/printLayout/insertupdate", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        return {
                            ...response.data,
                        };
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);
export const getPrintLayoutLookupAction = createAsyncThunk(
    "PrintLayout/printLayoutlookup",
    async (data) => {
        return apiCall("/restaurant/printlayout/getlookup", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        return {
                            ...response.data,
                        };
                    } else {
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);