import { createAction, createReducer, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import {
    getAllSalesSummaryReportACTION,
    getAllSalesQuantityReportACTION,
    getAllVoidLineReportACTION,
    getAllVoidSaleReportACTION,
    getAllNoSaleReportACTION,
    getPayInReportACTION,
    getPayOutReportACTION,
    // getCashLiftReportACTION,
    // getCashInReportACTION
} from '../actions/reports.Actions'

const INITIAL_STATE = {
    salesSummaryReport: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    salesQuantityReport: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    voidLineReport: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    voidSaleReport: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    noSaleReport: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    payInReport: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    payOutReport: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    // cashLiftReport: {
    //     data: null,
    //     apiMsg: {
    //         message: null,
    //         status: null,
    //         type: null,
    //     },
    // },
    // cashInReport: {
    //     data: null,
    //     apiMsg: {
    //         message: null,
    //         status: null,
    //         type: null,
    //     },
    // }
};

const reportsReducer = createSlice({
    name: "Reports",
    initialState: INITIAL_STATE,
    reducers: {
        RESET(state, action) {
            state.salesSummaryReport = INITIAL_STATE.salesSummaryReport;
            state.salesQuantityReport = INITIAL_STATE.salesQuantityReport;
            state.StaffClockInOutReport = INITIAL_STATE.StaffClockInOutReport;
        }
    },
    extraReducers: (builder) => {
        //------------------------------- getAllSalesSummaryReportACTION start -------------------------
        // eslint-disable-next-line
        builder.addCase(getAllSalesSummaryReportACTION.fulfilled, (state, action) => {

            state.salesSummaryReport.data = action.payload.data;
            state.salesSummaryReport.apiMsg.status = action.meta.requestStatus;
            state.salesSummaryReport.apiMsg.message = action.payload.message.message;
        }),
            builder.addCase(getAllSalesSummaryReportACTION.pending, (state, action) => {
                //console.log("getAllSalesSummaryReportACTION pending", action);
                state.salesSummaryReport.apiMsg.status = action.meta.requestStatus;
            }),
            builder.addCase(getAllSalesSummaryReportACTION.rejected, (state, action) => {
                //console.log("getAllSalesSummaryReportACTION rejected", action);
                state.salesSummaryReport.apiMsg.message = action.error.message;
                state.salesSummaryReport.apiMsg.status = action.meta.requestStatus;
            })
        //------------------------------- getAllSalesSummaryReportACTION end -------------------------

        //------------------------------- getAllSalesQuantityReportACTION start -------------------------
        // eslint-disable-next-line
        builder.addCase(getAllSalesQuantityReportACTION.fulfilled, (state, action) => {

            state.salesQuantityReport.data = action.payload.data;
            state.salesQuantityReport.apiMsg.status = action.meta.requestStatus;
            state.salesQuantityReport.apiMsg.message = action.payload.message.message;
        }),
            builder.addCase(getAllSalesQuantityReportACTION.pending, (state, action) => {
                //console.log("getAllSalesQuantityReportACTION pending", action);
                state.salesQuantityReport.apiMsg.status = action.meta.requestStatus;
            }),
            builder.addCase(getAllSalesQuantityReportACTION.rejected, (state, action) => {
                //console.log("getAllSalesQuantityReportACTION rejected", action);
                state.salesQuantityReport.apiMsg.message = action.error.message;
                state.salesQuantityReport.apiMsg.status = action.meta.requestStatus;
            })
        //------------------------------- getAllSalesQuantityReportACTION end -------------------------


        //------------------------------- getAllVoidLineReportACTION start -------------------------
        // eslint-disable-next-line
        builder.addCase(getAllVoidLineReportACTION.fulfilled, (state, action) => {

            state.voidLineReport.data = action.payload.data;
            state.voidLineReport.apiMsg.status = action.meta.requestStatus;
            state.voidLineReport.apiMsg.message = action.payload.message.message;
        }),
            builder.addCase(getAllVoidLineReportACTION.pending, (state, action) => {
                //console.log("getAllVoidLineReportACTION pending", action);
                state.voidLineReport.apiMsg.status = action.meta.requestStatus;
            }),
            builder.addCase(getAllVoidLineReportACTION.rejected, (state, action) => {
                //console.log("getAllVoidLineReportACTION rejected", action);
                state.voidLineReport.apiMsg.message = action.error.message;
                state.voidLineReport.apiMsg.status = action.meta.requestStatus;
            })
        //------------------------------- getAllVoidLineReportACTION end -------------------------

        //------------------------------- getAllVoidSaleReportACTION start -------------------------
        // eslint-disable-next-line
        builder.addCase(getAllVoidSaleReportACTION.fulfilled, (state, action) => {

            state.voidSaleReport.data = action.payload.data;
            state.voidSaleReport.apiMsg.status = action.meta.requestStatus;
            state.voidSaleReport.apiMsg.message = action.payload.message.message;
        }),
            builder.addCase(getAllVoidSaleReportACTION.pending, (state, action) => {
                //console.log("getAllVoidSaleReportACTION pending", action);
                state.voidSaleReport.apiMsg.status = action.meta.requestStatus;
            }),
            builder.addCase(getAllVoidSaleReportACTION.rejected, (state, action) => {
                //console.log("getAllVoidSaleReportACTION rejected", action);
                state.voidSaleReport.apiMsg.message = action.error.message;
                state.voidSaleReport.apiMsg.status = action.meta.requestStatus;
            })
        //------------------------------- getAllVoidSaleReportACTIONN end -------------------------

        //------------------------------- getAllNoSaleReportACTION start -------------------------
        // eslint-disable-next-line
        builder.addCase(getAllNoSaleReportACTION.fulfilled, (state, action) => {

            state.noSaleReport.data = action.payload.data;
            state.noSaleReport.apiMsg.status = action.meta.requestStatus;
            state.noSaleReport.apiMsg.message = action.payload.message.message;
        }),
            builder.addCase(getAllNoSaleReportACTION.pending, (state, action) => {
                //console.log("getAllNoSaleReportACTION pending", action);
                state.noSaleReport.apiMsg.status = action.meta.requestStatus;
            }),
            builder.addCase(getAllNoSaleReportACTION.rejected, (state, action) => {
                //console.log("getAllNoSaleReportACTION rejected", action);
                state.noSaleReport.apiMsg.message = action.error.message;
                state.noSaleReport.apiMsg.status = action.meta.requestStatus;
            })
        //------------------------------- getAllNoSaleReportACTION end -------------------------

                //------------------------------- getPayInReportACTION start -------------------------
        // eslint-disable-next-line
        builder.addCase(getPayInReportACTION.fulfilled, (state, action) => {
            state.payInReport.data = action.payload.data;
            state.payInReport.apiMsg.status = action.meta.requestStatus;
            state.payInReport.apiMsg.message = action.payload.message.message;
        }),
            builder.addCase(getPayInReportACTION.pending, (state, action) => {
                //console.log("getPayInReportACTION pending", action);
                state.payInReport.apiMsg.status = action.meta.requestStatus;
            }),
            builder.addCase(getPayInReportACTION.rejected, (state, action) => {
                //console.log("getPayInReportACTION rejected", action);
                state.payInReport.apiMsg.message = action.error.message;
                state.payInReport.apiMsg.status = action.meta.requestStatus;
            })
        //------------------------------- getPayInReportACTION end -------------------------

        //------------------------------- getPayOutReportACTION start -------------------------
        // eslint-disable-next-line
        builder.addCase(getPayOutReportACTION.fulfilled, (state, action) => {
            state.payOutReport.data = action.payload.data;
            state.payOutReport.apiMsg.status = action.meta.requestStatus;
            state.payOutReport.apiMsg.message = action.payload.message.message;
        }),
            builder.addCase(getPayOutReportACTION.pending, (state, action) => {
                //console.log("getPayOutReportACTION pending", action);
                state.payOutReport.apiMsg.status = action.meta.requestStatus;
            }),
            builder.addCase(getPayOutReportACTION.rejected, (state, action) => {
                //console.log("getPayOutReportACTION rejected", action);
                state.payOutReport.apiMsg.message = action.error.message;
                state.payOutReport.apiMsg.status = action.meta.requestStatus;
            })
        //------------------------------- getPayOutReportACTION end -------------------------

        //------------------------------- getCashLiftReportACTION start -------------------------
        // eslint-disable-next-line
        // builder.addCase(getCashLiftReportACTION.fulfilled, (state, action) => {

        //     state.cashLiftReport.data = action.payload.data;
        //     state.cashLiftReport.apiMsg.status = action.meta.requestStatus;
        //     state.cashLiftReport.apiMsg.message = action.payload.message.message;
        // }),
        //     builder.addCase(getCashLiftReportACTION.pending, (state, action) => {
        //         //console.log("getCashLiftReportACTION pending", action);
        //         state.cashLiftReport.apiMsg.status = action.meta.requestStatus;
        //     }),
        //     builder.addCase(getCashLiftReportACTION.rejected, (state, action) => {
        //         //console.log("getCashLiftReportACTION rejected", action);
        //         state.cashLiftReport.apiMsg.message = action.error.message;
        //         state.cashLiftReport.apiMsg.status = action.meta.requestStatus;
        //     })
        //------------------------------- getCashLiftReportACTION end -------------------------


        //------------------------------- getCashInReportACTION start -------------------------
        // eslint-disable-next-line
        // builder.addCase(getCashInReportACTION.fulfilled, (state, action) => {

        //     state.cashInReport.data = action.payload.data;
        //     state.cashInReport.apiMsg.status = action.meta.requestStatus;
        //     state.cashInReport.apiMsg.message = action.payload.message.message;
        // }),
        //     builder.addCase(getCashInReportACTION.pending, (state, action) => {
        //         //console.log("getCashLiftReportACTION pending", action);
        //         state.cashInReport.apiMsg.status = action.meta.requestStatus;
        //     }),
        //     builder.addCase(getCashInReportACTION.rejected, (state, action) => {
        //         //console.log("getCashInReportACTION rejected", action);
        //         state.cashInReport.apiMsg.message = action.error.message;
        //         state.cashInReport.apiMsg.status = action.meta.requestStatus;
        //     })
        //------------------------------- getCashInReportACTION end -------------------------

    }
});

export const { RESET } = reportsReducer.actions;
export default reportsReducer.reducer;
