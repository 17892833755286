import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiCall, logoutUser } from '../../API';
import * as Toast from "../../component/Toast";

export const getallCustomersACTION = createAsyncThunk(
    'Customers/getallCustomers',
    async (data) => {
        let d = {
            device: 1,
            pageNo: -1,
            pageSize: 0,
            sortBy: 0,
            sortOrder: 0,
            filter: {

                ...data.filter
            },
            restaurantID: data.restaurantID,
        };
        return apiCall('/user/getall', 'POST', d, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        return {
                            ...response.data,
                        };
                    } else {
                        Toast.error(response.data.message.message);
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                Toast.error(error);
                return Promise.reject({
                    ...error,
                });
            });

    }
)
export const customersAddUpdateACTIONACTION = createAsyncThunk(
    "Customers/customersAddUpdate",
    async (data) => {
        return apiCall("/user/insertupdate", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        Toast.success(response.data.message.message);
                        return {
                            data: {
                                ...response.data,
                            },
                            message: {
                                code: response.data.message.code,
                                type: response.data.message.type,
                                message: response.data.message.message
                            },
                            newRecord: data.id ? false : true
                        };
                    } else {
                        Toast.error(response.data.message.message);
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                Toast.error(error);
                return Promise.reject({
                    ...error,
                });
            })

    }
);

export const getCustomerACTION = createAsyncThunk(
    "Customers/get",
    async (data) => {
        return apiCall("/user/me", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        return {
                            ...response.data,
                        };
                    } else {
                        Toast.error(response.data.message.message);
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                Toast.error(error);
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const changeStatusACTION = createAsyncThunk(
    "Customers/changeStatus",
    async (data) => {
        return apiCall("/user/changestatus", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        Toast.success(response.data.message.message);
                        return {
                            ...response.data,
                            id: data.id,
                        };
                    } else {
                        Toast.error(response.data.message.message);
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                Toast.error(error);
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const changeBlockStatusACTION = createAsyncThunk(
    "Customers/changeBlockStatus",
    async (data) => {
        return apiCall("/user/changestatus", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        Toast.success(response.data.message.message);
                        return {
                            ...response.data,
                            id: data.id,
                        };
                    } else {
                        Toast.error(response.data.message.message);
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                Toast.error(error);
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const customersDeleteACTION = createAsyncThunk(
    "Customers/delete",
    async (data) => {
        //console.logog("data2 ", data);
        return apiCall("/user/changestatus", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        Toast.success(response.data.message.message);
                        return {
                            ...response.data,
                            data: {
                                ...data,
                            },
                        };
                    } else {
                        Toast.error(response.data.message.message);
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                Toast.error(error);
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const customerChangePasswordAction = createAsyncThunk(
    "Customers/ChangePassword",
    async (data) => {
        return apiCall("/user/changepassword", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        Toast.success(response.data.message.message);

                        return {
                            ...response.data,
                        }
                    } else {
                        Toast.error(response.data.message.message);

                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                return Promise.reject({
                    ...error,
                });
            });
    }
);

