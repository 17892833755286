import React, { useState, useEffect } from 'react'
import AppRouters from './config/router/AppRouters';
import AuthRouters from './config/router/AuthRouters';
import { ToastContainer } from 'react-toastify';
import { Alert } from './component/Modal';
import { useSelector, useDispatch } from "react-redux";
import { StateCompleted, StateHasRecords, StateRejected } from './helper/functions/general-func';
import { ReducerStateContant } from "./helper/constants/general-const";
import { getRestaurantRecordsACTION } from "./redux-store/actions/restaurantsActions";
import { webGetStaticConfigurationACTION } from "./redux-store/actions/webSettings.Actions";
import { EnumskeyValue } from "./helper/constants/emuns-const";
import * as Toast from './component/Toast'
import { signalRInitiated } from './hooks/signalR'
import { WebAdminContext } from './web-admin-context';
const signalR = require("@microsoft/signalr");


const App = () => {
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector((state) => state.authReduce);
  const restaurantsReducer = useSelector((state) => state.restaurantsReducer);
  const webSettingsReducer = useSelector((state) => state.webSettingsReducer);
  const [authorizedAccess, setAuthorizedAccess] = useState(ReducerStateContant.pending);
  const [websiteSetttings, setWebsiteSetttings] = useState(null);
  var restaurantID = false;
  // const restaurantID = restaurantsReducer.restaurant.data.records.filter(
  //   (item) => item.selected === true
  // )[0].id;

  useEffect(() => {
    dispatch(webGetStaticConfigurationACTION());
    if (window.location.pathname === "/" || window.location.pathname === "") {
      import('./css/bootstrap.min.css');
      import('./fonts/css/all.min.css');
      import("slick-carousel/slick/slick.css");
      import("slick-carousel/slick/slick-theme.css");
      import('react-toastify/dist/ReactToastify.css');
      import("./css/style.css");
      import('./css/landing-page.css');
    }
    else {
      import('./App.css');
      import('./style.css');
      import('react-toastify/dist/ReactToastify.css');

      let link = document.querySelector("link[rel~='icon']");
      if (!link) {
          link = document.createElement('link');
          link.rel = 'icon';
          document.getElementsByTagName('head')[0].appendChild(link);
      }
     // link.href = require("./assets/logoOnlogin.jpeg");
    }
  }, [])

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getRestaurantRecordsACTION({ device: 1 }));
    }
  }, [isLoggedIn]);
  useEffect(() => {

    if (StateCompleted(restaurantsReducer.restaurant) && StateHasRecords(restaurantsReducer.restaurant)) {
      setAuthorizedAccess(ReducerStateContant.fulfilled)
      restaurantID = restaurantsReducer.restaurant.data.records.filter(
        (item) => item.selected === true
      )[0].id;
      // console.log('signalRInitiated ')
      signalRInitiated(restaurantID)
    }
    if (StateRejected(restaurantsReducer.restaurant)) {
      setAuthorizedAccess(ReducerStateContant.rejected)
    }

  }, [restaurantsReducer.restaurant])
  useEffect(() => {
    window.addEventListener('online', function (e) {
      console.log('And we\'re back :).');
    }, false);

    window.addEventListener('offline', function (e) {
      console.log('Connection is down.');
    }, false);
  }, [])
  useEffect(() => {

    if (StateCompleted(webSettingsReducer.webStaticConfiguration)) {
      if (webSettingsReducer.webStaticConfiguration.data && webSettingsReducer.webStaticConfiguration.data[window.location.hostname]) {
        setWebsiteSetttings(webSettingsReducer.webStaticConfiguration.data[window.location.hostname]);
      }
      else {
        setWebsiteSetttings(webSettingsReducer.webStaticConfiguration.data.default);
      }
    }
  }, [webSettingsReducer.webStaticConfiguration]);
  return (
    websiteSetttings ? <>
      <WebAdminContext.Provider value={websiteSetttings}>
        {
          isLoggedIn ? <AppRouters authorizedAccess={authorizedAccess} active={isLoggedIn} /> : <AuthRouters />
        }
        <ToastContainer />
        <Alert />
      </WebAdminContext.Provider>
    </> : <><h1>Please wait</h1></>
  )
}

export default App;