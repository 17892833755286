import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiCall } from "../../API";
import * as Toast from "../../component/Toast";


export const payInOutAddUpdateACTION = createAsyncThunk(
  "PayInOut/payInOutAddUpdate",
  async (data) => {
    return apiCall("/payinout/insertupdate", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          Toast.success(response.data.message.message);
          if (!response.data.hasError) {
            return {
              data:{
                ...response.data,
              },
              message:{
                code:response.data.message.code,
                type:response.data.message.type,
                message:response.data.message.message
              },
              newRecord:data.id ? false : true
            };
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        Toast.error(error);
        return Promise.reject({
          ...error,
        });
      });
  }
);
export const  payInOutDeleteACTION = createAsyncThunk(
    "PayInOut/delete",
    async (data) => {
      return apiCall("/payinout/changestatus", "POST", data, false)
        .then((response) => {
          if (response.status === 200) {
            Toast.success(response.data.message.message);
            if (!response.data.hasError) {
              return {
                ...response.data,
                data: {
                  ...data,
                },
              };
            } else {
              return Promise.reject({
                ...response.data.message,
              });
            }
          }
        })
        .catch((error) => {
          Toast.error(error);
          return Promise.reject({
            ...error,
          });
        });
    }
  );
export const payInOutGetAllACTION = createAsyncThunk(
  "PayInOut/GetAll",
  async (data) => {
    let d = {
      device: 1,
      pageNo: -1,
      pageSize: 0,
      sortBy: 0,
      sortOrder: 0,
      filter:{
        ...data.filter
      },
      restaurantID: data.restaurantID,
    };

    return apiCall("/payinout/getall", "POST", d, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
              data: {
                ...response.data.data
              },
            };
          } else {
            Toast.error(response.data.message.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        Toast.error(error);
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const payInOutChangeStatusACTION = createAsyncThunk(
  "PayInOut/changeStatus",
  async (data) => {
    return apiCall("/payinout/changestatus", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          Toast.success(response.data.message.message);
          if (!response.data.hasError) {
            return {
              ...response.data,
              id: data.id,
            };
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        Toast.error(error);
        return Promise.reject({
          ...error,
        });
      });
  }
);


export const getPayInOutACTION = createAsyncThunk(
  "PayInOut/get",
  async (data) => {
    return apiCall("/payinout/get", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            };
          } else {
            Toast.error(response.data.message.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        Toast.error(error);
        return Promise.reject({
          ...error,
        });
      });
  }
);
