import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiCall } from "../../../API";

export const getRestaurantRecordsACTION = createAsyncThunk(
  "Restaurants/getrecords",
  async (data) => {
    if(!localStorage.getItem("usersInfo"))
    {
       return Promise.reject(false);;
    }
    return apiCall("/restaurant/getall", "POST", data, false)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          if (!response.data.hasError) {
            let data,id;
            if (localStorage.getItem("last_rest") !== null){
                data = JSON.parse(localStorage.getItem("last_rest"));
                id = data.lastRestaurant.id;
            }else{
                data = localStorage.getItem("last_rest")
            }
            return {
              ...response.data,
              data: {
                ...response.data.data,
                records: response.data.data.records.map((item, index) => {
                 
                    if (item.id === id) {
                        
                      localStorage.setItem(
                        "last_rest",
                        JSON.stringify({ lastRestaurant: item })
                      );
                      return {
                        ...item,
                        selected: true,
                      };
                    } else if ( data === null && index === 0) {
                        localStorage.setItem("last_rest",JSON.stringify({ lastRestaurant: item }));
                          return {
                            ...item,
                            selected:true 
                          };

                    }else {
                      return {
                        ...item,
                        selected:false 
                      };
                    }
                  
                }),
              },
            };
          } else {
            //console.log("response error", response.data.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const getRestaurantdetailsACTION = createAsyncThunk(
  "Restaurants/getRestaurantdetails",
  async (data) => {
    return apiCall("/restaurant/getgeneraldetails", "POST", {device:1,id:data}, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            //console.log('response.data ',response.data)
            return {
              ...response.data,
            }
          } else {
            //console.log("response error", response.data.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const updaterestaurantdetailsACTION = createAsyncThunk(
  "Restaurants/updaterestaurantdetails",
  async (data) => {
    return apiCall("/restaurant/updategeneraldetails", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError && response.data.data === data.id) {
            //console.log('response.data ',response.data.message)
            let message = response.data.message
            return {
             data:{
              ...data,
            },
            message:{
              code:response.data.message.code,
              type:response.data.message.type,
              message:response.data.message.message
            }
            }
          } else {
            //console.log("response error", response.data.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const getRestaurantAddressDetailsACTION = createAsyncThunk(
  "Restaurants/getRestaurantAddressDetails",
  async (data) => {
    return apiCall("/restaurant/getaddressdetails", "POST", {device:1,id:data}, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            //console.log('response.data ',response.data)
            return {
              ...response.data,
            }
          } else {
            //console.log("response error", response.data.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const updateRestaurantAddressDetailsACTION = createAsyncThunk(
  "Restaurants/updateRestaurantAddressDetails",
  async (data) => {
    return apiCall("/restaurant/updateaddressdetails", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError && response.data.data === data.id) {
            //console.log('response.data ',response.data.message)
            let message = response.data.message
            return {
             data:{
              ...data,
            },
            message:{
              code:response.data.message.code,
              type:response.data.message.type,
              message:response.data.message.message
            }
            }
          } else {
            //console.log("response error", response.data.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);