import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiCall } from "../../API";
import * as Toast from "../../component/Toast";

export const getLookupsACTION = createAsyncThunk(
  "lookup/get",
  async (data) => {
    return apiCall("/lookup/getvalue", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            };
          } else {
            Toast.error(response.data.message.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        Toast.error(error);
        return Promise.reject({
          ...error,
        });
      });
  }
);
