import { createSlice } from "@reduxjs/toolkit";
import { getAllUserStaffAction, getUserStaffAction, insertUpdateUserStaffAction, userStaffChangeStatusACTION, userStaffDeleteACTION } from "../../actions/settings/userStaffAction";
import { toast } from 'react-toastify';

const INITIAL_STATE = {

    userStaff: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    insertUpdateDetails: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },

    selectedUserStaff: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    activeInactiveDetails: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },

    deleteDetails: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    }




};

const userStaffReducer = createSlice({
    name: "UserStaff",
    initialState: INITIAL_STATE,
    reducers: {

        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetUserStaffDetailsState(state, action) {
            state.selectedUserStaff = INITIAL_STATE.selectedUserStaff;
            state.insertUpdateDetails = INITIAL_STATE.insertUpdateDetails;
        },
    },



    extraReducers: (builder) => {

        // get all userStaff action start

        builder.addCase(getAllUserStaffAction.fulfilled, (state, action) => {
            state.userStaff.data = action.payload.data;
            state.userStaff.apiMsg.status = action.meta.requestStatus;
            state.userStaff.apiMsg.message = "success";
        });
        builder.addCase(getAllUserStaffAction.pending, (state, action) => {
            // console.log("getAllUserStaffAction pending", action);
            state.userStaff.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllUserStaffAction.rejected, (state, action) => {
            // console.log("getAllUserStaffAction rejected", action);
            state.userStaff.apiMsg.message = action.error.message;
            state.userStaff.apiMsg.status = action.meta.requestStatus;
        });

        // get all userStaff action end

        // insert update userStaff Action start

        builder.addCase(
            insertUpdateUserStaffAction.fulfilled,
            (state, action) => {

                state.insertUpdateDetails.data = action.payload.data.data;
                state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateDetails.apiMsg.message = action.payload.message.message;

                if (!action.payload.newRecord) {
                    let updatedRecord = state.userStaff.data.records.map(x => {
                        if (x.id == action.payload.data.data.id) {
                            return action.payload.data.data;
                        }
                        else {
                            return x;
                        }
                    })

                    state.userStaff.data.records = updatedRecord;
                }
                else {
                    if (state.userStaff.data.records.length) {
                        state.userStaff.data.records.unshift(action.payload.data.data);
                    }
                    else {
                        state.userStaff.data.records.push(action.payload.data.data);
                    }
                }
                toast.success(action.payload.message.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });

            }
        );
        builder.addCase(
            insertUpdateUserStaffAction.pending,
            (state, action) => {
                state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdateUserStaffAction.rejected,
            (state, action) => {

                console.log(action.error.message);
                state.insertUpdateDetails.apiMsg.message = action.error.message;
                state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });
            }
        );

        //   insert update userStaff action end

        //------------------------------- getuserStaff start -------------------------

        builder.addCase(getUserStaffAction.fulfilled, (state, action) => {
            state.selectedUserStaff.data = action.payload.data;
            state.selectedUserStaff.apiMsg.status = action.meta.requestStatus;
            state.selectedUserStaff.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getUserStaffAction.pending, (state, action) => {
            state.selectedUserStaff.data = null;
            state.selectedUserStaff.apiMsg.message = action.meta.requestStatus;;
            state.selectedUserStaff.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getUserStaffAction.rejected, (state, action) => {
            state.selectedUserStaff.apiMsg.message = action.error.message;
            state.selectedUserStaff.apiMsg.status = action.meta.requestStatus;
        });


        //   get userStaff end



        // userStaffChangeStatusACTION start


        builder.addCase(userStaffChangeStatusACTION.fulfilled, (state, action) => {
            state.userStaff.data.records = state.userStaff.data.records.map((item) => {

                return {
                    ...item,
                    active: action.payload.id === item.id ? !item.active : item.active
                }
            })
            state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;
            state.activeInactiveDetails.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(userStaffChangeStatusACTION.pending, (state, action) => {
            state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(userStaffChangeStatusACTION.rejected, (state, action) => {
            state.activeInactiveDetails.apiMsg.message = action.error.message;
            state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;
        });


        // userStaffChangeStatusACTION end



        //------------------------------- userStaffDeleteACTION start -------------------------

        builder.addCase(userStaffDeleteACTION.fulfilled, (state, action) => {

            state.userStaff.data.records = state.userStaff.data.records.filter((item, index) => item.id !== action.payload.data.id)

            state.deleteDetails.apiMsg.status = action.meta.requestStatus;
            state.deleteDetails.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(userStaffDeleteACTION.pending, (state, action) => {
            state.deleteDetails.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(userStaffDeleteACTION.rejected, (state, action) => {
            state.deleteDetails.apiMsg.message = action.error.message;
            state.deleteDetails.apiMsg.status = action.meta.requestStatus;
        });
        //------------------------------- userStaffDeleteACTION end -------------------------

    }

});

export const { RESET, ResetUserStaffDetailsState } = userStaffReducer.actions;
export default userStaffReducer.reducer;