import React from "react";
import { Routes, Route } from "react-router-dom";
import WithLoading from "../WithLoading";
const LoginPage = WithLoading(React.lazy(() => import("../../pages/auth/Login/Login")));
const LandingPage = WithLoading(React.lazy(() => import("../../pages/landingPage/landingpage")))
const AuthRouters = () => {
    return (
        <Routes>

            <Route path="/admin" >
                <Route index element={<LoginPage />} />
                <Route path="login" element={<LoginPage />} />
                <Route path="*" element={<LoginPage />} />
            </Route>
            <Route path="/" >
                <Route index element={<LandingPage />} />
                <Route path="*" element={<LandingPage />} />
            </Route>
            

        </Routes>
    );


};

export default AuthRouters;
