import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiCall } from "../../API";
import * as Toast from "../../component/Toast";

export const restaurantSettingsAddUpdateACTION = createAsyncThunk(
  "RestaurantSetting/addUpdate",
  async (data) => {
    //console.logog("data2 ", data);
    return apiCall("/RestaurantSettings/insertupdate", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            Toast.success(response.data.message.message);
            return {
              data: {
                ...response.data,
              },
              message: {
                code: response.data.message.code,
                type: response.data.message.type,
                message: response.data.message.message
              },

            };
          } else {
            Toast.error(response.data.message.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        Toast.error(error);
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const getRestaurantSettingsACTION = createAsyncThunk(
  "RestaurantSetting/Get",
  async (data) => {
    return apiCall("/RestaurantSettings/get", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            };
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);
export const getRestaurantSurchargeSettingsACTION = createAsyncThunk(
  "RestaurantSetting/GetSurcharge",
  async (data) => {
    return apiCall("/RestaurantSettings/get", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            };
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const getRestaurantGeneralSettingsACTION = createAsyncThunk(
  "RestaurantSetting/GetGeneralSetting",
  async (data) => {
    return apiCall("/RestaurantSettings/get", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            };
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const getRestaurantReceiptSettingsACTION = createAsyncThunk(
  "RestaurantSetting/GetReceiptSettings",
  async (data) => {
    return apiCall("/RestaurantSettings/get", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            };
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const restaurantGeneralSettingsAddUpdateACTION = createAsyncThunk(
  "RestaurantSetting/addUpdateGeneralSettings",
  async (data) => {
    //console.logog("data2 ", data);
    return apiCall("/RestaurantSettings/insertupdate", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            Toast.success(response.data.message.message);
            return {
              data: {
                ...response.data,
              },
              message: {
                code: response.data.message.code,
                type: response.data.message.type,
                message: response.data.message.message
              },

            };
          } else {
            Toast.error(response.data.message.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        Toast.error(error);
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const getRestaurantEndOfDayReportSettingsACTION = createAsyncThunk(
  "RestaurantSetting/getEndOfDayReportSetting",
  async (data) => {
    return apiCall("/RestaurantSettings/get", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            };
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const restaurantEndOfDayReportSettingsAddUpdateACTION = createAsyncThunk(
  "RestaurantSetting/addUpdateEndOfDayReportSettings",
  async (data) => {
    //console.logog("data2 ", data);
    return apiCall("/RestaurantSettings/insertupdate", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            Toast.success(response.data.message.message);
            return {
              data: {
                ...response.data,
              },
              message: {
                code: response.data.message.code,
                type: response.data.message.type,
                message: response.data.message.message
              },

            };
          } else {
            Toast.error(response.data.message.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        Toast.error(error);
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const getRestaurantSocialSettingsACTION = createAsyncThunk(
  "RestaurantSetting/getRestaurantSocialSettings",
  async (data) => {
    return apiCall("/RestaurantSettings/get", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            };
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const restaurantSocialSettingsAddUpdateACTION = createAsyncThunk(
  "RestaurantSetting/addUpdateSocialSettings",
  async (data) => {
    //console.logog("data2 ", data);
    return apiCall("/RestaurantSettings/insertupdate", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            Toast.success(response.data.message.message);
            return {
              data: {
                ...response.data,
              },
              message: {
                code: response.data.message.code,
                type: response.data.message.type,
                message: response.data.message.message
              },

            };
          } else {
            Toast.error(response.data.message.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        Toast.error(error);
        return Promise.reject({
          ...error,
        });
      });
  }
);


export const getPosGeneralSettingsACTION = createAsyncThunk(
  "RestaurantSetting/getPosGeneralSettings",
  async (data) => {
    return apiCall("/RestaurantSettings/get", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
            };
          } else {
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        Toast.error(error);
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const PosGeneralSettingsInsertUpdateACTION = createAsyncThunk(
  "RestaurantSetting/PosGeneralSettingsInsertUpdate",
  async (data) => {

    return apiCall("/RestaurantSettings/insertupdate", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            Toast.success(response.data.message.message);
            return {
              data: {
                ...response.data,
              },
              message: {
                code: response.data.message.code,
                type: response.data.message.type,
                message: response.data.message.message
              },

            };
          } else {
            Toast.error(response.data.message.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        Toast.error(error);
        return Promise.reject({
          ...error,
        });
      });
  }
);


