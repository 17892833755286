import { createSlice } from "@reduxjs/toolkit";
import { getAllDeviceAction, getDeviceAction, insertUpdateDeviceAction, deviceChangeStatusACTION, deviceDeleteACTION, getDeviceSettingAction, insertUpdateDeviceSettingAction } from "../../actions/deviceAction/deviceAction";
import { toast } from 'react-toastify';

const INITIAL_STATE = {

    device: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    insertUpdateDetails: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },

    selectedDevice: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    activeInactiveDetails: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },

    deleteDetails: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },
    selectedDeviceSetting: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        groupType: null
    },

    insertUpdateSettingDetails: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },
};

const deviceReducer = createSlice({
    name: "Device",
    initialState: INITIAL_STATE,
    reducers: {

        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetDeviceDetailsState(state, action) {
            state.insertUpdateDetails = INITIAL_STATE.insertUpdateDetails;
            state.selectedDevice = INITIAL_STATE.selectedDevice;
            state.selectedDeviceSetting = INITIAL_STATE.selectedDeviceSetting;
            state.insertUpdateSettingDetails = INITIAL_STATE.insertUpdateSettingDetails
        },
    },

    extraReducers: (builder) => {

        //getAllDeviceAction start

        builder.addCase(getAllDeviceAction.fulfilled, (state, action) => {
            state.device.data = action.payload.data;
            state.device.apiMsg.status = action.meta.requestStatus;
            state.device.apiMsg.message = "success";
        });
        builder.addCase(getAllDeviceAction.pending, (state, action) => {
            state.device.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllDeviceAction.rejected, (state, action) => {
            state.device.apiMsg.message = action.error.message;
            state.device.apiMsg.status = action.meta.requestStatus;
        });

        // getAllDeviceAction end

        // insertUpdateDeviceAction start

        builder.addCase(
            insertUpdateDeviceAction.fulfilled,
            (state, action) => {

                state.insertUpdateDetails.data = action.payload.data.data;
                state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateDetails.apiMsg.message = action.payload.message.message;

                if (!action.payload.newRecord) {
                    let updatedRecord = state.device.data.records.map(x => {
                        if (x.id == action.payload.data.data.id) {
                            return action.payload.data.data;
                        }
                        else {
                            return x;
                        }
                    })

                    state.device.data.records = updatedRecord;
                }
                else {
                    if (state.device.data.records.length) {
                        state.device.data.records.unshift(action.payload.data.data);
                    }
                    else {
                        state.device.data.records.push(action.payload.data.data);
                    }
                }
                toast.success(action.payload.message.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });

            }
        );
        builder.addCase(
            insertUpdateDeviceAction.pending,
            (state, action) => {
                state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdateDeviceAction.rejected,
            (state, action) => {

                state.insertUpdateDetails.apiMsg.message = action.error.message;
                state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });
            }
        );

        //   insertUpdateDeviceAction end

        //------------------------------- getDeviceAction start -------------------------

        builder.addCase(getDeviceAction.fulfilled, (state, action) => {
            state.selectedDevice.data = action.payload.data;
            state.selectedDevice.apiMsg.status = action.meta.requestStatus;
            state.selectedDevice.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(getDeviceAction.pending, (state, action) => {
            state.selectedDevice.data = null;
            state.selectedDevice.apiMsg.message = action.meta.requestStatus;;
            state.selectedDevice.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getDeviceAction.rejected, (state, action) => {
            state.selectedDevice.apiMsg.message = action.error.message;
            state.selectedDevice.apiMsg.status = action.meta.requestStatus;
        });


        //  getDeviceAction end



        // deviceChangeStatusACTION start


        builder.addCase(deviceChangeStatusACTION.fulfilled, (state, action) => {

            if (action.meta.arg.statusType === 10) {

                state.device.data.records = state.device.data.records.map((item) => {
                    return {
                        ...item,
                        deviceID: action.payload.id === item.id ? null : item.deviceID,
                    };
                });
            } else {

                state.device.data.records = state.device.data.records.map((item) => {
                    return {
                        ...item,
                        active: action.payload.id === item.id ? !item.active : item.active,
                    };
                });
            }


            state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;
            state.activeInactiveDetails.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(deviceChangeStatusACTION.pending, (state, action) => {
            state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(deviceChangeStatusACTION.rejected, (state, action) => {
            state.activeInactiveDetails.apiMsg.message = action.error.message;
            state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;
        });


        // deviceChangeStatusACTION end



        //------------------------------- deviceDeleteACTION start -------------------------

        builder.addCase(deviceDeleteACTION.fulfilled, (state, action) => {

            state.device.data.records = state.device.data.records.filter((item, index) => item.id !== action.payload.data.id)
            state.deleteDetails.apiMsg.status = action.meta.requestStatus;
            state.deleteDetails.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(deviceDeleteACTION.pending, (state, action) => {
            state.deleteDetails.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(deviceDeleteACTION.rejected, (state, action) => {
            state.deleteDetails.apiMsg.message = action.error.message;
            state.deleteDetails.apiMsg.status = action.meta.requestStatus;
        });
        //------------------------------- deviceDeleteACTION end -------------------------

        //------------------------------- getDeviceSettingAction start -------------------------

        builder.addCase(getDeviceSettingAction.fulfilled, (state, action) => {
            state.selectedDeviceSetting.data = action.payload.data;
            state.selectedDeviceSetting.apiMsg.status = action.meta.requestStatus;
            state.selectedDeviceSetting.apiMsg.message = action.payload.message.message;
            state.selectedDeviceSetting.groupType = action.meta.arg.groupType;

        });
        builder.addCase(getDeviceSettingAction.pending, (state, action) => {
            state.selectedDeviceSetting.data = null;
            state.selectedDeviceSetting.apiMsg.message = action.meta.requestStatus;;
            state.selectedDeviceSetting.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getDeviceSettingAction.rejected, (state, action) => {
            state.selectedDeviceSetting.apiMsg.message = action.error.message;
            state.selectedDeviceSetting.apiMsg.status = action.meta.requestStatus;
        });

        //  getDeviceSettingAction end

        // insertUpdateDeviceSettingAction start

        builder.addCase(
            insertUpdateDeviceSettingAction.fulfilled,
            (state, action) => {

                state.insertUpdateSettingDetails.data = action.payload.data.data;
                state.insertUpdateSettingDetails.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateSettingDetails.apiMsg.message = action.payload.message.message;


                toast.success(action.payload.message.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });

            }
        );
        builder.addCase(
            insertUpdateDeviceSettingAction.pending,
            (state, action) => {
                state.insertUpdateSettingDetails.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdateDeviceSettingAction.rejected,
            (state, action) => {

                state.insertUpdateSettingDetails.apiMsg.message = action.error.message;
                state.insertUpdateSettingDetails.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });
            }
        );

        //   insertUpdateDeviceSettingAction end

    }
});
export const { RESET, ResetDeviceDetailsState } = deviceReducer.actions;

export default deviceReducer.reducer;