import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiCall } from "../../../API";
import * as Toast from "../../../component/Toast";


export const allergenMasterGetAllAction = createAsyncThunk(
    "AllergenMaster/GetAll",
    async (data) => {
        let d = {
            device: 1,
            pageNo: -1,
            pageSize: 0,
            sortBy: 0,
            sortOrder: 0,
            filter: {
                ...data.filter
            },
            restaurantID: data.id,
        };

        return apiCall("/allergenmaster/getall", "POST", d, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        return {
                            ...response.data,
                            data: {
                                ...response.data.data
                            },
                        };
                    } else {
                        Toast.error(response.data.message.message);
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                Toast.error(error);
                return Promise.reject({
                    ...error,
                });
            });
    }
);


export const allergenMasterAddUpdateACTION = createAsyncThunk(
    "AllergenMaster/addUpdate",
    async (data) => {
        //console.logog("data2 ", data);
        return apiCall("/allergenmaster/insertupdate", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        Toast.success(response.data.message.message);
                        return {
                            data: {
                                ...response.data,
                            },
                            message: {
                                code: response.data.message.code,
                                type: response.data.message.type,
                                message: response.data.message.message
                            },
                            newRecord: data.id ? false : true
                        };
                    } else {
                        Toast.error(response.data.message.message);
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                Toast.error(error);
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const getallergenMasterACTION = createAsyncThunk(
    "AllergenMaster/get",
    async (data) => {
        return apiCall("/allergenmaster/get", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        return {
                            ...response.data,
                        };
                    } else {
                        Toast.error(response.data.message.message);
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                Toast.error(error);
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const allergenMasterChangeStatusACTION = createAsyncThunk(
    "AllergenMaster/changeStatus",
    async (data) => {
        return apiCall("/allergenmaster/changestatus", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        Toast.success(response.data.message.message);
                        return {
                            ...response.data,
                            id: data.id,
                        };
                    } else {
                        Toast.error(response.data.message.message);
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                Toast.error(error);
                return Promise.reject({
                    ...error,
                });
            });
    }
);

export const allergenMasterDeleteACTION = createAsyncThunk(
    "AllergenMaster/delete",
    async (data) => {
        //console.logog("data2 ", data);
        return apiCall("/allergenmaster/changestatus", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        Toast.success(response.data.message.message);
                        return {
                            ...response.data,
                            data: {
                                ...data,
                            },
                        };
                    } else {
                        Toast.error(response.data.message.message);
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                Toast.error(error);
                return Promise.reject({
                    ...error,
                });
            });
    }
);