import { createAction, createReducer, createSlice } from "@reduxjs/toolkit";
import {
  menuTypeGetAllACTION,
  changeStatusACTION,
  addACTION,
  updateACTION,
  getMenuTypeACTION,
  deleteACTION
} from "../../actions/menutypeActions";
import { Enums } from '../../../helper/constants/emuns-const';

const INITIAL_STATE = {
  menuType: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  menuTypeDetails: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  insertUpdateMenuTypeDetails: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  }
};

const menutypeReducer = createSlice({
  name: "MenuType",
  initialState: INITIAL_STATE,
  reducers: {
    RESET(state, action) {
      state = INITIAL_STATE;
    },
    ResetMenuTypeDetailsState(state, action) {
      state.menuTypeDetails = INITIAL_STATE.menuTypeDetails;
      state.insertUpdateMenuTypeDetails = INITIAL_STATE.insertUpdateMenuTypeDetails;
    },
  },
  extraReducers: (builder) => {
    //------------------------------- updateACTION start -------------------------
    // eslint-disable-next-line
    builder.addCase(updateACTION.fulfilled, (state, action) => {


      // state.restaurantserviceDetails.data.records.push(action.payload.data);

    }),
      builder.addCase(updateACTION.pending, (state, action) => {

      }),
      builder.addCase(updateACTION.rejected, (state, action) => {

      }),
      //------------------------------- updateACTION end -------------------------
      //------------------------------- addACTION start -------------------------

      builder.addCase(addACTION.fulfilled, (state, action) => {
        //console.log("addACTION", action,action.meta);

        if (!action.payload.newRecord) {
          let updatedRecord = state.menuType.data.records.map(x => {
            if (x.id == action.meta.arg.id) {
              return action.payload.data.data;
            }
            else {
              return x;
            }
          })

          state.menuType.data.records = updatedRecord;
        }
        else {
          if (state.menuType.data.records.length) {
            state.menuType.data.records.unshift(action.payload.data.data);
          }
          else {
            state.menuType.data.records.push(action.payload.data.data);
          }
        }

        state.insertUpdateMenuTypeDetails.data = action.payload.data.data;
        state.insertUpdateMenuTypeDetails.apiMsg.status =
          action.meta.requestStatus;
        state.insertUpdateMenuTypeDetails.apiMsg.message =
          action.payload.message.message;
      }),
      builder.addCase(addACTION.pending, (state, action) => {

        state.insertUpdateMenuTypeDetails.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(addACTION.rejected, (state, action) => {

        state.insertUpdateMenuTypeDetails.apiMsg.message = action.error.message;
        state.insertUpdateMenuTypeDetails.apiMsg.status = action.meta.requestStatus;
      }),
      //------------------------------- addACTION end -------------------------

      //------------------------------- deleteACTION start -------------------------

      builder.addCase(deleteACTION.fulfilled, (state, action) => {

        state.menuType.data.records = state.menuType.data.records.filter((item, index) => item.id !== action.payload.data.id)

        state.menuTypeDetails.apiMsg.status = action.meta.requestStatus;
        state.menuTypeDetails.apiMsg.message =
          action.payload.message.message;
        state.menuTypeDetails = INITIAL_STATE.menuTypeDetails;
      }),
      builder.addCase(deleteACTION.pending, (state, action) => {
        state.menuTypeDetails.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(deleteACTION.rejected, (state, action) => {
        state.menuTypeDetails.apiMsg.message = action.error.message;
        state.menuTypeDetails.apiMsg.status = action.meta.requestStatus;
      }),
      //------------------------------- deleteACTION end -------------------------

      //------------------------------- menuTypeGetAllACTION start -------------------------

      builder.addCase(menuTypeGetAllACTION.fulfilled, (state, action) => {

        state.menuType.data = action.payload.data;
        state.menuType.apiMsg.status = action.meta.requestStatus;
        state.menuType.apiMsg.message = action.payload.message.message;

      }),
      builder.addCase(menuTypeGetAllACTION.pending, (state, action) => {

        state.menuType.apiMsg.status = action.meta.requestStatus;

      }),
      builder.addCase(menuTypeGetAllACTION.rejected, (state, action) => {

        state.menuType.apiMsg.message = action.error.message;
        state.menuType.apiMsg.status = action.meta.requestStatus;
      }),

      //------------------------------- menuTypeGetAllACTION end -------------------------
      //------------------------------- changeStatusACTION start -------------------------

      builder.addCase(changeStatusACTION.fulfilled, (state, action) => {

        var sortOrderChanged = action.meta.arg.statusType === Enums.ChangeStatus.SortOrder;
        state.menuType.data.records = state.menuType.data.records.map((item) => {

          return {
            ...item,
            active: action.payload.id === item.id && !sortOrderChanged ? !item.active : item.active,
            sortOrder: action.payload.id === item.id && sortOrderChanged ? action.meta.arg.value : item.sortOrder
          }
        })
        state.menuTypeDetails.apiMsg.status = action.meta.requestStatus;
        state.menuTypeDetails.apiMsg.message = action.payload.message.message;
      }),
      builder.addCase(changeStatusACTION.pending, (state, action) => {

        state.menuTypeDetails.apiMsg.status = action.meta.requestStatus;;
      }),
      builder.addCase(changeStatusACTION.rejected, (state, action) => {

        state.menuTypeDetails.apiMsg.message = action.error.message;
        state.menuTypeDetails.apiMsg.status = action.meta.requestStatus;
      }),

      //------------------------------- changeStatusACTION end -------------------------
      //------------------------------- getMenuTypeACTION start -------------------------

      builder.addCase(getMenuTypeACTION.fulfilled, (state, action) => {

        state.menuTypeDetails.data = action.payload.data;
        state.menuTypeDetails.apiMsg.status = action.meta.requestStatus;
        state.menuTypeDetails.apiMsg.message = action.payload.message.message;
      }),
      builder.addCase(getMenuTypeACTION.pending, (state, action) => {

        state.menuTypeDetails.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(getMenuTypeACTION.rejected, (state, action) => {

        state.menuTypeDetails.apiMsg.message = action.error.message;
        state.menuTypeDetails.apiMsg.status = action.meta.requestStatus;
      })
    //------------------------------- getMenuTypeACTION end -------------------------
  },
});

export const { RESET, ResetMenuTypeDetailsState } = menutypeReducer.actions;
export default menutypeReducer.reducer;
