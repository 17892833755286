import { createAction, createReducer, createSlice } from "@reduxjs/toolkit";
import {
    restaurantSettingsAddUpdateACTION,
    getRestaurantSettingsACTION,
    getRestaurantSurchargeSettingsACTION,
    getRestaurantGeneralSettingsACTION,
    getRestaurantReceiptSettingsACTION,
    restaurantGeneralSettingsAddUpdateACTION,
    getRestaurantEndOfDayReportSettingsACTION,
    restaurantEndOfDayReportSettingsAddUpdateACTION,
    getRestaurantSocialSettingsACTION,
    restaurantSocialSettingsAddUpdateACTION,
    PosGeneralSettingsInsertUpdateACTION,
    getPosGeneralSettingsACTION
} from '../actions/restaurantSetting.Actions'
import _ from "lodash";

const INITIAL_STATE = {
    restaurantSetting: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    insertUpdaterestaurantSetting: {
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    restaurantSurchargeSetting: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    restaurantGeneralSetting: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    restaurantReceiptSetting: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    insertUpdaterestaurantGeneralSetting: {
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    restaurantEndOfDayReportSetting: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    insertUpdateEndOfDayReportSetting: {
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    restaurantSocialSettings: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    insertUpdateSocialSetting: {
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    posGeneralSetting: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    insertUpdatePosGeneralSetting: {
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
};

const restaurantSettingReducer = createSlice({
    name: "RestaurantSetting",
    initialState: INITIAL_STATE,
    reducers: {
        RESETRESTAURANTSETTING(state, action) {
            state.restaurantSetting = INITIAL_STATE.restaurantSetting
            state.insertUpdaterestaurantSetting = INITIAL_STATE.insertUpdaterestaurantSetting
            state.restaurantGeneralSetting = INITIAL_STATE.restaurantGeneralSetting
            state.insertUpdaterestaurantGeneralSetting = INITIAL_STATE.insertUpdaterestaurantGeneralSetting
        }
    },
    extraReducers: (builder) => {
        //------------------------------- getRestaurantSettingsACTION start -------------------------
        // eslint-disable-next-line
        builder.addCase(getRestaurantSettingsACTION.fulfilled, (state, action) => {

            state.restaurantSetting.data = action.payload.data;
            state.restaurantSetting.apiMsg.status = action.meta.requestStatus;
            state.restaurantSetting.apiMsg.message = action.payload.message.message;

        });

        builder.addCase(getRestaurantSettingsACTION.pending, (state, action) => {

            state.restaurantSetting.apiMsg.status = action.meta.requestStatus;

        });

        builder.addCase(getRestaurantSettingsACTION.rejected, (state, action) => {

            state.restaurantSetting.apiMsg.message = action.error.message;
            state.restaurantSetting.apiMsg.status = action.meta.requestStatus;
        });
        //------------------------------- getRestaurantSettingsACTION end -------------------------


        builder.addCase(getRestaurantSurchargeSettingsACTION.fulfilled, (state, action) => {


            state.restaurantSurchargeSetting.data = action.payload.data;
            state.restaurantSurchargeSetting.apiMsg.status = action.meta.requestStatus;
            state.restaurantSurchargeSetting.apiMsg.message = action.payload.message.message;


        });

        builder.addCase(getRestaurantSurchargeSettingsACTION.pending, (state, action) => {

            state.restaurantSurchargeSetting.apiMsg.status = action.meta.requestStatus;

        });

        builder.addCase(getRestaurantSurchargeSettingsACTION.rejected, (state, action) => {

            state.restaurantSurchargeSetting.apiMsg.message = action.error.message;
            state.restaurantSurchargeSetting.apiMsg.status = action.meta.requestStatus;
        });

        //------------------------------- restaurantSettingsAddUpdateACTION start -------------------------
        builder.addCase(restaurantSettingsAddUpdateACTION.fulfilled, (state, action) => {


            state.insertUpdaterestaurantSetting.apiMsg.status = action.meta.requestStatus;
            state.insertUpdaterestaurantSetting.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(restaurantSettingsAddUpdateACTION.pending, (state, action) => {

            state.insertUpdaterestaurantSetting.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(restaurantSettingsAddUpdateACTION.rejected, (state, action) => {

            state.insertUpdaterestaurantSetting.apiMsg.message = action.error.message;
            state.insertUpdaterestaurantSetting.apiMsg.status = action.meta.requestStatus;
        });
        //------------------------------- restaurantSettingsAddUpdateACTION end -------------------------



        builder.addCase(getRestaurantGeneralSettingsACTION.fulfilled, (state, action) => {

            state.restaurantGeneralSetting.data = action.payload.data;
            state.restaurantGeneralSetting.apiMsg.status = action.meta.requestStatus;
            state.restaurantGeneralSetting.apiMsg.message = action.payload.message.message;

        });

        builder.addCase(getRestaurantGeneralSettingsACTION.pending, (state, action) => {


            state.restaurantGeneralSetting.apiMsg.status = action.meta.requestStatus;

        });

        builder.addCase(getRestaurantGeneralSettingsACTION.rejected, (state, action) => {


            state.restaurantGeneralSetting.apiMsg.message = action.error.message;
            state.restaurantGeneralSetting.apiMsg.status = action.meta.requestStatus;
        });
        //------------------------------- getRestaurantSettingsACTION end -------------------------


        builder.addCase(getRestaurantReceiptSettingsACTION.fulfilled, (state, action) => {


            state.restaurantReceiptSetting.data = action.payload.data;
            state.restaurantReceiptSetting.apiMsg.status = action.meta.requestStatus;
            state.restaurantReceiptSetting.apiMsg.message = action.payload.message.message;


        });

        builder.addCase(getRestaurantReceiptSettingsACTION.pending, (state, action) => {

            state.restaurantReceiptSetting.apiMsg.status = action.meta.requestStatus;

        });

        builder.addCase(getRestaurantReceiptSettingsACTION.rejected, (state, action) => {

            state.restaurantReceiptSetting.apiMsg.message = action.error.message;
            state.restaurantReceiptSetting.apiMsg.status = action.meta.requestStatus;
        });


        //------------------------------- restaurantSettingsAddUpdateACTION start -------------------------
        builder.addCase(restaurantGeneralSettingsAddUpdateACTION.fulfilled, (state, action) => {


            state.insertUpdaterestaurantGeneralSetting.apiMsg.status = action.meta.requestStatus;
            state.insertUpdaterestaurantGeneralSetting.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(restaurantGeneralSettingsAddUpdateACTION.pending, (state, action) => {

            state.insertUpdaterestaurantGeneralSetting.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(restaurantGeneralSettingsAddUpdateACTION.rejected, (state, action) => {

            state.insertUpdaterestaurantGeneralSetting.apiMsg.message = action.error.message;
            state.insertUpdaterestaurantGeneralSetting.apiMsg.status = action.meta.requestStatus;
        });
        //------------------------------- restaurantSettingsAddUpdateACTION end -------------------------



        builder.addCase(getRestaurantEndOfDayReportSettingsACTION.fulfilled, (state, action) => {


            state.restaurantEndOfDayReportSetting.data = action.payload.data;
            state.restaurantEndOfDayReportSetting.apiMsg.status = action.meta.requestStatus;
            state.restaurantEndOfDayReportSetting.apiMsg.message = action.payload.message.message;


        });

        builder.addCase(getRestaurantEndOfDayReportSettingsACTION.pending, (state, action) => {

            state.restaurantEndOfDayReportSetting.apiMsg.status = action.meta.requestStatus;

        });

        builder.addCase(getRestaurantEndOfDayReportSettingsACTION.rejected, (state, action) => {

            state.restaurantEndOfDayReportSetting.apiMsg.message = action.error.message;
            state.restaurantEndOfDayReportSetting.apiMsg.status = action.meta.requestStatus;
        });


        //------------------------------- restaurantSettingsAddUpdateACTION start -------------------------
        builder.addCase(restaurantEndOfDayReportSettingsAddUpdateACTION.fulfilled, (state, action) => {


            state.insertUpdateEndOfDayReportSetting.apiMsg.status = action.meta.requestStatus;
            state.insertUpdateEndOfDayReportSetting.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(restaurantEndOfDayReportSettingsAddUpdateACTION.pending, (state, action) => {

            state.insertUpdateEndOfDayReportSetting.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(restaurantEndOfDayReportSettingsAddUpdateACTION.rejected, (state, action) => {

            state.insertUpdateEndOfDayReportSetting.apiMsg.message = action.error.message;
            state.insertUpdateEndOfDayReportSetting.apiMsg.status = action.meta.requestStatus;
        });
        //------------------------------- restaurantSettingsAddUpdateACTION end -------------------------


        builder.addCase(getRestaurantSocialSettingsACTION.fulfilled, (state, action) => {


            state.restaurantSocialSettings.data = action.payload.data;
            state.restaurantSocialSettings.apiMsg.status = action.meta.requestStatus;
            state.restaurantSocialSettings.apiMsg.message = action.payload.message.message;


        });

        builder.addCase(getRestaurantSocialSettingsACTION.pending, (state, action) => {

            state.restaurantSocialSettings.apiMsg.status = action.meta.requestStatus;

        });

        builder.addCase(getRestaurantSocialSettingsACTION.rejected, (state, action) => {

            state.restaurantSocialSettings.apiMsg.message = action.error.message;
            state.restaurantSocialSettings.apiMsg.status = action.meta.requestStatus;
        });


        //------------------------------- restaurantSettingsAddUpdateACTION start -------------------------
        builder.addCase(restaurantSocialSettingsAddUpdateACTION.fulfilled, (state, action) => {


            state.insertUpdateSocialSetting.apiMsg.status = action.meta.requestStatus;
            state.insertUpdateSocialSetting.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(restaurantSocialSettingsAddUpdateACTION.pending, (state, action) => {

            state.insertUpdateSocialSetting.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(restaurantSocialSettingsAddUpdateACTION.rejected, (state, action) => {

            state.insertUpdateSocialSetting.apiMsg.message = action.error.message;
            state.insertUpdateSocialSetting.apiMsg.status = action.meta.requestStatus;
        });
        //------------------------------- restaurantSettingsAddUpdateACTION end -------------------------

        //------------------------------- getPosGeneralSettingsACTION start -------------------------

        builder.addCase(getPosGeneralSettingsACTION.fulfilled, (state, action) => {

            state.posGeneralSetting.data = action.payload.data;
            state.posGeneralSetting.apiMsg.status = action.meta.requestStatus;
            state.posGeneralSetting.apiMsg.message = action.payload.message.message;

        });

        builder.addCase(getPosGeneralSettingsACTION.pending, (state, action) => {

            state.posGeneralSetting.apiMsg.status = action.meta.requestStatus;

        });

        builder.addCase(getPosGeneralSettingsACTION.rejected, (state, action) => {

            state.posGeneralSetting.apiMsg.message = action.error.message;
            state.posGeneralSetting.apiMsg.status = action.meta.requestStatus;
        });

        //------------------------------- getPosGeneralSettingsACTION end -------------------------


        //------------------------------- PosGeneralSettingsInsertUpdateACTION start -------------------------


        builder.addCase(PosGeneralSettingsInsertUpdateACTION.fulfilled, (state, action) => {


            state.insertUpdatePosGeneralSetting.apiMsg.status = action.meta.requestStatus;
            state.insertUpdatePosGeneralSetting.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(PosGeneralSettingsInsertUpdateACTION.pending, (state, action) => {

            state.insertUpdatePosGeneralSetting.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(PosGeneralSettingsInsertUpdateACTION.rejected, (state, action) => {

            state.insertUpdatePosGeneralSetting.apiMsg.message = action.error.message;
            state.insertUpdatePosGeneralSetting.apiMsg.status = action.meta.requestStatus;
        });
        //------------------------------- PosGeneralSettingsInsertUpdateACTION end -------------------------

    }
});

export const { RESETRESTAURANTSETTING } = restaurantSettingReducer.actions;
export default restaurantSettingReducer.reducer;
