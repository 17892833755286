import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiCall } from "../../../API";
import * as Toast from "../../../component/Toast"
import { Enums } from '../../../helper/constants/emuns-const';


export const menuTypeGetAllACTION = createAsyncThunk(
  "MenuType/meunTypeGetAll",
  async (data) => {
    let d = {
      device: 1,
      pageNo: -1,
      pageSize: 0,
      sortBy: 0,
      sortOrder: 0,
      filter: {
        ...data.filter
      },
      restaurantID: data.restaurantID,
    };

    return apiCall("/menutype/getall", "POST", d, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            //console.log("response.data ", response.data);

            return {
              ...response.data,
              data: {
                ...response.data.data,

              },
            };
          } else {
            //console.log("response error", response.data.message);
            Toast.error(response.data.message.message)
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        Toast.error(error)
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const changeStatusACTION = createAsyncThunk(
  "MenuType/changeStatus",
  async (data) => {
    return apiCall("/menutype/changestatus", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            if (data.statusType !== Enums.ChangeStatus.SortOrder) {
              Toast.success(response.data.message.message)
            }
            return {
              ...response.data,
              id: data.id,
            };
          } else {
            //console.log("response error", response.data.message);
            Toast.error(response.data.message.message)
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        Toast.error(error)
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const updateACTION = createAsyncThunk(
  "MenuType/update",
  async (data) => {
    //console.log("data2 ", data);
    return apiCall("/menutype/insertupdate", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            //console.log("response.data ", response.data);
            Toast.success(response.data.message.message)
            return {
              ...response.data,
              data: {
                ...data,
              },
            };
          } else {
            //console.log("response error", response.data.message);
            Toast.error(response.data.message.message)
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        Toast.error(error)
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const addACTION = createAsyncThunk(
  "MenuType/add",
  async (data) => {
    //console.log("data2 ", data);
    return apiCall("/menutype/insertupdate", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            //console.log("response.data ", response.data);
            Toast.success(response.data.message.message)
            return {
              data: {
                id: response.data.data,
                ...response.data,
              },
              message: {
                code: response.data.message.code,
                type: response.data.message.type,
                message: response.data.message.message
              },
              newRecord: data.id ? false : true
            }
          } else {
            //console.log("response error", response.data.message);
            Toast.error(response.data.message.message)
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        Toast.error(error)
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const deleteACTION = createAsyncThunk(
  "MenuType/delete",
  async (data) => {
    //console.log("data2 ", data);
    return apiCall("/menutype/changestatus", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            //console.log("response.data ", response.data);
            Toast.success(response.data.message.message);
            return {
              ...response.data,
              data: {
                ...data,
              },
            };
          } else {
            //console.log("response error", response.data.message);
            Toast.error(response.data.message.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        Toast.error(error);
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const getMenuTypeACTION = createAsyncThunk(
  "MenuType/getMenuType",
  async (data) => {
    return apiCall("/menutype/get", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            //console.log("response.data ", response.data);

            return {
              ...response.data,

            };
          } else {
            //console.log("response error", response.data.message);
            Toast.error(response.data.message.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        Toast.error(error);
        return Promise.reject({
          ...error,
        });
      });
  }
);
