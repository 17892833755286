import { createAction, createReducer, createSlice } from "@reduxjs/toolkit";
import { GetAllPosUserGroupAction, insertUpdatePosUserGroupAction, getPosUserGroupACTION, posUserGroupChangeStatusACTION, posUserGroupDeleteACTION } from "../../actions/settings/PosUserGroupAction";

const INITIAL_STATE = {
    posUserGroup: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    insertUpdatePosUserGroup: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    selectedPosUserGroupDetails: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },


    activeInactiveDetails: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },

    deleteDetails: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    }
};


const posUserGroupReducer = createSlice({
    name: "posUserGroup",
    initialState: INITIAL_STATE,
    reducers: {
        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetposUserGroupDetailsState(state, action) {
            state.selectedPosUserGroupDetails = INITIAL_STATE.selectedPosUserGroupDetails;
            state.insertUpdatePosUserGroup = INITIAL_STATE.insertUpdatePosUserGroup;
        },
    },


    extraReducers: (builder) => {

        // GetAllPosUserGroupAction start

        builder.addCase(GetAllPosUserGroupAction.fulfilled, (state, action) => {
            state.posUserGroup.data = action.payload.data;
            state.posUserGroup.apiMsg.status = action.meta.requestStatus;
            state.posUserGroup.apiMsg.message = "success";
        });
        builder.addCase(GetAllPosUserGroupAction.pending, (state, action) => {
            // console.log("GetAllPosUserGroupAction pending", action);
            state.posUserGroup.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(GetAllPosUserGroupAction.rejected, (state, action) => {
            // console.log("GetAllPosUserGroupAction rejected", action);
            state.posUserGroup.apiMsg.message = action.error.message;
            state.posUserGroup.apiMsg.status = action.meta.requestStatus;
        });


        // GetAllPosUserGroupAction end

        // insertUpdatePosUserGroupAction Action start

        builder.addCase(
            insertUpdatePosUserGroupAction.fulfilled,
            (state, action) => {
                // console.log("insertUpdateTillAction", action);
                if (!action.payload.newRecord) {
                    let updatedRecord = state.posUserGroup.data.records.map(x => {
                        if (x.id == action.meta.arg.id) {
                            return action.payload.data.data;
                        }
                        else {
                            return x;
                        }
                    })

                    state.posUserGroup.data.records = updatedRecord;
                }
                else {
                    if (state.posUserGroup.data.records.length) {
                        state.posUserGroup.data.records.unshift(action.payload.data.data);
                    }
                    else {
                        state.posUserGroup.data.records.push(action.payload.data.data);
                    }
                }
                state.insertUpdatePosUserGroup.apiMsg.status = action.meta.requestStatus;
                state.insertUpdatePosUserGroup.apiMsg.message = action.payload.message.message;
            }
        );
        builder.addCase(
            insertUpdatePosUserGroupAction.pending,
            (state, action) => {
                //console.log("insertUpdateVatAction pending", action);
                state.insertUpdatePosUserGroup.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdatePosUserGroupAction.rejected,
            (state, action) => {
                state.insertUpdatePosUserGroup.apiMsg.message = action.error.message;
                state.insertUpdatePosUserGroup.apiMsg.status = action.meta.requestStatus;
            }
        );


        //   insert update tagMaster action end


        // get getPosUserGroupACTION  start


        builder.addCase(getPosUserGroupACTION.fulfilled, (state, action) => {
            //console.log("getPosUserGroupACTION", action);
            state.selectedPosUserGroupDetails.data = action.payload.data;
            state.selectedPosUserGroupDetails.apiMsg.status = action.meta.requestStatus;
            state.selectedPosUserGroupDetails.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(getPosUserGroupACTION.pending, (state, action) => {
            //console.log("getPosUserGroupACTION pending", action);
            state.selectedPosUserGroupDetails.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getPosUserGroupACTION.rejected, (state, action) => {
            //console.log("getPosUserGroupACTION rejected", action);
            state.selectedPosUserGroupDetails.apiMsg.message = action.error.message;
            state.selectedPosUserGroupDetails.apiMsg.status = action.meta.requestStatus;
        });
        // get TagMasterACTION  end



        
            // posUserGroupChangeStatusACTION start


            builder.addCase(posUserGroupChangeStatusACTION.fulfilled, (state, action) => {
                state.posUserGroup.data.records = state.posUserGroup.data.records.map((item) => {

                    return {
                        ...item,
                        active: action.payload.id === item.id ? !item.active : item.active
                    }
                })
                state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;
                state.activeInactiveDetails.apiMsg.message = action.payload.message.message;
            });
            builder.addCase(posUserGroupChangeStatusACTION.pending, (state, action) => {
                //console.log("posUserGroupChangeStatusACTION pending", action);
                state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;
            });
            builder.addCase(posUserGroupChangeStatusACTION.rejected, (state, action) => {
                //console.log("posUserGroupChangeStatusACTION rejected", action);
                state.activeInactiveDetails.apiMsg.message = action.error.message;
                state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;
            });


            // posUserGrouprChangeStatusACTION end



            //------------------------------- posUserGroupDeleteACTION start -------------------------

            builder.addCase(posUserGroupDeleteACTION.fulfilled, (state, action) => {
                //console.log("posUserGroupDeleteACTION", action);

                state.posUserGroup.data.records = state.tagMaster.data.records.filter((item, index) => item.id !== action.payload.data.id)

                state.deleteDetails.apiMsg.status = action.meta.requestStatus;
                state.deleteDetails.apiMsg.message = action.payload.message.message;
            });
            builder.addCase(posUserGroupDeleteACTION.pending, (state, action) => {
                //console.log("posUserGroupDeleteACTION pending", action);
                state.deleteDetails.apiMsg.status = action.meta.requestStatus;
            });
            builder.addCase(posUserGroupDeleteACTION.rejected, (state, action) => {
                //console.log("posUserGroupDeleteACTION rejected", action);
                state.deleteDetails.apiMsg.message = action.error.message;
                state.deleteDetails.apiMsg.status = action.meta.requestStatus;
            });
        //------------------------------- posUserGroupDeleteACTION end -------------------------

    },
});

export const { RESET, ResetposUserGroupDetailsState } = posUserGroupReducer.actions;
export default posUserGroupReducer.reducer;