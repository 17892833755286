import { createSlice } from "@reduxjs/toolkit";
import { getAllAreaAction, insertUpdateAreaAction, getAreaAction, areaChangeStatusACTION, areaDeleteACTION, restaurantServiceGetallACTION, getFloorMapDataAction } from "../../actions/settings/areaAction";
import { toast } from 'react-toastify';

const INITIAL_STATE = {

    area: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    insertUpdateDetails: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },

    selectedArea: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    activeInactiveDetails: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },

    deleteDetails: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },
    restaurantServices: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    floorMap:{
        data:{},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    }

};

const areaReducer = createSlice({
    name: "Area",
    initialState: INITIAL_STATE,
    reducers: {

        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetAreaDetailsState(state, action) {
            state.insertUpdateDetails = INITIAL_STATE.insertUpdateDetails;
            state.selectedArea = INITIAL_STATE.selectedArea;
            state.floorMap = INITIAL_STATE.floorMap
        },
    },

    extraReducers: (builder) => {

        //getAllAreaAction start

        builder.addCase(getAllAreaAction.fulfilled, (state, action) => {
            state.area.data = action.payload.data;
            state.area.apiMsg.status = action.meta.requestStatus;
            state.area.apiMsg.message = "success";
        });
        builder.addCase(getAllAreaAction.pending, (state, action) => {
            state.area.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllAreaAction.rejected, (state, action) => {
            state.area.apiMsg.message = action.error.message;
            state.area.apiMsg.status = action.meta.requestStatus;
        });

        // getAllAreaAction end

        // insertUpdateAreaAction start

        builder.addCase(
            insertUpdateAreaAction.fulfilled,
            (state, action) => {

                state.insertUpdateDetails.data = action.payload.data.data;
                state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateDetails.apiMsg.message = action.payload.message.message;

                if (!action.payload.newRecord) {
                    let updatedRecord = state.area.data.records.map(x => {
                        if (x.id == action.payload.data.data.id) {
                            return action.payload.data.data;
                        }
                        else {
                            return x;
                        }
                    })

                    state.area.data.records = updatedRecord;
                }
                else {
                    if (state.area.data.records.length) {
                        state.area.data.records.unshift(action.payload.data.data);
                    }
                    else {
                        state.area.data.records.push(action.payload.data.data);
                    }
                }
                toast.success(action.payload.message.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });

            }
        );
        builder.addCase(
            insertUpdateAreaAction.pending,
            (state, action) => {
                state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdateAreaAction.rejected,
            (state, action) => {

                state.insertUpdateDetails.apiMsg.message = action.error.message;
                state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });
            }
        );

        //   insertUpdateAreaAction end

        //------------------------------- getAreaAction start -------------------------

        builder.addCase(getAreaAction.fulfilled, (state, action) => {
            state.selectedArea.data = action.payload.data;
            state.selectedArea.apiMsg.status = action.meta.requestStatus;
            state.selectedArea.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(getAreaAction.pending, (state, action) => {
            state.selectedArea.data = null;
            state.selectedArea.apiMsg.message = action.meta.requestStatus;;
            state.selectedArea.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAreaAction.rejected, (state, action) => {
            state.selectedArea.apiMsg.message = action.error.message;
            state.selectedArea.apiMsg.status = action.meta.requestStatus;
        });


        //  getAreaAction end



        // areaChangeStatusACTION start


        builder.addCase(areaChangeStatusACTION.fulfilled, (state, action) => {
            state.area.data.records = state.area.data.records.map((item) => {

                return {
                    ...item,
                    active: action.payload.id === item.id ? !item.active : item.active
                }
            })
            state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;
            state.activeInactiveDetails.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(areaChangeStatusACTION.pending, (state, action) => {
            state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(areaChangeStatusACTION.rejected, (state, action) => {
            state.activeInactiveDetails.apiMsg.message = action.error.message;
            state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;
        });


        // areaChangeStatusACTION end



        //------------------------------- areaDeleteACTION start -------------------------

        builder.addCase(areaDeleteACTION.fulfilled, (state, action) => {

            state.area.data.records = state.area.data.records.filter((item, index) => item.id !== action.payload.data.id)
            state.deleteDetails.apiMsg.status = action.meta.requestStatus;
            state.deleteDetails.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(areaDeleteACTION.pending, (state, action) => {
            state.deleteDetails.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(areaDeleteACTION.rejected, (state, action) => {
            state.deleteDetails.apiMsg.message = action.error.message;
            state.deleteDetails.apiMsg.status = action.meta.requestStatus;
        });
        //------------------------------- areaDeleteACTION end -------------------------


        //------------------------------- restaurantServiceGetallACTION start -------------------------

        builder.addCase(restaurantServiceGetallACTION.fulfilled, (state, action) => {
            //console.log("restaurantServiceGetallACTION fulfilled", action);
            if (action.meta.arg.withDefault && action.payload.data.records && action.payload.data.records.length) {
                action.payload.data.records.unshift({
                    active: true,
                    id: null,
                    name: 'Default',
                    parentServiceID: null,
                    parentServiceName: '',
                    serviceCharge: 0,
                    serviceType: null,
                    serviceTypeName: 'Default',
                    serviceTypeID: null

                });
            }


            state.restaurantServices.data = action.payload.data;
            state.restaurantServices.apiMsg.status = action.meta.requestStatus;
            state.restaurantServices.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(restaurantServiceGetallACTION.pending, (state, action) => {
            //console.log("restaurantServiceGetallACTION pending", action);
            state.restaurantServices.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(restaurantServiceGetallACTION.rejected, (state, action) => {
            //console.log("restaurantServiceGetallACTION rejected", action);
            state.restaurantServices.apiMsg.message = action.error.message;
            state.restaurantServices.apiMsg.status = action.meta.requestStatus;
        });
        //------------------------------- restaurantServiceGetallACTION end -------------------------


                //------------------------------- floorMapGetAllDataAction start -------------------------


        builder.addCase(getFloorMapDataAction.fulfilled, (state, action) => {
            state.floorMap.data = action.payload.data;
            state.floorMap.apiMsg.status = action.meta.requestStatus;
            state.floorMap.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(getFloorMapDataAction.pending, (state, action) => {
            state.floorMap.data = null;
            state.floorMap.apiMsg.message = action.meta.requestStatus;;
            state.floorMap.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getFloorMapDataAction.rejected, (state, action) => {
            state.floorMap.apiMsg.message = action.error.message;
            state.floorMap.apiMsg.status = action.meta.requestStatus;
        });

        //------------------------------- floorMapGetAllDataAction end -------------------------


    }
});
export const { RESET, ResetAreaDetailsState } = areaReducer.actions;

export default areaReducer.reducer;
