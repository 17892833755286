import { createSlice } from "@reduxjs/toolkit";
import { getAllLinkedDevices, getQrCodeAction, linkedDeviceChangeStatus } from "../../actions/settings/restaurantDevicesActions";
import { toast } from 'react-toastify';

const INITIAL_STATE = {

    qrCode: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    linkedDevices: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    }

};

const restaurantDevicesReducer = createSlice({
    name: "QrCode",
    initialState: INITIAL_STATE,
    reducers: {

        RESET(state, action) {
            state.qrCode = INITIAL_STATE.qrCode;
        },

        RESET_LINKEDDEVICES(state, action) {
            state.linkedDevices = INITIAL_STATE.linkedDevices;
        }
    },


    extraReducers: (builder) => {

        // getQrCodeAction start

        builder.addCase(getQrCodeAction.fulfilled, (state, action) => {
            state.qrCode.data = action.payload.data;
            state.qrCode.apiMsg.status = action.meta.requestStatus;
            state.qrCode.apiMsg.message = "success";
        });
        builder.addCase(getQrCodeAction.pending, (state, action) => {
            state.qrCode.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getQrCodeAction.rejected, (state, action) => {
            state.qrCode.apiMsg.message = action.error.message;
            state.qrCode.apiMsg.status = action.meta.requestStatus;
        });

        // getQrCodeAction end


        // getAllLinkedDevices start

        builder.addCase(getAllLinkedDevices.fulfilled, (state, action) => {
            state.linkedDevices.data = action.payload.data;
            state.linkedDevices.apiMsg.status = action.meta.requestStatus;
            state.linkedDevices.apiMsg.message = "success";
        });
        builder.addCase(getAllLinkedDevices.pending, (state, action) => {
            state.linkedDevices.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllLinkedDevices.rejected, (state, action) => {
            state.linkedDevices.apiMsg.message = action.error.message;
            state.linkedDevices.apiMsg.status = action.meta.requestStatus;
        });

        // getAllLinkedDevices End


        //  linkedDeviceDeleteACTION start

        builder.addCase(linkedDeviceChangeStatus.fulfilled, (state, action) => {


            state.linkedDevices.data.records = state.linkedDevices.data.records.filter((item, index) => item.id !== action.payload.data.id)
            state.linkedDevices.apiMsg.status = action.meta.requestStatus;
            state.linkedDevices.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(linkedDeviceChangeStatus.pending, (state, action) => {

            state.linkedDevices.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(linkedDeviceChangeStatus.rejected, (state, action) => {

            state.linkedDevices.apiMsg.message = action.error.message;
            state.linkedDevices.apiMsg.status = action.meta.requestStatus;
        });


        // linkedDeviceDeleteACTION end

    }
},);

export const { RESET } = restaurantDevicesReducer.actions;
export default restaurantDevicesReducer.reducer;