import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiCall } from "../../API";
import * as Toast from "../../component/Toast";
import moment from "moment";

export const scheduleDayAddUpdateACTION = createAsyncThunk(
  "ScheduleDay/addUpdate",
  async (data) => {
    //console.logog("data2 ", data);
    return apiCall("/scheduledaymaster/insertupdate", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            Toast.success(response.data.message.message);
            return {
              data:{
                ...response.data,
              },
              message:{
                code:response.data.message.code,
                type:response.data.message.type,
                message:response.data.message.message
              },
              
            };
          } else {
            Toast.error(response.data.message.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        Toast.error(error);
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const  getScheduleDayACTION = createAsyncThunk(
  "ScheduleDay/Get",
  async (data) => {
    return apiCall("/scheduledaymaster/get", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
              data: {
                referenceID:response.data.data.referenceID,
                schedules:{
                  allDays:response.data.data.scheduleTime.filter(item => item.fromDate === null).map((item,index)=>{
                    return {
                      ...item,
                      indexID:index,
                      scheduleDays:item.scheduleDays.length > 0 ? item.scheduleDays: [{fromTime: moment().format('HH:mm'),toTime: moment().format('HH:mm')}]
                    }
                  
                  }),
                  days:response.data.data.scheduleTime.filter(item => item.fromDate !== null).map((item,index)=>{
                    return {
                      ...item,
                      indexID:index,
                      scheduleDays:item.scheduleDays.length > 0 ? item.scheduleDays: [{fromTime: moment().format('HH:mm'),toTime: moment().format('HH:mm')}]
                    }
                  
                  })
                }
               
              },
            };
          } else {
            Toast.error(response.data.message.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        Toast.error(error);
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const  scheduleDayGetAllACTION = createAsyncThunk(
  "ScheduleDay/GetAll",
  async (data) => {
    return apiCall("/scheduledaymaster/getall", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            return {
              ...response.data,
              data: {
                ...response.data.data
              },
            };
          } else {
            Toast.error(response.data.message.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        Toast.error(error);
        return Promise.reject({
          ...error,
        });
      });
  }
);
