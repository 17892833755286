import { createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { StaffDeleteACTION, getAllStaffAction, getStaffAction, insertUpdateStaffAction, staffChangeStatusACTION } from "../../actions/settings/staffActions";

const INITIAL_STATE = {

    staff: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    insertUpdateDetails: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },

    selectedStaff: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    activeInactiveDetails: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },

    deleteDetails: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    }
   


};

const staffReducer = createSlice({
    name: "Staff",
    initialState: INITIAL_STATE,
    reducers: {

        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetStaffDetailsState(state, action) {
            state.selectedStaff = INITIAL_STATE.selectedStaff;
            state.insertUpdateDetails = INITIAL_STATE.insertUpdateDetails;
        },
    },



    extraReducers: (builder) => {

        // get all Staff action start

        builder.addCase(getAllStaffAction.fulfilled, (state, action) => {
            state.staff.data = action.payload.data;
            state.staff.apiMsg.status = action.meta.requestStatus;
            state.staff.apiMsg.message = "success";
        });
        builder.addCase(getAllStaffAction.pending, (state, action) => {
            state.staff.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getAllStaffAction.rejected, (state, action) => {
            state.staff.apiMsg.message = action.error.message;
            state.staff.apiMsg.status = action.meta.requestStatus;
        });

        // get all Staff action end

        // insert update Staff Action start

        builder.addCase(
            insertUpdateStaffAction.fulfilled,
            (state, action) => {

                state.insertUpdateDetails.data = action.payload.data.data;
                state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateDetails.apiMsg.message = action.payload.message.message;

                if (!action.payload.newRecord) {
                    let updatedRecord = state.staff.data.records.map(x => {
                        if (x.id == action.payload.data.data.id) {
                            return action.payload.data.data;
                        }
                        else {
                            return x;
                        }
                    })

                    state.staff.data.records = updatedRecord;
                }
                else {
                    if (state.staff.data.records.length) {
                        state.staff.data.records.unshift(action.payload.data.data);
                    }
                    else {
                        state.staff.data.records.push(action.payload.data.data);
                    }
                }
                toast.success(action.payload.message.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });

            }
        );
        builder.addCase(
            insertUpdateStaffAction.pending,
            (state, action) => {
                state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            insertUpdateStaffAction.rejected,
            (state, action) => {

                state.insertUpdateDetails.apiMsg.message = action.error.message;
                state.insertUpdateDetails.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });
            }
        );

        //   insert updateStaff action end

        //------------------------------- getStaff start -------------------------

        builder.addCase(getStaffAction.fulfilled, (state, action) => {
            state.selectedStaff.data = action.payload.data;
            state.selectedStaff.apiMsg.status = action.meta.requestStatus;
            state.selectedStaff.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getStaffAction.pending, (state, action) => {
            state.selectedStaff.data = null;
            state.selectedStaff.apiMsg.message = action.meta.requestStatus;;
            state.selectedStaff.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getStaffAction.rejected, (state, action) => {
            state.selectedStaff.apiMsg.message = action.error.message;
            state.selectedStaff.apiMsg.status = action.meta.requestStatus;
        });


        //   getStaff end



        // StaffChangeStatusACTION start


        builder.addCase(staffChangeStatusACTION.fulfilled, (state, action) => {
            state.staff.data.records = state.staff.data.records.map((item) => {

                return {
                    ...item,
                    active: action.payload.id === item.id ? !item.active : item.active
                }
            })
            state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;
            state.activeInactiveDetails.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(staffChangeStatusACTION.pending, (state, action) => {
            state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(staffChangeStatusACTION.rejected, (state, action) => {
            state.activeInactiveDetails.apiMsg.message = action.error.message;
            state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;
        });


        // StaffChangeStatusACTION end

        // StaffDeleteACTION start


        builder.addCase(StaffDeleteACTION.fulfilled, (state, action) => {

            state.staff.data.records = state.staff.data.records.filter((item, index) => item.id !== action.payload.data.id)

            state.deleteDetails.apiMsg.status = action.meta.requestStatus;
            state.deleteDetails.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(StaffDeleteACTION.pending, (state, action) => {
            state.deleteDetails.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(StaffDeleteACTION.rejected, (state, action) => {
            state.deleteDetails.apiMsg.message = action.error.message;
            state.deleteDetails.apiMsg.status = action.meta.requestStatus;
        });

        // StaffDeleteACTION end
      

    }

});

export const { RESET, ResetStaffDetailsState } = staffReducer.actions;
export default staffReducer.reducer;