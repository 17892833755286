import { createSlice } from "@reduxjs/toolkit";
import {
  optionSetAddUpdateACTION,
  optionSetGetAllACTION,
  optionSetChangeStatusACTION,
  optionSetDeleteACTION,
  getOptionSetACTION
} from "../../actions/optionSetActions";
import { Enums } from '../../../helper/constants/emuns-const';


const INITIAL_STATE = {
  optionSets: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  optionSetDetails: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  selectedOptionSetDetails: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  insertUpdateOptionSetDetails: {
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  }
};

const optionSetReducer = createSlice({
  name: "OptionSet",
  initialState: INITIAL_STATE,
  reducers: {
    RESET(state, action) {
      state = INITIAL_STATE;
    },
    ResetOptionSetDetailsState(state, action) {
      state.optionSetDetails = INITIAL_STATE.optionSetDetails;
      state.insertUpdateOptionSetDetails = INITIAL_STATE.insertUpdateOptionSetDetails;
      state.selectedOptionSetDetails = INITIAL_STATE.selectedOptionSetDetails;
    },
  },
  extraReducers: (builder) => {
    //------------------------------- optionSetAddUpdateACTION start -------------------------
    // eslint-disable-next-line
    builder.addCase(optionSetAddUpdateACTION.fulfilled, (state, action) => {
      // console.log("optionSetAddUpdateACTION", action);

      //state.optionSets.data.records.push(action.payload.data);
      if (!action.payload.newRecord) {
        let updatedRecord = state.optionSets.data.records.map(x => {
          if (x.id === action.meta.arg.id) {
            return action.meta.arg;
          }
          else {
            return x;
          }
        })

        state.optionSets.data.records = updatedRecord;
      }
      else {
        action.meta.arg.id = action.payload.data.data;
        if (state.optionSets.data.records.length) {
          state.optionSets.data.records.unshift(action.meta.arg);
        }
        else {
          state.optionSets.data.records.push(action.meta.arg);
        }
      }

      state.insertUpdateOptionSetDetails.apiMsg.status = action.meta.requestStatus;
      state.insertUpdateOptionSetDetails.apiMsg.message = action.payload.message.message;
    }),
      builder.addCase(optionSetAddUpdateACTION.pending, (state, action) => {
        state.insertUpdateOptionSetDetails.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(optionSetAddUpdateACTION.rejected, (state, action) => {
        state.insertUpdateOptionSetDetails.apiMsg.message = action.error.message;
        state.insertUpdateOptionSetDetails.apiMsg.status = action.meta.requestStatus;
      }),

      //------------------------------- optionSetAddUpdateACTION start -------------------------

      //------------------------------- optionSetGetAllACTION start -------------------------

      builder.addCase(optionSetGetAllACTION.fulfilled, (state, action) => {

        state.optionSets.data = action.payload.data;
        state.optionSets.apiMsg.status = action.meta.requestStatus;
        state.optionSets.apiMsg.message = action.payload.message.message;
      }),
      builder.addCase(optionSetGetAllACTION.pending, (state, action) => {
        //console.log("optionSetGetAllACTION pending", action);
        state.optionSets.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(optionSetGetAllACTION.rejected, (state, action) => {
        //console.log("optionSetGetAllACTION rejected", action);
        state.optionSets.apiMsg.message = action.error.message;
        state.optionSets.apiMsg.status = action.meta.requestStatus;
      }),
      //------------------------------- optionSetGetAllACTION end -------------------------

      //------------------------------- optionSetChangeStatusACTION start -------------------------

      builder.addCase(optionSetChangeStatusACTION.fulfilled, (state, action) => {
        var sortOrderChanged = action.meta.arg.statusType === Enums.ChangeStatus.SortOrder;
        state.optionSets.data.records = state.optionSets.data.records.map((item) => {

          return {
            ...item,
            active: action.payload.id === item.id && !sortOrderChanged ? !item.active : item.active,
            sortOrder: action.payload.id === item.id && sortOrderChanged ? action.meta.arg.value : item.sortOrder
          }
        })
        state.optionSetDetails.apiMsg.status = action.meta.requestStatus;
        state.optionSetDetails.apiMsg.message = action.payload.message.message;
      }),
      builder.addCase(optionSetChangeStatusACTION.pending, (state, action) => {
        //console.log("optionSetChangeStatusACTION pending", action);
        state.optionSetDetails.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(optionSetChangeStatusACTION.rejected, (state, action) => {
        //console.log("optionSetChangeStatusACTION rejected", action);
        state.optionSetDetails.apiMsg.message = action.error.message;
        state.optionSetDetails.apiMsg.status = action.meta.requestStatus;
      }),
      //------------------------------- optionSetChangeStatusACTION end -------------------------

      //------------------------------- optionSetDeleteACTION start -------------------------

      builder.addCase(optionSetDeleteACTION.fulfilled, (state, action) => {
        //console.log("optionSetDeleteACTION", action);

        state.optionSets.data.records = state.optionSets.data.records.filter((item, index) => item.id !== action.payload.data.id)

        state.optionSetDetails.apiMsg.status = action.meta.requestStatus;
        state.optionSetDetails.apiMsg.message = action.payload.message.message;
      }),
      builder.addCase(optionSetDeleteACTION.pending, (state, action) => {
        //console.log("optionSetDeleteACTION pending", action);
        state.optionSetDetails.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(optionSetDeleteACTION.rejected, (state, action) => {
        //console.log("optionSetDeleteACTION rejected", action);
        state.optionSetDetails.apiMsg.message = action.error.message;
        state.optionSetDetails.apiMsg.status = action.meta.requestStatus;
      }),
      //------------------------------- optionSetDeleteACTION end -------------------------

      //------------------------------- getOptionSetACTION start -------------------------

      builder.addCase(getOptionSetACTION.fulfilled, (state, action) => {
        //console.log("getOptionSetACTION", action);
        state.selectedOptionSetDetails.data = action.payload.data;
        state.selectedOptionSetDetails.apiMsg.status = action.meta.requestStatus;
        state.selectedOptionSetDetails.apiMsg.message = action.payload.message.message;
      }),
      builder.addCase(getOptionSetACTION.pending, (state, action) => {
        //console.log("getOptionSetACTION pending", action);
        state.selectedOptionSetDetails.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(getOptionSetACTION.rejected, (state, action) => {
        //console.log("getOptionSetACTION rejected", action);
        state.selectedOptionSetDetails.apiMsg.message = action.error.message;
        state.selectedOptionSetDetails.apiMsg.status = action.meta.requestStatus;
      })

    //------------------------------- getOptionSetACTION end -------------------------

  },
});

export const { RESET, ResetOptionSetDetailsState } = optionSetReducer.actions;
export default optionSetReducer.reducer;
