import { createAction, createReducer, createSlice } from "@reduxjs/toolkit";
import { 
  variantAddUpdateACTION,
  variantGetAllACTION,
  variantChangeStatusACTION,
  variantDeleteACTION,
  getVariantACTION 
} from "../../actions/variantActions";
import {Enums} from '../../../helper/constants/emuns-const';


const INITIAL_STATE = {
  variants: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
 variantDetails: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  insertUpdateVariantUpdate:{
    apiMsg:{
      message: null,
      status: null,
      type: null,
    },
  }
};

const variantReducer = createSlice({
  name: "variant",
  initialState: INITIAL_STATE,
  reducers: {
    RESET(state, action) {
      state = INITIAL_STATE;
    },
    ResetVariantDetailsState(state, action) {
      state.variantDetails = INITIAL_STATE.variantDetails;
      state.insertUpdateVariantUpdate = INITIAL_STATE.insertUpdateVariantUpdate;
    },
  },
  extraReducers: (builder) => {
    //------------------------------- variantAddUpdateACTION start -------------------------
    // eslint-disable-next-line
    builder.addCase(variantAddUpdateACTION.fulfilled, (state, action) => {
      console.log("variantAddUpdateACTION", action);

        //state.variants.data.records.push(action.payload.data);
        if(!action.payload.newRecord){
          let updatedRecord = state.variants.data.records.map(x => {
            if(x.id==action.meta.arg.id){
              return action.payload.data.data;
            }
            else{
              return x;
            }
          })

          state.variants.data.records=updatedRecord;
        }
        else{
          
          if(state.variants.data.records.length){
            state.variants.data.records.unshift(action.payload.data.data);
          }
          else{
            state.variants.data.records.push(action.payload.data.data);
          }
        }

        state.insertUpdateVariantUpdate.apiMsg.status = action.meta.requestStatus;
        state.insertUpdateVariantUpdate.apiMsg.message = action.payload.message.message;
    }),
    builder.addCase(variantAddUpdateACTION.pending, (state, action) => {
      state.insertUpdateVariantUpdate.apiMsg.status = action.meta.requestStatus;
    }),
    builder.addCase(variantAddUpdateACTION.rejected, (state, action) => {
      state.insertUpdateVariantUpdate.apiMsg.message = action.error.message;
      state.insertUpdateVariantUpdate.apiMsg.status = action.meta.requestStatus;
    }),

      //------------------------------- variantAddUpdateACTION start -------------------------

      //------------------------------- variantGetAllACTION start -------------------------

      builder.addCase(variantGetAllACTION.fulfilled, (state, action) => {
        //console.log("variantGetAllACTION", action);

        state.variants.data = action.payload.data;
        state.variants.apiMsg.status = action.meta.requestStatus;
        state.variants.apiMsg.message = action.payload.message.message;
      }),
      builder.addCase(variantGetAllACTION.pending, (state, action) => {
        //console.log("variantGetAllACTION pending", action);
        state.variants.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(variantGetAllACTION.rejected, (state, action) => {
        //console.log("variantGetAllACTION rejected", action);
        state.variants.apiMsg.message = action.error.message;
        state.variants.apiMsg.status = action.meta.requestStatus;
      }),
    //------------------------------- variantGetAllACTION end -------------------------

    //------------------------------- variantChangeStatusACTION start -------------------------

    builder.addCase(variantChangeStatusACTION.fulfilled, (state, action) => {
      
      var sortOrderChanged = action.meta.arg.statusType===Enums.ChangeStatus.SortOrder;
      state.variants.data.records = state.variants.data.records.map((item) => {

          return {
            ...item,
            active: action.payload.id === item.id && !sortOrderChanged ? !item.active : item.active,
            sortOrder: action.payload.id === item.id && sortOrderChanged ? action.meta.arg.value : item.sortOrder
          }
        })
        state.variantDetails.apiMsg.status = action.meta.requestStatus;
        state.variantDetails.apiMsg.message = action.payload.message.message;
      }),
      builder.addCase(variantChangeStatusACTION.pending, (state, action) => {
        //console.log("variantChangeStatusACTION pending", action);
        state.variantDetails.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(variantChangeStatusACTION.rejected, (state, action) => {
        //console.log("variantChangeStatusACTION rejected", action);
        state.variantDetails.apiMsg.message = action.error.message;
        state.variantDetails.apiMsg.status = action.meta.requestStatus;
      }),
    //------------------------------- variantChangeStatusACTION end -------------------------

     //------------------------------- variantDeleteACTION start -------------------------
    
  builder.addCase(variantDeleteACTION.fulfilled, (state, action) => {
  //console.log("variantDeleteACTION", action);

  state.variants.data.records = state.variants.data.records.filter((item,index)=>item.id !== action.payload.data.id)
  
  state.variantDetails.apiMsg.status = action.meta.requestStatus;
  state.variantDetails.apiMsg.message = action.payload.message.message;
  }),
  builder.addCase(variantDeleteACTION.pending, (state, action) => {
    //console.log("variantDeleteACTION pending", action);
    state.variantDetails.apiMsg.status = action.meta.requestStatus;
  }),
  builder.addCase(variantDeleteACTION.rejected, (state, action) => {
    //console.log("variantDeleteACTION rejected", action);
    state.variantDetails.apiMsg.message = action.error.message;
    state.variantDetails.apiMsg.status = action.meta.requestStatus;
  }),
  //------------------------------- variantDeleteACTION end -------------------------

  //------------------------------- getVariantACTION start -------------------------

  builder.addCase(getVariantACTION.fulfilled, (state, action) => {
    //console.log("getVariantACTION", action);
    state.variantDetails.data = action.payload.data;
    state.variantDetails.apiMsg.status = action.meta.requestStatus;
    state.variantDetails.apiMsg.message = action.payload.message.message;
  }),
  builder.addCase(getVariantACTION.pending, (state, action) => {
    //console.log("getVariantACTION pending", action);
    state.variantDetails.apiMsg.status = action.meta.requestStatus;
  }),
  builder.addCase(getVariantACTION.rejected, (state, action) => {
    //console.log("getVariantACTION rejected", action);
    state.variantDetails.apiMsg.message = action.error.message;
    state.variantDetails.apiMsg.status = action.meta.requestStatus;
  })

  //------------------------------- getVariantACTION end -------------------------
    
  },
});

export const { RESET,ResetVariantDetailsState } = variantReducer.actions;
export default variantReducer.reducer;
