import { createAction, createReducer, createSlice } from "@reduxjs/toolkit";
import { 
  variantCategoryAddUpdateACTION,
  variantCategoryGetAllACTION,
  variantCategoryChangeStatusACTION,
  variantCategoryDeleteACTION,
  getVariantCategoryACTION 
} from "../../actions/variantCategoryActions";

const INITIAL_STATE = {
  variantCategories: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
 variantCategoryDetails: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  insertUpdateVariantCategoryUpdate:{
    apiMsg:{
      message: null,
      status: null,
      type: null,
    },
  }
};

const variantCategoryReducer = createSlice({
  name: "VariantCategory",
  initialState: INITIAL_STATE,
  reducers: {
    RESET(state, action) {
      state = INITIAL_STATE;
    },
    ResetVariantCategoryDetailsState(state, action) {
      state.variantCategoryDetails = INITIAL_STATE.variantCategoryDetails;
      state.insertUpdateVariantCategoryUpdate = INITIAL_STATE.insertUpdateVariantCategoryUpdate;
    },
  },
  extraReducers: (builder) => {
    //------------------------------- variantCategoryAddUpdateACTION start -------------------------
    // eslint-disable-next-line
    builder.addCase(variantCategoryAddUpdateACTION.fulfilled, (state, action) => {
      // console.log("variantCategoryAddUpdateACTION", action);

        //state.variantCategories.data.records.push(action.payload.data);
        if(!action.payload.newRecord){
          let updatedRecord = state.variantCategories.data.records.map(x => {
            if(x.id==action.meta.arg.id){
              return action.meta.arg;
            }
            else{
              return x;
            }
          })

          state.variantCategories.data.records=updatedRecord;
        }
        else{
          action.meta.arg.id=action.payload.data.data;
          if(state.variantCategories.data.records.length){
            state.variantCategories.data.records.unshift(action.meta.arg);
          }
          else{
            state.variantCategories.data.records.push(action.meta.arg);
          }
        }

        state.insertUpdateVariantCategoryUpdate.apiMsg.status = action.meta.requestStatus;
        state.insertUpdateVariantCategoryUpdate.apiMsg.message = action.payload.message.message;
    }),
    builder.addCase(variantCategoryAddUpdateACTION.pending, (state, action) => {
      state.insertUpdateVariantCategoryUpdate.apiMsg.status = action.meta.requestStatus;
    }),
    builder.addCase(variantCategoryAddUpdateACTION.rejected, (state, action) => {
      state.insertUpdateVariantCategoryUpdate.apiMsg.message = action.error.message;
      state.insertUpdateVariantCategoryUpdate.apiMsg.status = action.meta.requestStatus;
    }),

      //------------------------------- variantCategoryAddUpdateACTION start -------------------------

      //------------------------------- variantCategoryGetAllACTION start -------------------------

      builder.addCase(variantCategoryGetAllACTION.fulfilled, (state, action) => {
        //console.log("variantCategoryGetAllACTION", action);

        state.variantCategories.data = action.payload.data;
        state.variantCategories.apiMsg.status = action.meta.requestStatus;
        state.variantCategories.apiMsg.message = action.payload.message.message;
      }),
      builder.addCase(variantCategoryGetAllACTION.pending, (state, action) => {
        //console.log("variantCategoryGetAllACTION pending", action);
        state.variantCategories.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(variantCategoryGetAllACTION.rejected, (state, action) => {
        //console.log("variantCategoryGetAllACTION rejected", action);
        state.variantCategories.apiMsg.message = action.error.message;
        state.variantCategories.apiMsg.status = action.meta.requestStatus;
      }),
    //------------------------------- variantCategoryGetAllACTION end -------------------------

    //------------------------------- variantCategoryChangeStatusACTION start -------------------------

    builder.addCase(variantCategoryChangeStatusACTION.fulfilled, (state, action) => {
        //console.log("variantCategoryChangeStatusACTION", action);

        state.variantCategories.data.records = state.variantCategories.data.records.map((item)=>{

          return {
              ...item,
              active:action.payload.id === item.id ? !item.active : item.active 
          }
      })
        state.variantCategoryDetails.apiMsg.status = action.meta.requestStatus;
        state.variantCategoryDetails.apiMsg.message = action.payload.message.message;
      }),
      builder.addCase(variantCategoryChangeStatusACTION.pending, (state, action) => {
        //console.log("variantCategoryChangeStatusACTION pending", action);
        state.variantCategoryDetails.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(variantCategoryChangeStatusACTION.rejected, (state, action) => {
        //console.log("variantCategoryChangeStatusACTION rejected", action);
        state.variantCategoryDetails.apiMsg.message = action.error.message;
        state.variantCategoryDetails.apiMsg.status = action.meta.requestStatus;
      }),
    //------------------------------- variantCategoryChangeStatusACTION end -------------------------

     //------------------------------- variantCategoryDeleteACTION start -------------------------
    
  builder.addCase(variantCategoryDeleteACTION.fulfilled, (state, action) => {
  //console.log("variantCategoryDeleteACTION", action);

  state.variantCategories.data.records = state.variantCategories.data.records.filter((item,index)=>item.id !== action.payload.data.id)
  
  state.variantCategoryDetails.apiMsg.status = action.meta.requestStatus;
  state.variantCategoryDetails.apiMsg.message = action.payload.message.message;
  }),
  builder.addCase(variantCategoryDeleteACTION.pending, (state, action) => {
    //console.log("variantCategoryDeleteACTION pending", action);
    state.variantCategoryDetails.apiMsg.status = action.meta.requestStatus;
  }),
  builder.addCase(variantCategoryDeleteACTION.rejected, (state, action) => {
    //console.log("variantCategoryDeleteACTION rejected", action);
    state.variantCategoryDetails.apiMsg.message = action.error.message;
    state.variantCategoryDetails.apiMsg.status = action.meta.requestStatus;
  }),
  //------------------------------- variantCategoryDeleteACTION end -------------------------

  //------------------------------- getVariantCategoryACTION start -------------------------

  builder.addCase(getVariantCategoryACTION.fulfilled, (state, action) => {
    //console.log("getVariantCategoryACTION", action);
    state.variantCategoryDetails.data = action.payload.data;
    state.variantCategoryDetails.apiMsg.status = action.meta.requestStatus;
    state.variantCategoryDetails.apiMsg.message = action.payload.message.message;
  }),
  builder.addCase(getVariantCategoryACTION.pending, (state, action) => {
    //console.log("getVariantCategoryACTION pending", action);
    state.variantCategoryDetails.apiMsg.status = action.meta.requestStatus;
  }),
  builder.addCase(getVariantCategoryACTION.rejected, (state, action) => {
    //console.log("getVariantCategoryACTION rejected", action);
    state.variantCategoryDetails.apiMsg.message = action.error.message;
    state.variantCategoryDetails.apiMsg.status = action.meta.requestStatus;
  })

  //------------------------------- getVariantCategoryACTION end -------------------------
    
  },
});

export const { RESET,ResetVariantCategoryDetailsState } = variantCategoryReducer.actions;
export default variantCategoryReducer.reducer;
