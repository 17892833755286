import { createAction, createReducer, createSlice } from "@reduxjs/toolkit";
import {
  servicezonGetallACTION,
  getservicezoneACTION,
  addupdateACTION,
  changeStatusACTION,
  deleteACTION,
  getAllwithCoordinatesACTION
} from '../actions/servicezoneActions'
import _ from "lodash";

const INITIAL_STATE = {
  servicezones: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  },
  servicezoneDetail: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      type: null,
    }
  },
  servicezoneWithcoordinates: {
    data: {},
    apiMsg: {
      message: null,
      status: null,
      type: null,
    }
  },
  insertUpdateServicezoneDetail: {
    apiMsg: {
      message: null,
      status: null,
      type: null,
    },
  }


};

const serviceZoneReducer = createSlice({
  name: "Servicezone",
  initialState: INITIAL_STATE,
  reducers: {
    RESETSERVICEZONEDETAIL(state, action) {
      state.servicezoneDetail = INITIAL_STATE.servicezoneDetail
    }
  },
  extraReducers: (builder) => {
    //------------------------------- servicezonGetallACTION start -------------------------
    // eslint-disable-next-line
    builder.addCase(servicezonGetallACTION.fulfilled, (state, action) => {

      state.servicezones.data = action.payload.data;
      state.servicezones.apiMsg.status = action.meta.requestStatus;
      state.servicezones.apiMsg.message = action.payload.message.message;
    }),
      builder.addCase(servicezonGetallACTION.pending, (state, action) => {

        state.servicezones.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(servicezonGetallACTION.rejected, (state, action) => {

        state.servicezones.apiMsg.message = action.error.message;
        state.servicezones.apiMsg.status = action.meta.requestStatus;
      }),
      //------------------------------- servicezonGetallACTION start -------------------------
      //------------------------------- getservicezoneACTION start -------------------------

      builder.addCase(getservicezoneACTION.fulfilled, (state, action) => {

        state.servicezoneDetail.data = action.payload.data;
        state.servicezoneDetail.apiMsg.status = action.meta.requestStatus;
        state.servicezoneDetail.apiMsg.message = action.payload.message.message;
      }),
      builder.addCase(getservicezoneACTION.pending, (state, action) => {

        state.servicezoneDetail.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(getservicezoneACTION.rejected, (state, action) => {

        state.servicezoneDetail.apiMsg.message = action.error.message;
        state.servicezoneDetail.apiMsg.status = action.meta.requestStatus;
      }),
      //------------------------------- getservicezoneACTION start -------------------------
      //------------------------------- addupdateACTION start -------------------------

      builder.addCase(addupdateACTION.fulfilled, (state, action) => {

        if (!action.payload.newRecord) {
          let updatedRecord = state.servicezones.data.records.map(x => {
            if (x.id == action.meta.arg.id) {
              return action.meta.arg;
            }
            else {
              return x;
            }
          })

          state.servicezones.data.records = updatedRecord;
        }
        else {
          action.meta.arg.id = action.payload.data.data;
          if (state.servicezones.data.records.length) {
            state.servicezones.data.records.unshift(action.meta.arg);
          }
          else {
            state.servicezones.data.records.push(action.meta.arg);
          }
        }

        state.insertUpdateServicezoneDetail.apiMsg.status = action.meta.requestStatus;
        state.insertUpdateServicezoneDetail.apiMsg.message =
          action.payload.message.message;
      }),
      builder.addCase(addupdateACTION.pending, (state, action) => {

        state.insertUpdateServicezoneDetail.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(addupdateACTION.rejected, (state, action) => {

        state.insertUpdateServicezoneDetail.apiMsg.message = action.error.message;
        state.insertUpdateServicezoneDetail.apiMsg.status = action.meta.requestStatus;
      }),
      //------------------------------- addupdateACTION end -------------------------
      //------------------------------- changeStatusACTION start -------------------------

      builder.addCase(changeStatusACTION.fulfilled, (state, action) => {
        //console.log("changeStatusACTION", action);

        state.servicezones.data.records = state.servicezones.data.records.map((item) => {

          return {
            ...item,
            active: action.payload.id === item.id ? !item.active : item.active
          }
        });
        state.insertUpdateServicezoneDetail.apiMsg.status = action.meta.requestStatus;
        state.insertUpdateServicezoneDetail.apiMsg.message = action.payload.message.message;
      }),
      builder.addCase(changeStatusACTION.pending, (state, action) => {

        state.insertUpdateServicezoneDetail.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(changeStatusACTION.rejected, (state, action) => {

        state.insertUpdateServicezoneDetail.apiMsg.message = action.error.message;
        state.insertUpdateServicezoneDetail.apiMsg.status = action.meta.requestStatus;
      }),
      //------------------------------- changeStatusACTION end -------------------------
      //------------------------------- deleteACTION start -------------------------

      builder.addCase(deleteACTION.fulfilled, (state, action) => {

        state.servicezones.data.records = state.servicezones.data.records.filter((item, index) => item.id !== action.payload.data.id)

        state.insertUpdateServicezoneDetail.apiMsg.status = action.meta.requestStatus;
        state.insertUpdateServicezoneDetail.apiMsg.message =
          action.payload.message.message;
      }),
      builder.addCase(deleteACTION.pending, (state, action) => {
        //console.log("deleteACTION pending", action);
        state.insertUpdateServicezoneDetail.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(deleteACTION.rejected, (state, action) => {
        //console.log("deleteACTION rejected", action);
        state.insertUpdateServicezoneDetail.apiMsg.message = action.error.message;
        state.insertUpdateServicezoneDetail.apiMsg.status = action.meta.requestStatus;
      }),
      //------------------------------- deleteACTION end -------------------------
      //------------------------------- getAllwithCoordinatesACTION start -------------------------

      builder.addCase(getAllwithCoordinatesACTION.fulfilled, (state, action) => {

        state.servicezones.data = action.payload.data;
        state.servicezones.apiMsg.status = action.meta.requestStatus;
        state.servicezones.apiMsg.message = action.payload.message.message;
      }),
      builder.addCase(getAllwithCoordinatesACTION.pending, (state, action) => {

        state.servicezones.apiMsg.status = action.meta.requestStatus;
      }),
      builder.addCase(getAllwithCoordinatesACTION.rejected, (state, action) => {

        state.servicezones.apiMsg.message = action.error.message;
        state.servicezones.apiMsg.status = action.meta.requestStatus;
      })
    //------------------------------- getAllwithCoordinatesACTION start -------------------------

  }
});

export const { RESETSERVICEZONEDETAIL } = serviceZoneReducer.actions;
export default serviceZoneReducer.reducer;
