import { createAction, createReducer, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { getStaffClockAction, updateStaffClockAction, getAllStaffClockInOutReportACTION, staffClockDeleteACTION } from "../../actions/staffCLockActions/staffClock.Actions";
import { toast } from 'react-toastify';

const INITIAL_STATE = {

    StaffClockInOutReport: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    selectedStaffClock: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    updateStaffCLock: {
        data: {},

        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },

    deleteStaffCLock:{
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    }
    
};

const staffClockReducer = createSlice({
    name: "StaffClock",
    initialState: INITIAL_STATE,
    reducers: {

        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetStaffClockeDetailsState(state, action) {
            state.updateStaffCLock = INITIAL_STATE.updateStaffCLock;
            state.selectedStaffClock = INITIAL_STATE.selectedStaffClock;
        },
    },

    extraReducers: (builder) => {

        //------------------------------- getAllStaffClockInOutReportACTION start -------------------------
        // eslint-disable-next-line
        builder.addCase(getAllStaffClockInOutReportACTION.fulfilled, (state, action) => {

            state.StaffClockInOutReport.data = action.payload.data;
            state.StaffClockInOutReport.apiMsg.status = action.meta.requestStatus;
            state.StaffClockInOutReport.apiMsg.message = action.payload.message.message;
        }),
            builder.addCase(getAllStaffClockInOutReportACTION.pending, (state, action) => {
                //console.log("getAllStaffClockInOutReportACTION pending", action);
                state.StaffClockInOutReport.apiMsg.status = action.meta.requestStatus;
            }),
            builder.addCase(getAllStaffClockInOutReportACTION.rejected, (state, action) => {
                //console.log("getAllStaffClockInOutReportACTION rejected", action);
                state.StaffClockInOutReport.apiMsg.message = action.error.message;
                state.StaffClockInOutReport.apiMsg.status = action.meta.requestStatus;
            })
        //------------------------------- getAllStaffClockInOutReportACTION end -------------------------


        builder.addCase(getStaffClockAction.fulfilled, (state, action) => {
            state.selectedStaffClock.data = action.payload.data;
            state.selectedStaffClock.apiMsg.status = action.meta.requestStatus;
            state.selectedStaffClock.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(getStaffClockAction.pending, (state, action) => {
            state.selectedStaffClock.data = null;
            state.selectedStaffClock.apiMsg.message = action.meta.requestStatus;;
            state.selectedStaffClock.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getStaffClockAction.rejected, (state, action) => {
            state.selectedStaffClock.apiMsg.message = action.error.message;
            state.selectedStaffClock.apiMsg.status = action.meta.requestStatus;
        });


        builder.addCase(updateStaffClockAction.fulfilled,
            (state, action) => {

                state.updateStaffCLock.data = action.payload.data.data;
                state.updateStaffCLock.apiMsg.status = action.meta.requestStatus;
                state.updateStaffCLock.apiMsg.message = action.payload.message.message;

                // if (!action.payload.newRecord) {
                let updatedRecord = state.StaffClockInOutReport.data.records.map(x => {
                    if (x.id == action.payload.data.data.id) {
                        return action.payload.data.data;
                    }
                    else {
                        return x;
                    }
                })

                state.StaffClockInOutReport.data.records = updatedRecord;
                // }
                // else {
                //     if (state.device.data.records.length) {
                //         state.device.data.records.unshift(action.payload.data.data);
                //     }
                //     else {
                //         state.device.data.records.push(action.payload.data.data);
                //     }
                // }
                toast.success(action.payload.message.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });

            }
        );
        builder.addCase(
            updateStaffClockAction.pending,
            (state, action) => {
                state.updateStaffCLock.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            updateStaffClockAction.rejected,
            (state, action) => {

                state.updateStaffCLock.apiMsg.message = action.error.message;
                state.updateStaffCLock.apiMsg.status = action.meta.requestStatus;

                toast.error(action.error.message ? action.error.message : "Something went wrong please try again", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    toastId: "dept-error"
                });
            }
        );


        
        //------------------------------- staffClockDeleteACTION start -------------------------

        builder.addCase(staffClockDeleteACTION.fulfilled, (state, action) => {

            // state.StaffClockInOutReport.data.records = state.StaffClockInOutReport.data.records.filter((item, index) => item.id !== action.meta.arg.id)
            // console.log(action.meta.arg , "argssssssssss");
            // state.deleteStaffCLock.apiMsg.status = action.meta.requestStatus;
            // state.deleteStaffCLock.apiMsg.message = action.payload.message.message;

            // console.log("argssssssssss", action.meta.arg);
            if(action.meta.arg.parentId){
                const allStaffRecords = state.StaffClockInOutReport.data.records;
                const staffDetailsIndex = allStaffRecords.findIndex((staff) => staff.id === action.meta.arg.parentId);
            
                if(staffDetailsIndex !== -1){
                    const staffDetails = allStaffRecords[staffDetailsIndex];
                    if(staffDetails.staffLogDtails && staffDetails.staffLogDtails.length){
                        staffDetails.staffLogDtails = staffDetails.staffLogDtails.filter((child) => child.id !== action.meta.arg.id);
                    }
                }
            }
            else{
                state.StaffClockInOutReport.data.records = state.StaffClockInOutReport.data.records.filter((item, index) => item.id !== action.meta.arg.id);
                // console.log(action.meta.arg , "argssssssssss");
            }
            

            state.deleteStaffCLock.apiMsg.status = action.meta.requestStatus;
                state.deleteStaffCLock.apiMsg.message = action.payload.message.message;
                
        });

      
          
        builder.addCase(staffClockDeleteACTION.pending, (state, action) => {
            state.deleteStaffCLock.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(staffClockDeleteACTION.rejected, (state, action) => {
            state.deleteStaffCLock.apiMsg.message = action.error.message;
            state.deleteStaffCLock.apiMsg.status = action.meta.requestStatus;
        });
        //------------------------------- staffClockDeleteACTION end -------------------------

    }
})

export const { RESET, ResetStaffClockeDetailsState } = staffClockReducer.actions;
export default staffClockReducer.reducer;