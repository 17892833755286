import { createAction, createReducer, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import {
    getallCustomersACTION,
    getCustomerACTION,
    customersAddUpdateACTIONACTION,
    changeStatusACTION,
    changeBlockStatusACTION,
    customersDeleteACTION,
    customerChangePasswordAction
} from '../actions/customers.Actions'

const INITIAL_STATE = {
    customers: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    customerDetails: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    customerStatusUpdate: {
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    insertUpdateCustomerDetails: {
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    changePasswordDetails: {
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    }

};

const customersReducre = createSlice({
    name: "Customers",
    initialState: INITIAL_STATE,
    reducers: {

    },
    extraReducers: (builder) => {
        //------------------------------- customersAddUpdateACTIONACTION start -------------------------
        // eslint-disable-next-line
        builder.addCase(customersAddUpdateACTIONACTION.fulfilled, (state, action) => {
            // console.log("customersAddUpdateACTIONACTION", action);

            if (!action.payload.newRecord) {
                let updatedRecord = state.customers.data.records.map(x => {
                    if (x.id === action.meta.arg.id) {
                        return action.meta.arg;
                    }
                    else {
                        return x;
                    }
                })

                state.customers.data.records = updatedRecord;
            }
            else {
                action.meta.arg.id = action.payload.data.data;
                if (state.customers.data.records.length) {
                    state.customers.data.records.unshift(action.meta.arg);
                }
                else {
                    state.customers.data.records.push(action.meta.arg);
                }
            }

            state.insertUpdateCustomerDetails.apiMsg.status = action.meta.requestStatus;
            state.insertUpdateCustomerDetails.apiMsg.message = action.payload.message.message;
        }),
            builder.addCase(customersAddUpdateACTIONACTION.pending, (state, action) => {
                state.insertUpdateCustomerDetails.apiMsg.status = action.meta.requestStatus;
            }),
            builder.addCase(customersAddUpdateACTIONACTION.rejected, (state, action) => {
                state.insertUpdateCustomerDetails.apiMsg.message = action.error.message;
                state.insertUpdateCustomerDetails.apiMsg.status = action.meta.requestStatus;
            }),

            //------------------------------- customersAddUpdateACTIONACTION start -------------------------

            //------------------------------- getallCustomersACTION start -------------------------

            builder.addCase(getallCustomersACTION.fulfilled, (state, action) => {

                state.customers.data = action.payload.data;
                state.customers.apiMsg.status = action.meta.requestStatus;
                state.customers.apiMsg.message = action.payload.message.message;
            }),
            builder.addCase(getallCustomersACTION.pending, (state, action) => {
                //console.log("getallCustomersACTION pending", action);
                state.customers.apiMsg.status = action.meta.requestStatus;
            }),
            builder.addCase(getallCustomersACTION.rejected, (state, action) => {
                //console.log("getallCustomersACTION rejected", action);
                state.customers.apiMsg.message = action.error.message;
                state.customers.apiMsg.status = action.meta.requestStatus;
            }),
            //------------------------------- getallCustomersACTION end -------------------------

            //------------------------------- changeStatusACTION start -------------------------

            builder.addCase(changeStatusACTION.fulfilled, (state, action) => {
                //console.log("changeStatusACTION", action);

                state.customers.data.records = state.customers.data.records.map((item) => {

                    return {
                        ...item,
                        active: action.payload.id === item.id ? !item.active : item.active
                    }
                })
                state.customerStatusUpdate.apiMsg.status = action.meta.requestStatus;
                state.customerStatusUpdate.apiMsg.message = action.payload.message.message;
            }),
            builder.addCase(changeStatusACTION.pending, (state, action) => {
                //console.log("changeStatusACTION pending", action);
                state.customerStatusUpdate.apiMsg.status = action.meta.requestStatus;
            }),
            builder.addCase(changeStatusACTION.rejected, (state, action) => {
                //console.log("changeStatusACTION rejected", action);
                state.customerStatusUpdate.apiMsg.message = action.error.message;
                state.customerStatusUpdate.apiMsg.status = action.meta.requestStatus;
            }),
            //------------------------------- changeBlockStatusACTION end -------------------------
            builder.addCase(changeBlockStatusACTION.fulfilled, (state, action) => {
                //console.log("changeBlockStatusACTION", action);

                state.customers.data.records = state.customers.data.records.map((item) => {

                    return {
                        ...item,
                        blocked: action.payload.id === item.id ? !item.blocked : item.blocked
                    }
                })
                state.customerStatusUpdate.apiMsg.status = action.meta.requestStatus;
                state.customerStatusUpdate.apiMsg.message = action.payload.message.message;
            }),
            builder.addCase(changeBlockStatusACTION.pending, (state, action) => {
                //console.log("changeBlockStatusACTION pending", action);
                state.customerStatusUpdate.apiMsg.status = action.meta.requestStatus;
            }),
            builder.addCase(changeBlockStatusACTION.rejected, (state, action) => {
                //console.log("changeBlockStatusACTION rejected", action);
                state.customerStatusUpdate.apiMsg.message = action.error.message;
                state.customerStatusUpdate.apiMsg.status = action.meta.requestStatus;
            }),
            //------------------------------- changeBlockStatusACTION end -------------------------

            //------------------------------- customersDeleteACTION start -------------------------

            builder.addCase(customersDeleteACTION.fulfilled, (state, action) => {
                //console.log("customersDeleteACTION", action);

                state.customers.data.records = state.customers.data.records.filter((item, index) => item.id !== action.payload.data.id)

                state.customerStatusUpdate.apiMsg.status = action.meta.requestStatus;
                state.customerStatusUpdate.apiMsg.message = action.payload.message.message;
            }),
            builder.addCase(customersDeleteACTION.pending, (state, action) => {
                //console.log("customersDeleteACTION pending", action);
                state.customerStatusUpdate.apiMsg.status = action.meta.requestStatus;
            }),
            builder.addCase(customersDeleteACTION.rejected, (state, action) => {
                //console.log("customersDeleteACTION rejected", action);
                state.customerStatusUpdate.apiMsg.message = action.error.message;
                state.customerStatusUpdate.apiMsg.status = action.meta.requestStatus;
            }),
            //------------------------------- customersDeleteACTION end -------------------------

            //------------------------------- getCustomerACTION start -------------------------

            builder.addCase(getCustomerACTION.fulfilled, (state, action) => {
                //console.log("getCustomerACTION", action);
                state.customerDetails.data = action.payload.data;
                state.customerDetails.apiMsg.status = action.meta.requestStatus;
                state.customerDetails.apiMsg.message = action.payload.message.message;
            }),
            builder.addCase(getCustomerACTION.pending, (state, action) => {
                //console.log("getCustomerACTION pending", action);
                state.customerDetails.apiMsg.status = action.meta.requestStatus;
            }),
            builder.addCase(getCustomerACTION.rejected, (state, action) => {
                //console.log("getCustomerACTION rejected", action);
                state.customerDetails.apiMsg.message = action.error.message;
                state.customerDetails.apiMsg.status = action.meta.requestStatus;
            }),

        //------------------------------- getAdvertisementACTION end -------------------------


        //------------------------------- customerChangePassword start -------------------------


        builder.addCase(customerChangePasswordAction.fulfilled, (state, action) => {
            state.changePasswordDetails.apiMsg.status = action.meta.requestStatus;
            state.changePasswordDetails.apiMsg.message = action.payload.message.message;
        }),
            builder.addCase(customerChangePasswordAction.pending, (state, action) => {
                state.changePasswordDetails.apiMsg.status = action.meta.requestStatus;
            }),
            builder.addCase(customerChangePasswordAction.rejected, (state, action) => {
                state.changePasswordDetails.apiMsg.message = action.error.message;
                state.changePasswordDetails.apiMsg.status = action.meta.requestStatus;
            })

        //------------------------------- customerChangePassword end -------------------------


    }
});

export const { } = customersReducre.actions;
export default customersReducre.reducer;
