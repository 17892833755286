import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiCall } from "../../../API";
import * as Toast from "../../../component/Toast"
import { Enums } from '../../../helper/constants/emuns-const';

export const productMasterGetAllACTION = createAsyncThunk(
  "MenuItem/productMasterGetAll",
  async (data) => {
    let d = {
      device: 1,
      pageNo: -1,
      pageSize: 0,
      sortBy: 0,
      sortOrder: 0,
      filter: {

        ...data.filter
      },
      restaurantID: data.restaurantID,
    };

    return apiCall("/productmaster/getall", "POST", d, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            //console.log("response.data ", response.data);

            return {
              ...response.data,
              data: {
                ...response.data.data,

              },
            };
          } else {
            //console.log("response error", response.data.message);
            Toast.error(response.data.message.message)
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        Toast.error(error)
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const changeStatusACTION = createAsyncThunk(
  "MenuItem/changeStatus",
  async (data) => {
    return apiCall("/productmaster/changestatus", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            if (data.statusType !== Enums.ChangeStatus.SortOrder) {
              Toast.success(response.data.message.message)
            }
            return {
              ...response.data,
              id: data.id,
            };
          } else {
            //console.log("response error", response.data.message);
            Toast.error(response.data.message.message)
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        Toast.error(error)
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const addUpdateACTION = createAsyncThunk(
  "MenuItem/addUpdate",
  async (data) => {
    //console.log("data2 ", data);
    return apiCall("/productmaster/insertupdate", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            //console.log("response.data ", response.data);
            Toast.success(response.data.message.message)
            return {
              data: {
                ...response.data,
              },
              message: {
                code: response.data.message.code,
                type: response.data.message.type,
                message: response.data.message.message
              },
              newRecord: data.id ? false : true
            };
          } else {
            //console.log("response error", response.data.message);
            Toast.error(response.data.message.message)
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        Toast.error(error)
        return Promise.reject({
          ...error,
        });
      });
  }
);
export const addUpdatePricingACTION = createAsyncThunk(
  "MenuItem/addUpdatePricing",
  async (data) => {
    console.log("data2 ", data);
    return apiCall("/productmaster/menupriceupdate", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            //console.log("response.data ", response.data);
            Toast.success(response.data.message.message)
            return {
              data: {
                ...response.data,
              },
              message: {
                code: response.data.message.code,
                type: response.data.message.type,
                message: response.data.message.message
              },

            };
          } else {
            //console.log("response error", response.data.message);
            Toast.error(response.data.message.message)
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        Toast.error(error)
        return Promise.reject({
          ...error,
        });
      });
  }
);
export const deleteACTION = createAsyncThunk(
  "MenuItem/delete",
  async (data) => {

    return apiCall("/productmaster/changeStatus", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {

            Toast.success(response.data.message.message);
            return {
              ...response.data,
              data: {
                ...data,
              },
            };
          } else {
            //console.log("response error", response.data.message);
            Toast.error(response.data.message.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        Toast.error(error);
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const getProductMasterACTION = createAsyncThunk(
  "MenuItem/getProductMaster",
  async (data) => {
    return apiCall("/productmaster/get", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            //console.log("response.data ", response.data);

            return {
              ...response.data,

            };
          } else {
            //console.log("response error", response.data.message);
            Toast.error(response.data.message.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        Toast.error(error);
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const getProductPriceMasterACTION = createAsyncThunk(
  "MenuItem/getProductPriceMaster",
  async (data) => {
    return apiCall("/productmaster/get", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            //console.log("response.data ", response.data);

            return {
              ...response.data,

            };
          } else {
            //console.log("response error", response.data.message);
            Toast.error(response.data.message.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        Toast.error(error);
        return Promise.reject({
          ...error,
        });
      });
  }
);


export const getAddonVariantPriceACTION = createAsyncThunk(
  "MenuItem/getAddonVariantPrice",
  async (data) => {
    return apiCall("/productmaster/getaddonvariantprice", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {

            return {
              ...response.data,

            };
          } else {
            //console.log("response error", response.data.message);
            Toast.error(response.data.message.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        Toast.error(error);
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const addonVariantPriceUpdateACTION = createAsyncThunk(
  "MenuItem/addonVariantPriceUpdate",
  async (data) => {

    return apiCall("/productmaster/addonvariantpriceinsertupdate", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            //console.log("response.data ", response.data);
            Toast.success(response.data.message.message)
            return {
              data: {
                ...response.data,
              },
              message: {
                code: response.data.message.code,
                type: response.data.message.type,
                message: response.data.message.message
              },

            };
          } else {
            //console.log("response error", response.data.message);
            Toast.error(response.data.message.message)
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        Toast.error(error);
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const assignMenuTypeUpdateACTION = createAsyncThunk(
  "MenuItem/assignMenuTypeUpdate",
  async (data) => {

    return apiCall("/productmaster/assignmenutype", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {
            //console.log("response.data ", response.data);
            Toast.success(response.data.message.message)
            return {
              data: {
                ...response.data,
              },
              message: {
                code: response.data.message.code,
                type: response.data.message.type,
                message: response.data.message.message
              },

            };
          } else {
            //console.log("response error", response.data.message);
            Toast.error(response.data.message.message)
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        Toast.error(error);
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const getKitchenPrintersACTION = createAsyncThunk(
  "MenuItem/getKitchenPrinters",
  async (data) => {
    return apiCall("/productmaster/getkitchenprinters", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {

            return {
              ...response.data,

            };
          } else {
            //console.log("response error", response.data.message);
            Toast.error(response.data.message.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        Toast.error(error);
        return Promise.reject({
          ...error,
        });
      });
  }
);
export const kitchenPrintersUpdateACTION = createAsyncThunk(
  "MenuItem/kitchenPrintersUpdate",
  async (data) => {

    return apiCall("/productmaster/kitchenprintersupdate", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {

            Toast.success(response.data.message.message)
            return {
              data: {
                ...response.data,
              },
              message: {
                code: response.data.message.code,
                type: response.data.message.type,
                message: response.data.message.message
              },

            };
          } else {
            //console.log("response error", response.data.message);
            Toast.error(response.data.message.message)
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        Toast.error(error);
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const getAllComboTtemsACTION = createAsyncThunk(
  "MenuItem/getAllComboTtems",
  async (data) => {
    return apiCall("/productmaster/getallcomboitems", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {

            return {
              ...response.data,

            };
          } else {
            //console.log("response error", response.data.message);
            Toast.error(response.data.message.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        Toast.error(error);
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const getComboTtemsACTION = createAsyncThunk(
  "MenuItem/getComboTtems",
  async (data) => {
    return apiCall("/productmaster/getcomboitems", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {

            return {
              ...response.data,

            };
          } else {
            //console.log("response error", response.data.message);
            Toast.error(response.data.message.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        Toast.error(error);
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const comboItemUpdateACTION = createAsyncThunk(
  "MenuItem/comboItemUpdate",
  async (data) => {

    return apiCall("/productmaster/comboiteminsertupdate", "POST", data, false)
      .then((response) => {
        console.log('response ', data)

        if (response.status === 200) {
          if (!response.data.hasError) {

            Toast.success(response.data.message.message)
            return {
              data: {
                ...response.data,
              },

            };
          } else {
            //console.log("response error", response.data.message);
            Toast.error(response.data.message.message)
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        Toast.error(error);
        return Promise.reject({
          ...error,
        });
      });
  }
);

export const getproductpriceAction = createAsyncThunk(
  "MenuItem/getproductpriceAction",
  async (data) => {

    return apiCall("/productmaster/getproductprice", "POST", data, false)
      .then((response) => {

        if (response.status === 200) {
          if (!response.data.hasError) {


            return {
              data: {
                ...response.data,
              },

            };
          } else {
            //console.log("response error", response.data.message);
            Toast.error(response.data.message.message)
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        Toast.error(error);
        return Promise.reject({
          ...error,
        });
      });
  }
);
export const productpriceupdateAction = createAsyncThunk(
  "MenuItem/productpriceupdate",
  async (data) => {

    return apiCall("/productmaster/productpriceupdate", "POST", data, false)
      .then((response) => {

        if (response.status === 200) {
          if (!response.data.hasError) {
            Toast.success(response.data.message.message)

            return {
              data: {
                ...response.data,
              },

            };
          } else {
            //console.log("response error", response.data.message);
            Toast.error(response.data.message.message)
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        Toast.error(error);
        return Promise.reject({
          ...error,
        });
      });
  }
);
export const getAllPromotionProductAction = createAsyncThunk(
  "MenuItem/GetllPromotionProduct",
  async (data) => {
    return apiCall("/productmaster/getallpromotionproduct", "POST", data, false)
      .then((response) => {
        if (response.status === 200) {
          if (!response.data.hasError) {

            return {
              ...response.data,

            };
          } else {
            //console.log("response error", response.data.message);
            Toast.error(response.data.message.message);
            return Promise.reject({
              ...response.data.message,
            });
          }
        }
      })
      .catch((error) => {
        Toast.error(error);
        return Promise.reject({
          ...error,
        });
      });
  }
);