import { createAction, createReducer, createSlice } from "@reduxjs/toolkit";
import { tagMasterGetAllACTION, tagMasterAddUpdateACTION, getTagMasterACTION, tagMasterChangeStatusACTION, tagMasterDeleteACTION } from "../../actions/tagMasterActions";

const INITIAL_STATE = {
    tagMaster: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    insertUpdateTagMaster: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },

    selectedTagMasterDetails: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    activeInactiveDetails: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    },
    deleteDetails: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
        newRecord: false
    }
};


const tagMasterReducer = createSlice({
    name: "tagMaster",
    initialState: INITIAL_STATE,
    reducers: {
        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResettagMasterDetailsState(state, action) {
            state.selectedTagMasterDetails = INITIAL_STATE.selectedTagMasterDetails;
            state.insertUpdateTagMaster = INITIAL_STATE.insertUpdateTagMaster;
        },
    },


    extraReducers: (builder) => {

        // tagMasterGetAllACTION start

        builder.addCase(tagMasterGetAllACTION.fulfilled, (state, action) => {
            state.tagMaster.data = action.payload.data;
            state.tagMaster.apiMsg.status = action.meta.requestStatus;
            state.tagMaster.apiMsg.message = "success";
        });
        builder.addCase(tagMasterGetAllACTION.pending, (state, action) => {
            // console.log("getTillAction pending", action);
            state.tagMaster.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(tagMasterGetAllACTION.rejected, (state, action) => {
            // console.log("getTillAction rejected", action);
            state.tagMaster.apiMsg.message = action.error.message;
            state.tagMaster.apiMsg.status = action.meta.requestStatus;
        });


        // tagMasterGetAllACTION end

        // insert update tagMaster Action start

        builder.addCase(
            tagMasterAddUpdateACTION.fulfilled,
            (state, action) => {
                // console.log("insertUpdateTillAction", action);
                if (!action.payload.newRecord) {
                    let updatedRecord = state.tagMaster.data.records.map(x => {
                        if (x.id == action.meta.arg.id) {
                            return action.payload.data.data;
                        }
                        else {
                            return x;
                        }
                    })

                    state.tagMaster.data.records = updatedRecord;
                }
                else {
                    if (state.tagMaster.data.records.length) {
                        state.tagMaster.data.records.unshift(action.payload.data.data);
                    }
                    else {
                        state.tagMaster.data.records.push(action.payload.data.data);
                    }
                }
                state.insertUpdateTagMaster.apiMsg.status = action.meta.requestStatus;
                state.insertUpdateTagMaster.apiMsg.message = action.payload.message.message;
            }
        );
        builder.addCase(
            tagMasterAddUpdateACTION.pending,
            (state, action) => {
                //console.log("insertUpdateVatAction pending", action);
                state.insertUpdateTagMaster.apiMsg.status = action.meta.requestStatus;
            }
        );
        builder.addCase(
            tagMasterAddUpdateACTION.rejected,
            (state, action) => {
                state.insertUpdateTagMaster.apiMsg.message = action.error.message;
                state.insertUpdateTagMaster.apiMsg.status = action.meta.requestStatus;
            }
        );


        //   insert update tagMaster action end


        // get TagMasterACTION  start


        builder.addCase(getTagMasterACTION.fulfilled, (state, action) => {
            //console.log("getTagMasterACTION", action);
            state.selectedTagMasterDetails.data = action.payload.data;
            state.selectedTagMasterDetails.apiMsg.status = action.meta.requestStatus;
            state.selectedTagMasterDetails.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(getTagMasterACTION.pending, (state, action) => {
            //console.log("getTagMasterACTION pending", action);
            state.selectedTagMasterDetails.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getTagMasterACTION.rejected, (state, action) => {
            //console.log("getTagMasterACTION rejected", action);
            state.selectedTagMasterDetails.apiMsg.message = action.error.message;
            state.selectedTagMasterDetails.apiMsg.status = action.meta.requestStatus;
        });
        // get TagMasterACTION  end



        // tagMasterChangeStatusACTION start


        builder.addCase(tagMasterChangeStatusACTION.fulfilled, (state, action) => {
            state.tagMaster.data.records = state.tagMaster.data.records.map((item) => {

                return {
                    ...item,
                    active: action.payload.id === item.id ? !item.active : item.active
                }
            })
            state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;
            state.activeInactiveDetails.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(tagMasterChangeStatusACTION.pending, (state, action) => {
            //console.log("tagMasterChangeStatusACTION pending", action);
            state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(tagMasterChangeStatusACTION.rejected, (state, action) => {
            //console.log("tagMasterChangeStatusACTION rejected", action);
            state.activeInactiveDetails.apiMsg.message = action.error.message;
            state.activeInactiveDetails.apiMsg.status = action.meta.requestStatus;
        });


        // tagMasterChangeStatusACTION end



        //------------------------------- tagMasterDeleteACTION start -------------------------

        builder.addCase(tagMasterDeleteACTION.fulfilled, (state, action) => {
            //console.log("tagMasterDeleteACTION", action);

            state.tagMaster.data.records = state.tagMaster.data.records.filter((item, index) => item.id !== action.payload.data.id)

            state.deleteDetails.apiMsg.status = action.meta.requestStatus;
            state.deleteDetails.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(tagMasterDeleteACTION.pending, (state, action) => {
            //console.log("tagMasterDeleteACTION pending", action);
            state.deleteDetails.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(tagMasterDeleteACTION.rejected, (state, action) => {
            //console.log("tagMasterDeleteACTION rejected", action);
            state.deleteDetails.apiMsg.message = action.error.message;
            state.deleteDetails.apiMsg.status = action.meta.requestStatus;
        });
        //------------------------------- tagMasterDeleteACTION end -------------------------

    },
});

export const { RESET, ResettagMasterDetailsState } = tagMasterReducer.actions;
export default tagMasterReducer.reducer;