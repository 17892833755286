import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiCall } from "../../../API";
import * as Toast from "../../../component/Toast";

export const getQrCodeAction = createAsyncThunk(
    "QrCode/getqrcode",
    async (data) => {
        

        return apiCall("/restaurant/getqrcode", "POST", data, false)
            .then((response) => {
                if (response.status === 200) {
                    if (!response.data.hasError) {
                        return {
                            ...response.data,
                            data: {
                                ...response.data.data
                            },
                        };
                    } else {
                        Toast.error(response.data.message.message);
                        return Promise.reject({
                            ...response.data.message,
                        });
                    }
                }
            })
            .catch((error) => {
                Toast.error(error);
                return Promise.reject({
                    ...error,
                });
            });
    }
);


export const getAllLinkedDevices = createAsyncThunk(
    "LinkedDevices/getlinkeddevices",
    async (data) => {
        let d = {
            device: 1,
            pageNo: -1,
            pageSize: 0,
            sortBy: 0,
            sortOrder: 0,
            restaurantID: data.filter.restaurantID,
          };
      
        return apiCall("/restaurant/linkeddevice/getall","POST", d, false).then((response)=>{
            if(response.status === 200){
                if (!response.data.hasError) {
                    return {
                        ...response.data,
                        data: {
                            ...response.data.data
                        },
                    };
                } else {
                    Toast.error(response.data.message.message);
                    return Promise.reject({
                        ...response.data.message,
                    });
                }
            }
        })
    }
);


export const  linkedDeviceChangeStatus = createAsyncThunk(
    "LinkedDevices/delete",
    async (data) => {
      console.log(data)
      return apiCall("/restaurant/linkeddevice/changestatus", "POST", data, false)
        .then((response) => {
          if (response.status === 200) {
            if (!response.data.hasError) {
              Toast.success(response.data.message.message);
              return {
                ...response.data,
                data: {
                  ...data,
                },
              };
            } else {
              Toast.error(response.data.message.message);
              return Promise.reject({
                ...response.data.message,
              });
            }
          }
        })
        .catch((error) => {
          Toast.error(error);
          return Promise.reject({
            ...error,
          });
        });
    }
  );