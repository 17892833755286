/* eslint-disable import/first */
import React from 'react';
import { useLocation, useSearchParams } from 'react-router-dom'
import * as Toast from '../component/Toast'
const signalR = require("@microsoft/signalr");
import { useSelector, useDispatch } from 'react-redux';
import { EnumskeyValue } from '../helper/constants/emuns-const';
import { RESET_ORDERS } from '../redux-store/reducers/orders.Reducer'
import { rest } from 'lodash';
const APIURL = process.env.REACT_APP_BACKEND_URL

var publicObject = {};
var audio = null;
export var connection = null;
function newOrderReceivedEvent(restaurantID, item) {
    if (item) {

        if (item.resId == restaurantID) {

            if (item.status.toUpperCase() == EnumskeyValue.orderStatusOne[1].key.toUpperCase()
                || item.status.toUpperCase() == EnumskeyValue.orderStatusOne[0].key.toUpperCase()
                || item.status.toUpperCase() == EnumskeyValue.orderStatusOne[2].key.toUpperCase()
                || item.status.toUpperCase() == EnumskeyValue.orderStatusOne[3].key.toUpperCase()) {
                Toast.orderToast('New order #' + item.orderNumber + ' ' + item.status, 'New Order')
            } else {
                Toast.orderToast('New order #' + item.orderNumber + ' ' + item.status, 'New Order')
            }

            // if (typeof (RMSUserAdmin.Order) != "undefined") {
            //   RMSUserAdmin.Order.RefreshOrders();
            // }
            // else if (typeof (RMSUserAdmin.TodayOrder) != "undefined") {
            //   RMSUserAdmin.TodayOrder.RefreshOrders();
            // }

            audio.currentTime = 0;
            audio.play();
            return item;
        }
    }

}

function orderStatusChangedEvent(restaurantID, item) {
    if (item) {
        var refId = restaurantID;

        if (refId == item.resId) {

            // if (typeof (RMSUserAdmin.Order) != "undefined") {
            //   RMSUserAdmin.Order.RefreshOrders();
            // }
            // else if (typeof (RMSUserAdmin.TodayOrder) != "undefined") {
            //   RMSUserAdmin.TodayOrder.RefreshOrders();
            // }
            return item;
        }
    }

}

export function signalRInitiated(restaurantID) {

    publicObject.Init = () => {
        // console.log('Initialized++>')
        audio = new Audio('/ordersound.mp3');

        // if (GetBrowserName() != "safari") {
        //   navigator.mediaDevices.getUserMedia({
        //     video: false,
        //     audio: true
        //   }).then(stream => {
        //     window.localStream = stream; // A
        //     window.localAudio.srcObject = stream; // B
        //     window.localAudio.autoplay = true; // C
        //   }).catch(err => {
        //     console.log("u got an error:" + err)
        //   });
        // }
    };

    publicObject.PlayAudio = () => {
        audio.currentTime = 0;
        audio.play();
    };

    publicObject.PauseAudio = () => {
        audio.pause();
    };

    publicObject.Init()

    connection = new signalR.HubConnectionBuilder()
        .withUrl(`${APIURL}/notify`)
        .configureLogging(signalR.LogLevel.Information)
        .build();
    connection.on("NewOrderReceived", (item) => {
        console.log('NewOrderReceived ', item)
        newOrderReceivedEvent(restaurantID, item)
    });

    connection.on("OrderStatusChanged", (item) => {
        console.log('OrderStatusChanged ', item)
        orderStatusChangedEvent(restaurantID, item)
    });

    function startConnection() {
        try {
            connection.start().catch(function (err) {
                console.log(err.toString());
                setTimeout(() => startConnection(), 5000);
            });
            console.log("SignalR Connected.");
        } catch (err) {
            console.log(err);
            setTimeout(() => startConnection(), 5000);
        }
    };
    if(window.location.pathname.indexOf("/admin")!="-1"){
        console.log("notify start Connection")
        startConnection();
    }
    else{
        console.log("no start notify Connection")
    }
   

    connection.onclose(error => {
        startConnection();
    });

    setInterval(function () {

        if (restaurantID) {
            if (connection.connection._connectionState === "Connected") {
                try {
                    connection.invoke("SendStatus", restaurantID);
                } catch (err) {
                    console.log(err);
                }
            } else {
                startConnection();
            }
        }
    }, 30000);
}