import { createAction, createReducer, createSlice } from "@reduxjs/toolkit";
import { getLandingPageACTION, getWebLandingPageACTION, insertUpdateContactAboutACTION, insertUpdateGeneralACTION, insertUpdateHeaderACTION, insertUpdateNavigationACTION, insertUpdateFooterACTION, insertUpdateGalleryACTION } from "../../actions/landPageAction";


const INITIAL_STATE = {


    selectedLandPageDetails: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    insertUpdateGeneral: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    insertUpdateNavigation: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    insertUpdateHeader: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    insertUpdateContactAbout: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    insertUpdateFooter: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    insertUpdateGallery: {
        data: {},
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
    selectedLandingPage: {
        data: null,
        apiMsg: {
            message: null,
            status: null,
            type: null,
        },
    },
};

const landingPageReducer = createSlice({
    name: "landingPage",
    initialState: INITIAL_STATE,
    reducers: {
        RESET(state, action) {
            state = INITIAL_STATE;
        },
        ResetlandingPageDetailsState(state, action) {
            state.selectedLandPageDetails = INITIAL_STATE.selectedLandPageDetails;
            state.insertUpdateGeneral = INITIAL_STATE.insertUpdateGeneral;
        },
    },



    extraReducers: (builder) => {


        //------------------------------- getLandingPageACTION start -------------------------

        builder.addCase(getLandingPageACTION.fulfilled, (state, action) => {

            state.selectedLandPageDetails.data = action.payload.data;
            state.selectedLandPageDetails.apiMsg.status = action.meta.requestStatus;
            state.selectedLandPageDetails.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(getLandingPageACTION.pending, (state, action) => {

            state.selectedLandPageDetails.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(getLandingPageACTION.rejected, (state, action) => {

            state.selectedLandPageDetails.apiMsg.message = action.error.message;
            state.selectedLandPageDetails.apiMsg.status = action.meta.requestStatus;
        });
        //------------------------------- getLandingPageACTION end -------------------------


        //------------------------------- insertUpdateGeneralACTION start -------------------------
        builder.addCase(insertUpdateGeneralACTION.fulfilled, (state, action) => {


            state.insertUpdateGeneral.apiMsg.status = action.meta.requestStatus;
            state.insertUpdateGeneral.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(insertUpdateGeneralACTION.pending, (state, action) => {

            state.insertUpdateGeneral.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(insertUpdateGeneralACTION.rejected, (state, action) => {

            state.insertUpdateGeneral.apiMsg.message = action.error.message;
            state.insertUpdateGeneral.apiMsg.status = action.meta.requestStatus;
        });
        //------------------------------- insertUpdateGeneralACTION end -------------------------


        //------------------------------- insertUpdateHeaderACTION start -------------------------
        builder.addCase(insertUpdateHeaderACTION.fulfilled, (state, action) => {

            state.insertUpdateHeader.apiMsg.status = action.meta.requestStatus;
            state.insertUpdateHeader.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(insertUpdateHeaderACTION.pending, (state, action) => {

            state.insertUpdateHeader.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(insertUpdateHeaderACTION.rejected, (state, action) => {

            state.insertUpdateHeader.apiMsg.message = action.error.message;
            state.insertUpdateHeader.apiMsg.status = action.meta.requestStatus;
        });
        //------------------------------- insertUpdateHeaderACTION end -------------------------

        //------------------------------- insertUpdateFooterACTION start -------------------------
        builder.addCase(insertUpdateFooterACTION.fulfilled, (state, action) => {


            state.insertUpdateFooter.apiMsg.status = action.meta.requestStatus;
            state.insertUpdateFooter.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(insertUpdateFooterACTION.pending, (state, action) => {

            state.insertUpdateFooter.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(insertUpdateFooterACTION.rejected, (state, action) => {

            state.insertUpdateFooter.apiMsg.message = action.error.message;
            state.insertUpdateFooter.apiMsg.status = action.meta.requestStatus;
        });
        //------------------------------- insertUpdateFooterACTION end -------------------------

        //------------------------------- insertUpdateContactAboutACTION start -------------------------
        builder.addCase(insertUpdateContactAboutACTION.fulfilled, (state, action) => {


            state.insertUpdateContactAbout.apiMsg.status = action.meta.requestStatus;
            state.insertUpdateContactAbout.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(insertUpdateContactAboutACTION.pending, (state, action) => {

            state.insertUpdateContactAbout.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(insertUpdateContactAboutACTION.rejected, (state, action) => {

            state.insertUpdateContactAbout.apiMsg.message = action.error.message;
            state.insertUpdateContactAbout.apiMsg.status = action.meta.requestStatus;
        });
        //------------------------------- insertUpdateContactAboutACTION end -------------------------


        //------------------------------- insertUpdateGalleryACTION start -------------------------
        builder.addCase(insertUpdateGalleryACTION.fulfilled, (state, action) => {


            state.insertUpdateGallery.apiMsg.status = action.meta.requestStatus;
            state.insertUpdateGallery.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(insertUpdateGalleryACTION.pending, (state, action) => {

            state.insertUpdateGallery.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(insertUpdateGalleryACTION.rejected, (state, action) => {

            state.insertUpdateGallery.apiMsg.message = action.error.message;
            state.insertUpdateGallery.apiMsg.status = action.meta.requestStatus;
        });
        //------------------------------- insertUpdateGalleryACTION end -------------------------

        //------------------------------- insertUpdateNavigationACTION start -------------------------
        builder.addCase(insertUpdateNavigationACTION.fulfilled, (state, action) => {


            state.insertUpdateNavigation.apiMsg.status = action.meta.requestStatus;
            state.insertUpdateNavigation.apiMsg.message = action.payload.message.message;
        });
        builder.addCase(insertUpdateNavigationACTION.pending, (state, action) => {
            state.insertUpdateNavigation.apiMsg.status = action.meta.requestStatus;
        });
        builder.addCase(insertUpdateNavigationACTION.rejected, (state, action) => {

            state.insertUpdateNavigation.apiMsg.message = action.error.message;
            state.insertUpdateNavigation.apiMsg.status = action.meta.requestStatus;
        });
        //------------------------------- insertUpdateNavigationACTION end -------------------------

        //------------------------------- getLandingPageAction start -------------------------

        builder.addCase(getWebLandingPageACTION.fulfilled, (state, action) => {
            state.selectedLandingPage.data = action.payload.data;
            state.selectedLandingPage.apiMsg.status = action.meta.requestStatus;
            state.selectedLandingPage.apiMsg.message = action.payload.message.message;
        });

        builder.addCase(getWebLandingPageACTION.pending, (state, action) => {
            state.selectedLandingPage.data = null;
            state.selectedLandingPage.apiMsg.message = action.meta.requestStatus;;
            state.selectedLandingPage.apiMsg.status = action.meta.requestStatus;
        });

        builder.addCase(getWebLandingPageACTION.rejected, (state, action) => {
            state.selectedLandingPage.apiMsg.message = action.error.message;
            state.selectedLandingPage.apiMsg.status = action.meta.requestStatus;
        });


        //   getLandingPageAction end

    }
});

export const { ResetlandingPageDetailsState } = landingPageReducer.actions;
export default landingPageReducer.reducer;
